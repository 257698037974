import { ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsService, OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-clinical-second',
  templateUrl: './clinical-second.component.html',
  styleUrl: './clinical-second.component.scss'
})
export class ClinicalSecondComponent {
  constructor(private accessService : ContentService,private cmsService:CmsService,private cd:ChangeDetectorRef,protected config: OccConfig) {}
  thirdheadline: Observable<any> = this.cmsService.getComponentData('OperationalEfficiencyPageFeatureResource');
  data1: any;
  data2: any;
  data3: any;
  mergedObject1 :any= [];
  public resourse1: Observable<any> = this.accessService.clinicalSupportSlide1();
  public resourse2: Observable<any> = this.accessService.clinicalSupportSlide2();
  public resourse3: Observable<any> = this.accessService.clinicalSupportSlide3();
  //public resourseFinal: Observable<any>= this.accessService.getMultipleResources()
  // getImage(image: any): string {
  //   return this.config.backend?.occ?.baseUrl + image.url;
  // }
  getContent(){
    combineLatest([this.resourse1, this.resourse2, this.resourse3]).subscribe(([data1, data2, data3]) => {
      this.data1 = data1;
      this.data2 = data2;
      this.data3 = data3;
     
      console.log('Received data1:', this.data1);
      console.log('Received data2:', this.data2);
      console.log('Received data3:', this.data3);
 
      this.cd.detectChanges();
      this.mergedObject1.push(this.data1);
      this.mergedObject1.push(this.data2);
      this.mergedObject1.push(this.data3);
      //  this.mergeObjects( this.data1, this.data2, this.data3);
      console.log('Merged data:', this.mergedObject1);
     
      //this.isLoading = false;
    });
    return this.mergedObject1
  }
  ngOnInit() {
    this.getContent()
  //  this.resourseFinal.subscribe(res=>{
  //   console.log('Received datafinal:', res);
  //  })
 
}
ngAfterViewInit(){
  this.getContent()
}
}
