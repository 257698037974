import { Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent {
  constructor(
    public userInfoService:UserInfoService) {
  }
  iframeData$:Observable<any> = this.userInfoService.getcheckOutId();
  
  ngOnInit(): void {
    let id = localStorage.getItem('checkoutId')
   console.log('local storage',id);
    let obj:any = localStorage.getItem('iframeObj')
    console.log('local storage', JSON.parse(obj))
    // setTimeout(() => {
    //   this.iframeData$.subscribe(res=>{
    //     console.log('subject',res);
    //   })
    // }, 1000);
    // this.iframeData$.subscribe(res=>{
    //   console.log('subject',res);

    //   //06728f2b-10b6-71ff-b86f-d2269a968ba9
    //   let obj = {
    //     invoiceAmt: '1027.5',
    //     invoiceNumber: '9130225906',
    //     tokenId: '06729c68-e6c6-71ff-b3ff-147f8d689c86',
    //     poNumber: 'tw0112202368',
    //     creditsAppliedFlag: "applied",
    //     appliedCredits: {
    //       invoiceNumber: '9130225906',
    //       creditNotes: [
    //         {
    //           creditNoteNumber: 9130225307,
    //           creditAmount: 10
    //         }
    //       ],
    //       paymentFlag: ""
    //     }pi
    //   }
      this.userInfoService.paymentStatusIframe(obj,id).subscribe(data=>{
       
        //console.log(document.getElementById('iframeHolder'));
        //document.getElementById('iframeHolder')?.style.display = none
        //(document.querySelector('iframeHolder') as HTMLElement).style.visibility = 'hidden'
        localStorage.setItem('isCancelled', data.cancelled) 
        localStorage.setItem('iframeErrorCode', data.errorCode)  
        this.userInfoService.isCancelled$.next(true)
      })
    // })
    
  }
}
