<div class="col-12 recent-order-wrapper float_left" [ngClass]="currentSite == 'seqirusb2b-flu360'?'':'dashborad_announceleftheaderuk'">
    <div class="row dashboard-rightheadersection" [ngClass]="currentSite == 'seqirusb2b-flu360'?'':'dashboard-rightheadersectionuk'">
        <div class="col-6 col-md-7 dashboard-orderheades no-padding float_left" [ngClass]="currentSite == 'seqirusb2b-flu360'?'dashboard-orderheades':'dashboard-orderheadesuk'">
                    <span>
                      <span *ngIf="currentSite == 'seqirusb2b-flu360'">Recent Order History</span>
                      <span *ngIf="currentSite == 'seqirusb2b-uk'">Orders</span>
                     
                      
                    </span>
                </div>
            <div class="col-6 col-md-5 float_left" [ngClass]="currentSite == 'seqirusb2b-flu360'?'dashboard-viewall-orders':'dashboard-viewall-ordersuk'">
                <a *ngIf="currentSite == 'seqirusb2b-flu360'" href="/orders" class="dashboard-viewall">View Orders<i class="global_blackarrow"></i></a>
                <ng-container *ngIf="getdata | async as item" >
                <a *ngIf="currentSite == 'seqirusb2b-uk'  && item?.seqirusOrdersData.length != 0" routerLink="/orders" class="dashboard-viewall">View All Orders<i class="global_blackarrow"></i></a>
                </ng-container>
            </div>
    </div>
                                       
   
 
  <ng-container *ngIf="getdata | async as item" class="row">
    <div *ngIf="item?.seqirusOrdersData.length != 0;else nodata">
    <ng-container *ngIf="currentSite == 'seqirusb2b-flu360'">
        <div *ngFor="let item4 of item?.seqirusOrdersData" class="row dashboard-ordersborder">
        <div class="col-6 col-md-7 col-lg-6 no-padding float_left">
            <div class="dashboard-orderdetials-subheader">SEASON&nbsp;{{item4.orderSeason}}</div>
            <div class="dashboard-orderdetials-header">Order #{{item4.orderID}}</div>
            <div class="row">
                <div class="col-12 float_left">
                    <div class="dashboard-orderdetials-text">Unit Qty Total:</div>
                    <div class="dashboard-orderdetials-text">Shipping Location:</div>
                </div>
                
            </div>
        </div>
        <div class="col-6 col-md-5 col-lg-6 float_left">
            <div class="dashboard-orderdetials-status"><span class="dashboard-statusspan">{{item4.status}}</span></div>
            <div class="row">
                <div class="col-12 col-md-12 margin-T20 padding-T12 float_left">
                    <div class="dashboard-orderdetials-textaddress">{{item4.totalQuantity | number:'1.0'}}</div>
                    <div class="dashboard-orderdetials-textaddress">

                        
                            1619 N Swan Road<br>Tucson&nbsp;AZ&nbsp;85712

                   </div>
                      </div>
            </div>
        </div>
        </div>
    </ng-container> 
    
    <ng-container *ngIf="currentSite =='seqirusb2b-uk'">
        <div *ngFor="let orderData of item?.seqirusOrdersData" class="row dashboard-ordersborder dashboard-ordersborderuk margin-T15">
            <div class="col-xs-6 col-md-6 no-padding">
               
                <div class="dashboard-orderdetials-subheaderuk">{{orderData.orderSeason}} Season </div>
                
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <div class="dashboard-orderdetials-textuk">Order Number</div>
                        
                        <div *ngFor="let product of orderData.orderedProductDetails" class="dashboard-orderdetials-textuk">{{product.product}}</div>
                        
                        <div class="dashboard-orderdetials-textuk">Delivery Location</div>
                    </div>
                    
                </div>
            </div>
            <div class="col-xs-6 col-md-6 dashboard_spacing">
                <div class="dashboard-orderdetials-status"><span class="dashboard-statusspan">{{orderData.status}}</span></div>
                <div class="row">
                    <div class="col-xs-12 col-md-12 dashboard-orderdetials-iphone5uk margin-T5 ">
                        <div class="dashboard-orderdetials-textaddressuk">{{orderData.orderID}}</div>
                        
                        <div *ngFor="let product of orderData.orderedProductDetails" class="dashboard-orderdetials-textaddressuk ukdashboarddosescomma">{{product.volume}}</div>
                       
                        
                          <ng-container *ngIf="orderData.addressDetail.addressLine1 == '';else fullAddress">
                          <div class="dashboard-orderdetials-textaddressuk">{{orderData.addressDetail.addressLine2}}<br>{{orderData.addressDetail.city}}&nbsp;{{orderData.addressDetail.state}}&nbsp;{{orderData.addressDetail.zipCode}}</div>
                          </ng-container>
                          <ng-template #fullAddress>
                          <div *ngIf="orderData.nameOne;else noNameOne" class="dashboard-orderdetials-textaddressuk">{{orderData.nameOne}},&nbsp;{{orderData.addressDetail.addressLine1}}&nbsp;{{orderData.addressDetail.addressLine2}}&nbsp;{{orderData.addressDetail.city}} {{orderData.addressDetail.state}},&nbsp;{{orderData.addressDetail.zipCode}}</div>
                          <ng-template #noNameOne>
                            <div  class="dashboard-orderdetials-textaddressuk">{{orderData.addressDetail.addressLine1}}&nbsp;{{orderData.addressDetail.addressLine2}}&nbsp;{{orderData.addressDetail.city}} {{orderData.addressDetail.state}},&nbsp;{{orderData.addressDetail.zipCode}}</div>
                          </ng-template>
                        </ng-template>
                        
                    </div>
                </div>
            </div>
        </div>

    </ng-container>
    </div>
    <ng-template #nodata>
        <ng-container *ngIf="currentSite === 'seqirusb2b-flu360';else uknoData">
            <div class="row dashboard-ordersborder noorders">No order available</div>
        </ng-container>
        <ng-template #uknoData>
            <div class="dashboard-splitscetionnoorderuk"></div>
            <div class="dashboard-noorderuk">You have no orders in your account.</div>
            <div class="dashboard-noorderlinkuk"><a routerLink='start-order'>Place an order now<i class="global_blackarrow"></i></a>
            </div>
        </ng-template>
    </ng-template> 
  </ng-container>      
                                 
    

    </div>
