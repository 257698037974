import { Component } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';
import patternFill from 'highcharts/modules/pattern-fill';
patternFill(Highcharts);
interface CustomPoint extends Highcharts.Point {
  value: number;
}
@Component({
  selector: 'app-current-season-status',
  templateUrl: './current-season-status.component.html',
  styleUrl: './current-season-status.component.scss'
})

export class CurrentSeasonStatusComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService) {}
  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
  orderSummary : Observable<any>= this.userInfoService.getOrderSummary();
    percDelivered:any;
    percInTransit:any;
    percProcessed:any;
  Highcharts: typeof chart = chart;
  columnChart!: Chart;  
 
  customPattern(color: string): Highcharts.PatternObject {
    return {
      pattern: {
        patternIndex: 0, 
        path: {
                 d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                 stroke: '#979797',
                 strokeWidth: 1
               },
        width: 10,
        borderColor: '#979797',
        height: 10,
       //s r:4,
       // patternTransform: 'rotate(-30)',
        //color: 'blue'
    },
    } as Highcharts.PatternObject;
  }
  initColumn(delivered :any, transit:any, processed:any) {
    console.log(delivered,transit,processed)
    const column = new Chart({
    
    title: {
      text: ''
  },
  chart: {
      backgroundColor: 'transparent',
      renderTo: 'ordergraphcontainer'
  },
  //showInLegend: true,
  colors: ["#5D6F7B ", "#EA181B ", "#979797 "], 
  credits: {
      enabled: false
  },
  legend: {
      enabled: true,
      layout: 'vertical',
      backgroundColor: 'transparent',
      padding: 10,
      itemDistance: 500,
      itemMarginTop: 10,
      itemMarginBottom: 10,
      symbolHeight: 16,
      symbolWidth: 16,
      symbolRadius: 10,
      useHTML: true,
      labelFormatter: function () {
        const point = this as CustomPoint;
          //return '<b>' + point.name +  ' : ' + c + '%</div>';
          return '<div class="orderslabelname">' + point.name + '&nbsp;</div><div class="orderpercnetage">' + point.value + '%</div>';
      }
      //width: 400
  },
    series: [{
      showInLegend: true,
      type: 'pie',
      center: ['50%', '50%'],
      borderColor: '#AEB7BD',
      enableMouseTracking: false,
      innerSize: '80%',
      dataLabels: {
          enabled: false
      },
      data: [{
              y: parseInt(delivered),//parseInt(percDelivered),
              value:parseInt(delivered),
              borderColor: '#5D6F7B',
              //borderWidth: 2,
              name: 'Delivered'
          }, {
              y:parseInt(transit),//parseInt(percInTransit),
              value:parseInt(transit),
              borderColor: '#EA181B',
              //borderWidth: 2,
              name: 'Shipped'
          }, {
              y: parseInt(processed),
              value:parseInt(processed),
              //borderWidth: 2,
              name: 'Ordered',
              color: this.customPattern('#979797'),
          //    color: {
          //     pattern: {
          //            path: {
          //                 d: 'M 3 3 L 8 3 L 8 8 Z',
          //                fill: '#102045'
          //            },
          //            width: 12,
          //            height: 12,
          //            color: '#907000',
          //            opacity: 0.5
          //     }
          // }
              
              // color: {
              //     //patternIndex: 0,                 
              //     pattern: {
              //         path: {
              //             d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
              //             strokeWidth: 2,
                      
              //         width: 20,
              //         height: 20,
              //         color: '#979797'
              //         },
              //     }
              // }

          }]
  }]
  
    });
    //column.addPoint(12);
    //this.callStorke(column)
    this.columnChart = column;
    column.ref$.subscribe(console.log);
   
  }
  ngAfterViewInit() {
    //const chart  = this.initColumn();  
    
  }
  ngOnInit() {
    //this.initColumn();
     
  this.orderSummary.subscribe(data=>{
    console.log('sum', data);
    if (data.total_Shipped_Qty)
      {
          if (data.delivered && data.delivered > 0)
          {
              this.percDelivered =((data.delivered / data.total_Shipped_Qty)* 100).toFixed(0);
          } else
          {
              this.percDelivered = 0;
          }
          if (data.intrantsit && data.intrantsit > 0)
          {
              this.percInTransit = ((data.intrantsit / data.total_Shipped_Qty * 100)).toFixed(0);
          } else
          {
              this.percInTransit = 0;
          }
          if (data.processing && data.processing >= 0)
          {
              this.percProcessed =((data.processing / data.total_Shipped_Qty * 100)).toFixed(0);
          } else
          {
              this.percProcessed = 0;
          }
      } else
      {
          this.percDelivered = 0;
          this.percInTransit = 0;
          this.percProcessed = 0;
      }
      console.log('sum', this.percDelivered,this.percInTransit , this.percProcessed);
      this.initColumn( this.percDelivered, this.percInTransit, this.percProcessed) 
  })
  
   // this.initDonut();
  }
}
