<div class="col-12 recent-order-wrapper float_left">
    <div class="row dashboard-rightheadersection">
        <div class="col-6 col-md-7 dashboard-orderheades no-padding float_left">
                    <span>
                      <span>Recent Order History</span>
                    </span>
                </div>
            <div class="col-6 col-md-5 dashboard-viewall-orders float_left">
                                                <a href="/orders" class="dashboard-viewall">View Orders<i class="global_blackarrow"></i></a>
                                            </div>
                                        </div>
                                       
   
 
  <ng-container *ngIf="getdata | async as item" class="row">
    <div *ngIf="item.seqirusOrdersData;else nodata">
    <div *ngFor="let item4 of item?.seqirusOrdersData" class="row dashboard-ordersborder">
        <div class="col-6 col-md-7 col-lg-6 no-padding float_left">
            <div class="dashboard-orderdetials-subheader">SEASON&nbsp;{{item4.orderSeason}}</div>
            <div class="dashboard-orderdetials-header">Order #{{item4.orderID}}</div>
            <div class="row">
                <div class="col-12 float_left">
                    <div class="dashboard-orderdetials-text">Unit Qty Total:</div>
                    <div class="dashboard-orderdetials-text">Shipping Location:</div>
                </div>
                
            </div>
        </div>
        <div class="col-6 col-md-5 col-lg-6 float_left">
            <div class="dashboard-orderdetials-status"><span class="dashboard-statusspan">{{item4.status}}</span></div>
            <div class="row">
                <div class="col-12 col-md-12 margin-T20 padding-T12 float_left">
                    <div class="dashboard-orderdetials-textaddress">{{item4.totalQuantity | number:'1.0'}}</div>
                    <div class="dashboard-orderdetials-textaddress">

                        
                            1619 N Swan Road<br>Tucson&nbsp;AZ&nbsp;85712

                   </div>
                      </div>
            </div>
        </div>
    </div>
    </div>
    <ng-template #nodata>
        <div class="row dashboard-ordersborder noorders">No order available</div>
      </ng-template> 
  </ng-container>      
                                 
    

    </div>
