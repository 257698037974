import { Component, ElementRef, ViewChild } from '@angular/core';
import { OccEndpointsService, ClientTokenService } from '@spartacus/core';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-link-resend',
  templateUrl: './custom-link-resend.component.html',
  styleUrl: './custom-link-resend.component.scss'
})
export class CustomLinkResendComponent {
  @ViewChild('verifyModal') verifySuccessModal: ElementRef | undefined;

  constructor(private occEndpointsService: OccEndpointsService,
    private commonService: CommonApiService,
    private clientTokenService: ClientTokenService,
    private router: Router,
    private route: ActivatedRoute,) { }

  resendVerification() {
    if (localStorage.getItem('email')) {
      this.clientTokenService.getClientToken().subscribe(res => {
        if (res) {
          let token = res.access_token;
          this.passCalltoVerification(token);
        }
      })
    }

  }
  passCalltoVerification(token: string) {
    let endpoint = this.occEndpointsService.buildUrl(`Verify/VerifyProfileEmail?emailId=${localStorage.getItem('email')}`);
    let payload = {
      url: endpoint,
      token: token,
    }
    this.commonService.getAPICallNoAuth(payload).subscribe({
      next: (res: any) => {
        if (res) {
          localStorage.removeItem('email');
          (this.verifySuccessModal!.nativeElement).modal('show');
        }
      },
      error: (e) => {
        (this.verifySuccessModal!.nativeElement).modal('show');
      },
    })
  }

}
