import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from './invoice/invoice.component';
import { CreditComponent } from './credit/credit.component';
import { FinancialBannerComponent } from './financial-banner/financial-banner.component';
import { HelpsectionComponent } from './helpsection/helpsection.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';


@NgModule({
  declarations: [
    InvoiceComponent,
    CreditComponent,
    FinancialBannerComponent,
    HelpsectionComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FinancialTopBannerComponent:{
          component:FinancialBannerComponent,
        },
        /* SeqirusNeedhelpBannerComponent :{
          component: HelpsectionComponent,
        }, */
        FinancialInvoicesComponent:{
          component: InvoiceComponent ,
        }
     
     
      }
    } as CmsConfig)
  ]
})
export class FinancialDashboardModule { }
