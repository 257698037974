import { Component } from '@angular/core';
import { AuthService, CmsService, OccConfig } from '@spartacus/core';
import { LoginFormComponent, LoginFormComponentService } from '@spartacus/user/account/components';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-user-guide',
  templateUrl: './custom-user-guide.component.html',
  styleUrl: './custom-user-guide.component.scss',
  providers:[LoginFormComponentService]
})
export class CustomUserGuideComponent {
  userLoggedIn: any;



  constructor(private cmsService: CmsService,private userInfoSevice:UserInfoService,protected config: OccConfig,public PageParagraphComponent : CmsComponentData<any>) {
  }

  userGuide: Observable<any> = this.PageParagraphComponent.data$;

  ngOnInit(){
    this.userInfoSevice.userLoggedIn$.subscribe(res=>{
      this.userLoggedIn=res;
    })
  }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image?.url;
  }
}
