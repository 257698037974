<div id="start_order">
    <div class="col-xs-12 col-md-12 col-lg-12  main">
        <section class="col-xs-12 cart_titlesection">
            <div class="col-xs-12 col-md-12">
                <h1 class="cart_header">{{'startOrder.mycarttitle' | cxTranslate}}</h1>
                <div class="cart_subheader"></div>
            </div>
        </section>
        <section class="col-xs-12 cart_neworder">
            <div class="col-xs-12 d-md-block d-lg-block d-xl-block d-none d-xs-none d-sm-none pl-0 pr-0">

                <div style="display: flex;">
                    <div class="col-md-6 col-lg-6 col-xl-6 cart_neworderheader">New Order</div>
                    <div class="col-md-6 col-lg-6 col-xl-6 need_help_content pr-0">
                        <div class="startuserguideparent need_help_container pr-0"
                            *ngIf="needHelpContent | async as item">
                            <span class="userguidegrey">{{item.headline}}</span>
                            <a class="userguideanchor" [href]="getImage(item.media)" target="_blank"><span
                                    class="userguideblack anchorunderline">{{item.h2content}}</span>
                            </a>
                            <span class="blackarrow"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 d-block d-sm-block d-md-none d-lg-none d-xl-none pr-0">
                <div class="startmobuserguideparent" *ngIf="needHelpContent | async as item">
                    <span class="userguidegrey">{{item.headline}}</span><a class="userguideanchor"
                        [href]="getImage(item.media)" target="_blank"><span
                            class="userguideblack">{{item.h2content}}</span>
                    </a>
                    <span class="blackarrow"></span>
                </div>
            </div>
            <div
                class="col-12 col-xs-12 col-sm-12 d-block d-sm-block d-md-none d-lg-none d-xl-none cart_neworderheader">
                New Order</div>
            <!-- <div class="cart_delete hidden"><div class="global_blackarrow"></div><span>Delete Cart &amp; Start New Order</span></div>     
  
    <div class="col-xs-12 col-md-12 cart_TIValert hidden">  
        <div class="attention_header">Attention:</div>
        <div>New and existing 2024-2025 pre-book orders will be fulfilled in trivalent product formulations. No action is needed from you.<br> <a href="/digital_customer_letter_tiv_vaccines_24-25" target="_blank">Read our full Customer Letter<img alt="" class="padding-L5" src="_ui/responsive/theme-lambda/images/red_arrow.png"></a></div>
    </div> -->
            <div class="d-md-none d-lg-block d-xl-block d-none d-xs-none d-sm-none">
                <div class="col-xs-12 cart_neworderbtns pr-0" style="display: flex;" *ngIf="orderLists">
                    <div class="col-xs-4 pl-0 pr-0 mr-4" id="cart_neworderbtn-1" *ngIf="orderLists.preSeasonEnable">
                        <button class="col-xs-12 cart_neworderbtn" (click)="handleOnSeasonBtnClick('preSeason')"
                            [ngClass]="seasonValue === 'preSeason'? 'cart_active': 'disable_cart'">
                            <div class="col-xs-12 cart_neworderbtnheader">{{'startOrder.reserveforlabel' | cxTranslate}}
                            </div>
                            <div class="col-xs-12 cart_neworderbtndate">{{'startOrder.sesonterm2text' | cxTranslate}}
                            </div>
                        </button>
                    </div>
                    <div class="col-xs-4 pl-0 pr-0" id="cart_neworderbtn-2" *ngIf="orderLists.inSeasonEnable">
                        <button class="col-xs-12 cart_neworderbtn" (click)="handleOnSeasonBtnClick('inSeason')"
                            [ngClass]="seasonValue === 'inSeason' ? 'cart_active': 'disable_cart'">
                            <div class="col-xs-12 cart_neworderbtnheader">{{'startOrder.orderfortext' | cxTranslate}}
                            </div>
                            <div class="col-xs-12 cart_neworderbtndate">{{'startOrder.sesonterm1text' | cxTranslate}}
                            </div>
                        </button>
                    </div>
                    <div class="col-xs-4 pl-0 pr-0 ml-4" id="cart_neworderbtn-3"
                        (click)="handleOnSeasonBtnClick('bulkOrder')">
                        <button class="col-xs-12 cart_neworderbtn" data-toggle="collapse"
                            data-target="#cart_selectlocation-3"
                            [ngClass]="seasonValue === 'bulkOrder' ? 'cart_active': 'disable_cart'">
                            <div class="col-xs-12 cart_neworderbtnheader">{{'startOrder.orderviatext' | cxTranslate}}
                            </div>
                            <div class="col-xs-12 cart_neworderbtndate">{{'startOrder.fileuploadtext' | cxTranslate}}
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xs-12 col-sm-12 d-block d-sm-block d-md-block d-lg-none d-xl-none cart_neworderbtns pr-0 mt-0"
                *ngIf="orderLists">
                <div class="global_selectdivmob">
                    <select name="cart_neworderselect" id="cart_neworderselect" [(ngModel)]="mobileSeasonValue"
                        (change)="handleOnSeasonBtnClick('mobile')">
                        <option selected value="Select New Order Type" id="cart_neworderselect-0" disabled>
                            {{'startOrder.selectnewordtypetext'
                            | cxTranslate}}</option>{{'startOrder.reserveforlabel' | cxTranslate}}
                        <option value="preSeason" id="cart_neworderselect-1" *ngIf="orderLists.preSeasonEnable">
                            {{'startOrder.sesonterm2text' |
                            cxTranslate}}</option>
                        <option value="inSeason" id="cart_neworderselect-2" *ngIf="orderLists.inSeasonEnable">
                            {{'startOrder.sesonterm1text' |
                            cxTranslate}}</option>
                        <option value="bulkOrder" id="cart_neworderselect-3">{{'startOrder.fileuploadtext' |
                            cxTranslate}}
                        </option>
                    </select>
                </div>
            </div>
        </section>
        <div *ngIf="orderLists && orderLists.validForRebook" class="col-xs-12 cart_neworderreorder prebookrolloverlink" style="cursor:pointer" (click)="handleOnReorderClick()">Reorder previous season quantities <div class="global_blackarrow"></div></div>
        <!--Loader icons-->
        <div *ngIf="isLoading" class="loading">
            <div class="loading_txt">
                <div class="load_txt">Loading</div>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <!----Loader ends here----->
        <div *ngIf="showMembershipContent && orderLists">
            <section class="col-xs-12 col-md-12 cartmembercontainer" style="display: block;">
                <!-- *ngIf="(showBodyContent && orderLists.isMembershipDeclared) || (showLocationContent && orderLists.isMembershipDeclared)"> -->
                <form id="cartMemberform" #memberForm="ngForm"
                    (ngSubmit)="memberForm.form.valid && handleOnFormSubmit(memberForm.value,orderLists)">
                    <div class="col-xs-12 cart_memberbox">
                        <!-- <div *ngIf="orderLists.membershipData.isMembershipDeclared"> -->
                        <div>
                            <div class="col-xs-12 memberheader memberheadercolor">Select Your Membership</div>
                            <div class="col-xs-12 memberline"></div>
                            <div class="col-xs-12 no-padding memberquestionsection">
                                <!-- <input type="hidden" value="flu360 Pricing" name="currentMembership"> -->
                                <div class="col-xs-12 memberdetails margin-B30">Your current membership is <span
                                        class="membername">&nbsp;{{orderLists.membershipData.currentMembership}}</span>
                                </div>
                                <div class="col-xs-12 cart_questionheader">Do you intend to order under this
                                    membership
                                    this
                                    season?
                                </div>

                                <div class="col-xs-12 memberradiocontainer">
                                    <input type="radio" name="membershipDecision" class="memberradiobtn"
                                        id="memberradiobtn1" value="yes" #membershipDecision="ngModel"
                                        [(ngModel)]="orderLists.membershipData.membershipDecision"
                                        (ngModelChange)="clearMembershipData()" required [disabled]="!fieldsetDisabled">
                                    <span class="radio-button__control"></span>
                                    <label for="memberradiobtn1">Yes, I confirm I want to use this membership
                                        for
                                        this
                                        season</label>
                                </div>
                                <div class="col-xs-12 memberradiocontainer">
                                    <input type="radio" name="membershipDecision" class="memberradiobtn"
                                        id="memberradiobtn2" value="no" #membershipDecision="ngModel"
                                        [(ngModel)]="orderLists.membershipData.membershipDecision" required
                                        [disabled]="!fieldsetDisabled">
                                    <span class="radio-button__control"></span>
                                    <label for="memberradiobtn2">No, I would like to change my membership to the
                                        following</label>
                                </div>
                                <div class="col-md-5 col-xs-11 addmemberfield">
                                    <label class="cartmember_inputLabel" for="memberinput">Membership</label>
                                    <input type="text" #membershipData="ngModel" [(ngModel)]="requestedMembership"
                                        class="cartmember_input form-control" id="memberinput"
                                        name="requestedMembership" placeholder="Enter only one membership here"
                                        [disabled]="membershipDecision.value != 'no' || !fieldsetDisabled"
                                        autocomplete="no" required>
                                    <span class="glyphicon  form-control-feedback"></span>
                                    <div class="membererrortext"></div>

                                </div>
                                <div class="clearboth"></div>
                                <div class="col-xs-12 memberradiocontainer">
                                    <input type="radio" name="membershipDecision" class="memberradiobtn"
                                        id="memberradiobtn3" value="notsure" #membershipDecision="ngModel"
                                        [(ngModel)]="orderLists.membershipData.membershipDecision"
                                        (ngModelChange)="clearMembershipData()" required [disabled]="!fieldsetDisabled">
                                    <span class="radio-button__control"></span>
                                    <label for="memberradiobtn3">I'm not sure</label>

                                </div>

                                <div class="clearboth"></div>
                                <button type="submit" class="membersubmitbtn" id="memberSubmitbtn"
                                    [disabled]="!memberForm.valid || !fieldsetDisabled">
                                    <!-- [disabled]="!fieldsetDisabled || !membershipDecision.value || (membershipDecision.value === 'no' && !requestedMembership)"> -->
                                    Submit</button>
                                <div class="col-xs-12 memberThankyou yescontractsign" *ngIf="yesContent"
                                    [ngClass]="!fieldsetDisabled && membershipDecision.value === 'yes' ? 'showContent' : ''">
                                    <div [innerHTML]="yesContent.content"
                                        *ngIf="!fieldsetDisabled && membershipDecision.value === 'yes'">

                                    </div>
                                </div>
                                <div class="col-xs-12 memberThankyou nosubmit" *ngIf="noContent"
                                    [ngClass]="!fieldsetDisabled && membershipDecision.value === 'no' ? 'showContent' : ''">
                                    <div *ngIf="!fieldsetDisabled && membershipDecision.value === 'no'">
                                        <div [innerHTML]="noContent.content"></div>
                                        <div>{{noContent.headline}}&nbsp;{{orderLists.membershipData.currentMembership}}
                                        </div>
                                        <div [innerHTML]="noContent.h2content"></div>
                                        <div [innerHTML]="noContent.h3content"></div>
                                    </div>
                                </div>
                                <div class="col-xs-12 memberThankyou notsurecontractsign" *ngIf="notSureContent"
                                    [ngClass]="!fieldsetDisabled && membershipDecision.value === 'notsure' ? 'showContent' : ''">
                                    <div *ngIf="!fieldsetDisabled && membershipDecision.value === 'notsure'"
                                        [innerHTML]="notSureContent.content"></div>

                                </div>
                                <div class="col-xs-12 memberline mb-0"></div>
                                <div class="clearboth"></div>

                            </div>
                            <div class="col-xs-12 startorderbtn">
                                <button *ngIf="fieldsetDisabled" type="submit" class="membersubmitbtn"
                                    id="memberSubmitbtn" [disabled]="true">Start Order</button>
                                <button type="submit" *ngIf="!fieldsetDisabled" class="enablestrtbtn"
                                    id="memberSubmitbtn" (click)="handleOnStartOrderBtnClick()">Start
                                    Order</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
        <div *ngIf="showLocationContent && orderLists">
            <section class="col-xs-12 col-md-12 cart_selectlocations" style="display: block;">
                <!-- *ngIf="(showBodyContent && !orderLists.isMembershipDeclared) || (showLocationContent && !orderLists.isMembershipDeclared)"> -->
                <div class="col-xs-12 cart_memberbox">
                    <div>
                        <div class="col-xs-12 col-md-6 cart_selectheader">Select Locations</div>
                        <div class="col-xs-12 col-md-6 cart_selectcount">Ordering to: <span class="cart_loccount"
                                *ngIf="searchText.value === ''">{{selection.selected.length}} </span>
                            <span>Locations</span>
                        </div>
                        <div class="col-xs-12 cart_selectline"></div>
                        <div class="col-xs-12 cart_selectoptions">
                            <div class="cart_customsearch">
                                <i class="global_searchicon"></i><input type="search" #searchText
                                    (keyup)="$event.keyCode == 13 ? applyFilter(searchText.value) : null"
                                    placeholder="Search for location" id="cart_searchbox"><button>Search</button>
                            </div>
                            <div class="cart_selectunselect">
                                <div class="cart_selectoption" id="cart_selectall"
                                    (click)="handleOnSelectAllBtnClick()">Select All</div>
                                <div class="cart_selectdivision"></div>
                                <div class="cart_selectoption" id="cart_clearall" (click)="handleOnClearSelection()">
                                    Clear
                                    Selections</div>
                            </div>
                        </div>
                    </div>
                    <!-- Table starts -->
                    <div class="tablecontainer"
                        style="position: relative; overflow: auto; max-height: 360px; width: 100%;">
                        <table mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="locID">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="element.orderExists ? 'disbleRow' : ''"> {{element.locname}} </td>
                            </ng-container>
                            <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="element.orderExists ? 'disbleRow' : ''"> {{element.address}} </td>
                            </ng-container>
                            <ng-container matColumnDef="address">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element"
                                    [ngClass]="element.orderExists ? 'disbleRow' : ''"> {{element.state}} </td>
                            </ng-container>
                            <ng-container matColumnDef="orderExists">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="cart_existingorder" *ngIf="element.orderExists">This location has an
                                        existing order. Visit <span class="cart_orderNavigate" (click)="handleOnViewOrders()">My Orders</span> if
                                        you
                                        need to edit your order.</div>
                                    <div *ngIf="!element.orderExists" class="selectedtick"></div>
                                </td>
                            </ng-container>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="handleOnRowClick(row)"
                                [ngClass]="selection.isSelected(row) || row.selected ? 'selected' : ''"></tr>
                        </table>
                    </div>

                    <div class="col-xs-12 cart_selectline"></div>
                    <div class="col-xs-12 cart_startorderbtnsection">
                        <button class="cart_startorderbtn" id="inSeason" [disabled]="!(selection.selected.length > 0)"
                            (click)="handleOnSelectProductClick()">Select Products</button>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-xs-12" id="cart_section" style="display: block; " *ngIf="showProductContent && orderLists">
            <div class="col-xs-12 col-md-12 add_cart_wrapper" id="cart-wrapper-main">
                <div class="row">
                    <div class="col-md-12 border_cart">
                        <div class="cart_summaryProductselection anchorunderline" (click)="handleOnBackToLocationBtnClick()">
                            <div class="global_blackarrow"></div>Back to Location Selection
                        </div>
                        <!-- <div class="col-md-12 back_txt"><i class="fa fa-long-arrow-left"
                                    aria-hidden="true"></i>&nbsp;&nbsp;<div (click)="handleOnBackToLocationBtnClick()">Back
                                    to Location Selection</div> -->
                        <div class="col-xs-12 no-padding pending"
                            *ngIf="orderLists && (orderLists.membershipData.membershipStatus == 'Active')">
                            <div class="col-xs-12 topline"></div>
                            <div class="col-xs-12 productmemberdetails" *ngIf="activeMembershipContent">
                                <p>
                                    {{activeMembershipContent.title}}&nbsp;<b>{{orderLists.membershipData.currentMembership}}.</b>
                                </p>
                                <div [innerHTML]="activeMembershipContent.content"></div>
                            </div>
                        </div>
                        <div class="col-xs-12 no-padding pending"
                            *ngIf="orderLists && orderLists.membershipData.membershipStatus == 'Pending'">
                            <div class="col-xs-12 topline"></div>
                            <div class="col-xs-12 productmemberdetails" *ngIf="pendingMembershipContent">
                                <p>
                                    {{pendingMembershipContent.title}}
                                </p>
                                <div [innerHTML]="pendingMembershipContent.content"></div>
                            </div>
                        </div>

                        <div class="col-md-12 order-listing pl-0 pr-0" style="display: block;"
                            *ngIf="showSelectedProducts">

                            <!--Add Products list in the cart starts here -->
                            <form id="reviewform" onkeypress="return event.keyCode != 13;" action="/addtoCart"
                                method="post" autocomplete="off">
                                <div *ngFor="let item1 of selectedProducts; index as i">
                                    <div class="product_according pl-0 pr-0 Activestage d-none d-sm-none d-md-none d-xl-block d-lg-block"
                                        id="product-10052815" style="display: block;">
                                        <div class="col-md-12 product_row">
                                            <div class="col-md-11 pl-0 pr-0 left-pro-list"
                                                [ngClass]="item1.showAngleDownArrow ? '' : 'border_cart_select'">
                                                <div class="col-md-2 pro_desc d-none d-sm-none d-md-block d-lg-block d-xl-block"
                                                    style="align-content: center;">
                                                    <img alt="{{item1.baseName}}"
                                                        [src]="'../../assets/images/'+item1.baseName+'.webp'"
                                                        width="90%">
                                                </div>
                                                <div class="col-md-3 col-xs-10 pro_desc" style="align-content: center;">
                                                    {{item1.baseName}}
                                                </div>
                                                <!-- <div class="col-xs-2 pl-0 pr-0 del_section d-md-none d-lg-none d-none d-sm-none border_del_select"
                                                style="align-content: center;">
                                            </div> -->
                                                <div class="col-md-3 pro_desc col-xs-8" style="align-content: center;">
                                                    {{item1.presentationName}}</div>
                                                <div class="col-md-3 pro_desc pro_qty d-none d-sm-none d-md-block d-lg-block d-xl-block notvisible-ipad"
                                                    style="align-content: center;">
                                                    <strong>Total Quantity: <span
                                                            class="count_qty">{{selectedProducts[i].totalQuantity  | number:'1.0'}}</span></strong>
                                                </div>
                                                <div class="col-md-2 col-xs-4 pro_desc pro_qty d-md-none d-lg-none d-block d-sm-block visible-ipad"
                                                    style="align-content: center;">
                                                    <strong>Qty: <span
                                                            class="count_qty">{{selectedProducts[i].totalQuantity  | number:'1.0'}}</span></strong>
                                                </div>
                                                <div style="border-left: 1px solid #cccccc;margin-bottom: 15px;">
                                                </div>
                                                <div class="col-md-1 pro_desc col-xs-12 d-none d-sm-none d-md-block d-lg-block accordion"
                                                    style="align-content: center;" [id]="'accordion'+i">
                                                    <i class="show_pro_details fa fa-angle-up"
                                                        (click)="handleOnToggle(item1)" aria-hidden="true"
                                                        data-toggle="collapse" [attr.data-target]="'#part'+i"></i>
                                                </div>
                                            </div>

                                            <div class="col-md-1 pl-0 pr-0 del_section border_del_select"
                                                [ngStyle]="{'border': item1.showAngleDownArrow ? 'none' : ''}"><i
                                                    class="fa fa-trash-o" aria-hidden="true"
                                                    data-button-id="addBtn-10052815"
                                                    (click)="handleOnDeleteBtnClick(item1,i)"></i></div>
                                        </div>
                                        <div class="accordion col-md-12 pro_calc_wrapper collapse show" [id]="'part'+i"
                                            [attr.data-parent]="'#accordion'+i">
                                            <div class="row">
                                                <div class="col-md-3 spcl_3"><a [href]="afluria"
                                                        target="_blank" class="cart_pdf_link">Prescribing
                                                        Information</a></div>
                                                <div class="col-md-3"><a href="/products/afluria" target="_blank"
                                                        class="cart_pdf_link">Important
                                                        Safety Information</a></div>
                                                <div class="col-md-3"><a
                                                        href="/flu-vaccine-resources/afluria-coding-billing"
                                                        target="_blank" class="cart_pdf_link">Coding
                                                        &amp; Billing Guide</a></div>
                                                <div class="col-md-3 col-xs-12 cart_doses_link">
                                                    {{item1.priceData.value
                                                    | currency: item1.priceData.currency}}
                                                    unit&nbsp;&nbsp;|&nbsp;&nbsp;{{item1.doses}} doses per unit
                                                </div>
                                            </div>
                                            <!-- <input min="0" max="9999" placeholder="0" name="orderReviewdata[0].productCode" type="number" class="form-control qty-messure locqty" value="0" maxlength="4" > -->

                                            <div *ngIf="item1.location.length>0">
                                                <div class="locationrowall col-md-12 product_calc locationrow0060151646 hide"
                                                    style="display: flex;"
                                                    *ngFor="let locationDetail of item1.location; index as index">

                                                    <div class="col-md-2">{{locationDetail.locname}} </div>
                                                    <div class="col-md-4">{{locationDetail.address}}</div>
                                                    <div class="col-md-4">{{locationDetail.state}}</div>
                                                    <div class="col-md-2 cart_inputUnit">
                                                        <input name="'locationDetail{{i}}{{index}}'" min="0" max="9999"
                                                            placeholder="0" type="text"
                                                            class="form-control qty-messure locqty0060151646 locqty"
                                                            maxlength="4" [(ngModel)]="item1.location[index].value"
                                                            (ngModelChange)="handleOnUnitChange($event,i,index)"><label
                                                            class="cart_unitLabel" for="locqty"><i>units</i></label>
                                                    </div>
                                                    <!-- <input type="hidden" class="prodprice" value="182.17">
                                                    <input id="ship_100528150060151646"
                                                        name="orderReviewdata[0].shipLocationQty[0].locID"
                                                        value="0060151646" type="hidden"> -->
                                                </div>
                                            </div>



                                            <div class="col-md-12 total_count locationrow ">
                                                <div class="col-md-8 col-xs-6 no-padding-left total-order-txt">
                                                    <strong>Total
                                                        Cost :</strong>
                                                </div>
                                                <div class="col-md-2 col-xs-6 no-padding-right total_doses_item">
                                                    <strong><span
                                                            class="total_doses">{{selectedProducts[i].totalDoses | number:'1.0'}}</span>&nbsp;doses</strong>
                                                </div>
                                                <div class="col-md-2 col-xs-12 no-padding-right total_cost_item">
                                                    <strong><span class="total_amnt"
                                                            *ngIf="selectedProducts[i].totalAmount">{{selectedProducts[i].totalAmount}}</span><span
                                                            class="total_amnt"
                                                            *ngIf="!(selectedProducts[i].totalAmount)">{{'0.00' |
                                                            currency: item1.priceData.currency}}</span></strong>
                                                </div>
                                            </div>

                                            <div class="clearfix"></div>

                                        </div>
                                    </div>

                                    <div class="product_according mobile_view pl-0 pr-0 d-block d-sm-block d-md-block d-lg-none d-xl-none"
                                        style="display: block;">
                                        <div class="col-md-12 pl-0 pr-0 left-pro-list">

                                            <div class="pro_desc">
                                                <div> {{item1.baseName}}</div>
                                                <div class="l-0 pr-0 "><i class="fa fa-trash-o"></i></div>

                                            </div>

                                            <div class="pro_desc ">

                                                <div> {{item1.presentationName}}</div>
                                                <div class="pro_desc pro_qty"><strong>Qty: <span
                                                            class="count_qty">{{selectedProducts[i].totalQuantity  | number:'1.0' }}</span></strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 pro_calc_wrapper collapse show" [id]="'part'+i"
                                            [attr.data-parent]="'#accordion'+i">
                                            <div class="row">
                                                <div class="col-xs-12 cart_doses_link">{{item1.priceData.value
                                                    | currency: item1.priceData.currency}}
                                                    unit&nbsp;&nbsp;|&nbsp;&nbsp;{{item1.doses}} doses per unit</div>
                                            </div>
                                            <div *ngIf="item1.location.length>0">
                                                <div class="locationrowall col-md-12 product_calc"
                                                    *ngFor="let locationDetail of item1.location; index as index">
                                                    <div>{{locationDetail.locname}} </div>
                                                    <div>{{locationDetail.address}}</div>
                                                    <div>{{locationDetail.state}}</div>
                                                    <div class="col-md-12 cart_inputUnit pl-0 pr-0">
                                                        <input name="'locationDetail{{i}}{{index}}'" min="0" max="9999"
                                                            placeholder="0" type="text"
                                                            class="form-control qty-messure locqty0060151646 locqty"
                                                            maxlength="4" [(ngModel)]="item1.location[index].value"
                                                            (ngModelChange)="handleOnUnitChange($event,i,index)"><label
                                                            class="cart_unitLabel" for="locqty"><i>units</i></label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-12 total_count locationrow ">
                                                <div class="col-md-10 col-xs-6 no-padding-left total-order-txt">
                                                    <strong>Total Cost :</strong>
                                                </div>
                                                <div class="col-md-2 col-xs-6 no-padding-right total_doses_item">
                                                    <strong><span class="total_doses">{{selectedProducts[i].totalDoses | number:'1.0' }}
                                                            doses</span></strong>
                                                </div>

                                            </div>
                                            <div class="col-md-12 col-xs-12 no-padding-right total_cost_item">
                                                <strong><span class="total_amnt"
                                                        *ngIf="selectedProducts[i].totalAmount">{{selectedProducts[i].totalAmount}}</span><span
                                                        class="total_amnt"
                                                        *ngIf="!(selectedProducts[i].totalAmount)">{{'0.00' |
                                                        currency: item1.priceData.currency}}</span></strong>
                                            </div>
                                            <div class="row mob-prescribe">
                                                <div class="spcl_3"><a [href]= "afluria" target="_blank"
                                                        class="cart_pdf_link">Prescribing
                                                        Information</a></div>
                                                <div><a href="/products/afluria" target="_blank"
                                                        class="cart_pdf_link">Important Safety Information</a></div>
                                                <div><a href="flu-vaccine-resources/afluria-coding-billing"
                                                        target="_blank" class="cart_pdf_link">Coding &amp; Billing
                                                        Guide</a></div>
                                            </div>
                                        </div>
                                        <div class="pro_desc col-xs-12 notvisible-ipad mobile-according">
                                            <span><i class="fa show_pro_details fa-angle-up" aria-hidden="true"
                                                    (click)="handleOnToggle(item1)" aria-hidden="true"
                                                    data-toggle="collapse" [attr.data-target]="'#part'+i"></i></span>
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div class="col-xs-12 top-b"></div>
                        </div>


                        <div class="col-xs-12 col-md-12 pr-0 pl-0">
                            <!-- add product -->
                            <div class="col-12 col-lg-12 col-xl-12 pl-0 pr-0 prod_mobView" style="display: flex;">
                                <div class="col-12 col-lg-10 col-xl-10 pl-0 pr-0">
                                    <div class="add_product_btn add_product_btn12"
                                        [ngClass]="showProductList ? 'border-add' : ''">Add Product <i
                                            class="fa fa-plus" aria-hidden="true"
                                            (click)="handleOnAddProductBtnClick()"></i></div>
                                    <div class="col-md-12 cart_list_wrap" *ngIf="showProductList">
                                        <ng-container
                                            *ngIf="seasonValue === 'inSeason' && orderLists.inSeasonProducts.length > 0">
                                            <div class="col-md-12 prod_list active_stage add_pro_cart"
                                                *ngFor="let item of orderLists.inSeasonProducts; index as i">
                                                <div class="col-md-4 pl-0 pr-0"
                                                    [ngStyle]="{'cursor': item.sellable ? 'pointer' : 'default'}">
                                                    {{item.baseName}}</div>
                                                <div class="col-md-6 pl-0 pr-0"
                                                    [ngStyle]="{'cursor': item.sellable ? 'pointer' : 'default'}">
                                                    {{item.presentationName}}</div>
                                                <div class="col-md-2 pl-0 pr-0"
                                                    [ngStyle]="{'cursor': item.sellable ? 'pointer' : 'default'}"
                                                    (click)="handleOnAddClick(item, i)">
                                                    <span *ngIf="item.sellable">ADD&nbsp;&nbsp;<i class="fa fa-plus"
                                                            aria-hidden="true"></i></span>
                                                    <span *ngIf="!item.sellable" style="color:red">SOLD OUT</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="seasonValue === 'preSeason' && orderLists.preBookProducts.length > 0">
                                            <div class="col-md-12 prod_list active_stage add_pro_cart"
                                                *ngFor="let item of orderLists.preBookProducts; index as i">
                                                <div class="col-md-4 pl-0 pr-0">{{item.baseName}}</div>
                                                <div class="col-md-6 pl-0 pr-0">{{item.presentationName}}</div>
                                                <div class="col-md-2 pl-0 pr-0"><span
                                                        (click)="handleOnAddClick(item, i)">ADD&nbsp;&nbsp;<i
                                                            class="fa fa-plus" aria-hidden="true"></i></span></div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-2 col-xl-2 mob_border pr-0 d-lg-block d-xl-block">
                                    <div class="btn-confirm " (click)="handleOnPlaceOrderBtnClick()"
                                        [ngClass]="selectedProducts.length === 0 ? 'rvw_btn_disable' : 'rvw_btn_enable'">
                                        Review &amp; Place Order</div>
                                    <!-- <button class="rvw_btn_enable col-xs-7" style="width: 100%" >Review &amp; Place
                                        Order</button> -->

                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- </div> -->
                </div>
                <div>
                </div><!-- Add product DIV ends -->
            </div>
        </div>

        <div *ngIf="showReviewContent && orderLists">
            <div class="cart_delete">
                <div class="global_blackarrow"></div><button data-toggle="modal" data-target="#cart_deletemodal"
                    data-backdrop="false"><span style="font-family: 'CamptonSemiBold'">Delete Cart &amp; Start New Order</span></button>
            </div>

            <section class="cart_selectlocations" id="cart_summaryLocation" style="display: block;">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_memberbox mb-5">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryContentbox pl-0 pr-0">
                        <div class="cart_summaryProductselection" style="display: flex;">
                            <div class="global_blackarrow"></div>
                            <div (click)="handleOnBackToProductBtnClick()">Back to Product Selection</div>
                        </div>

                        <!-- membership changes start's -->
                        <div class="col-xs-12 memberline"></div>
                        <!-- <div class="col-xs-12 memberThankyou activecontractnotsign">
                            <p>Your membership is&nbsp;<span class="productmembername">{{orderLists.membershipData.currentMembership}}</span>.</p>
                            <p>Prices shown may not be final for your selected membership. Once pricing is finalized
                                for the applicable flu season, pricing will be reflected on your invoice. You can
                                edit your current membership in <a class="membercustomerlink" href="/my-profile">My
                                    Profile</a>.</p>
                        </div> -->
                        <div class="col-xs-12 pl-0 pr-0"
                            *ngIf="orderLists && (orderLists.membershipData.membershipStatus == 'Active')">
                            <div *ngIf="activeMembershipContent" class="productmemberdetails">
                                <p>
                                    {{activeMembershipContent.title}}
                                    <b>{{orderLists.membershipData.currentMembership}}.</b>
                                </p>
                                <div [innerHTML]="activeMembershipContent.content"></div>
                            </div>
                        </div>
                        <div class="col-xs-12"
                            *ngIf="orderLists && orderLists.membershipData.membershipStatus == 'Pending'">
                            <div class="col-xs-12 productmemberdetails" *ngIf="pendingMembershipContent">
                                <p>
                                    {{pendingMembershipContent.title}}
                                </p>
                                <div [innerHTML]="pendingMembershipContent.content"></div>
                            </div>
                        </div>

                        <!-- membership changes end's -->
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summarytable no-padding"
                            id="cart_summaryProductTable">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let item of cartListData.products">
                                            <th><br>{{item.product}}<br><br><span>{{item.dose}}</span></th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let locations of cartListData.locations">
                                        <tr>
                                            <td>
                                                {{locations.location}}
                                            </td>
                                            <ng-container *ngFor="let productCount of locations.locationProducts">
                                                <td>{{productCount.locQty | number:'1.0'}}</td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                    <tr style="background-color:#F9FAFA">
                                        <ng-container *ngFor="let productQty of cartListData.productstotalQty">
                                            <td>
                                                {{productQty.qty | number:'1.0'}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                    <tr>
                                        <ng-container *ngFor="let productCost of cartListData.productstotalCost">

                                            <td>
                                                {{productCost.cost | number:'1.2-2'}}
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryProductTableMob">
                                <div class="cart_summaryProductname">
                                    <div class="cart_summaryProductdetails">AFLURIA</div>
                                    <div class="cart_summaryProductdetails">0.5-mL pre-filled syringe</div>
                                    <div class="cart_summaryProductdetails"><span>Qty: </span>30</div>
                                    <div class="cart_summaryProductdetails" style="display: none;"><span>Total Cost:
                                        </span>$5,464.00</div>
                                    <div class="cart_summaryProductdetails"></div>
                                    <div class="cart_summaryLocationcontainer" style="display: none;">
                                        <div class="cart_summaryLocationname">
                                            <div class="cart_summaryLocationdetails">Bhat Internal Medicine</div>
                                            <div class="cart_summaryLocationdetails">1619 N Swan Road</div>
                                            <div class="cart_summaryLocationdetails">Tucson, AZ, 85712</div>
                                            <div class="cart_summaryLocationdetails"><span>Qty: </span>10</div>
                                        </div>
                                        <div class="cart_summaryLocationname">
                                            <div class="cart_summaryLocationdetails">ship org</div>
                                            <div class="cart_summaryLocationdetails">1 Church St Bldg 65</div>
                                            <div class="cart_summaryLocationdetails">New york, NY, 10006-1404</div>
                                            <div class="cart_summaryLocationdetails"><span>Qty: </span>20</div>
                                        </div>
                                    </div>
                                    <div class="cart_summarytogglearrow"><span
                                            class="glyphicon glyphicon-menu-down cart_summaryshowdetails"></span>
                                    </div>
                                </div>
                                <div class="cart_summaryProductname">
                                    <div class="cart_summaryProductdetails">FLUCELVAX</div>
                                    <div class="cart_summaryProductdetails">0.5-mL pre-filled syringe</div>
                                    <div class="cart_summaryProductdetails"><span>Qty: </span>30</div>
                                    <div class="cart_summaryProductdetails" style="display: none;"><span>Total Cost:
                                        </span>$7,869.00</div>
                                    <div class="cart_summaryProductdetails"></div>
                                    <div class="cart_summaryLocationcontainer" style="display: none;">
                                        <div class="cart_summaryLocationname">
                                            <div class="cart_summaryLocationdetails">Bhat Internal Medicine</div>
                                            <div class="cart_summaryLocationdetails">1619 N Swan Road</div>
                                            <div class="cart_summaryLocationdetails">Tucson, AZ, 85712</div>
                                            <div class="cart_summaryLocationdetails"><span>Qty: </span>10</div>
                                        </div>
                                        <div class="cart_summaryLocationname">
                                            <div class="cart_summaryLocationdetails">ship org</div>
                                            <div class="cart_summaryLocationdetails">1 Church St Bldg 65</div>
                                            <div class="cart_summaryLocationdetails">New york, NY, 10006-1404</div>
                                            <div class="cart_summaryLocationdetails"><span>Qty: </span>20</div>
                                        </div>
                                    </div>
                                    <div class="cart_summarytogglearrow"><span
                                            class="glyphicon glyphicon-menu-down cart_summaryshowdetails"></span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="product_according review_mobile_view pl-0 pr-0 d-block d-sm-block d-md-block d-lg-none d-xl-none"
                                style="display: block;" *ngIf="cartListData">
                                <ng-container *ngFor="let item1 of cartListData.data; index as i">
                                <div class="col-md-12 pl-0 pr-0 left-pro-list">

                                    <div class="pro_desc">
                                        <div> {{item1.productname}}</div>

                                    </div>

                                    <div class="pro_desc ">

                                        <div> {{item1.dose}}</div>

                                    </div>
                                    <div class="pro_desc pro_qty"><strong>Qty: <span
                                                class="count_qty">{{item1.totalquantity | number:'1.0'}}</span></strong>
                                    </div>
                                </div>
                                <div class="col-md-12 pro_calc_wrapper collapse show" [id]="'part1'+i"
                                    [attr.data-parent]="'#accordion1'+i">
                                    <div class="row">
                                        <div class="col-md-12 total_count locationrow ">
                                            <div class="col-md-10 col-xs-6 no-padding-left total-order-txt">
                                                <strong>Total Cost :</strong>
                                            </div>
                                            <div class="col-md-12 col-xs-12 no-padding-right total_cost_item">
                                                <strong><span class="total_amnt">${{item1.totalcost}}</span></strong>
                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="item1.locations.length>0" class="reviewlocation">
                                        <ng-container  *ngFor="let locationDetail of item1.locations; index as index">
                                        <div class="locationrowall col-md-12 product_calc">
                                            <div>{{locationDetail.locname}} </div>
                                            <div>{{locationDetail.locstreet}}</div>
                                            <div>{{locationDetail.state}}</div>
                                            <div class="col-md-12 cart_inputUnit pl-0 pr-0" style="padding-left:0px !important">
                                                <strong>Qty: <span
                                                        class="count_qty">{{locationDetail.locqtn}}</span></strong>
                                            </div>

                                        </div>
                                    </ng-container>
                                    </div>
                                </div>
                                <div class="pro_desc col-xs-12 notvisible-ipad mobile-according" accordion [id]="'accordion1'+i" >
                                    <span><i class="fa show_pro_details fa-angle-up" aria-hidden="true"
                                            (click)="handleOnToggle1(item1)" aria-hidden="true" data-toggle="collapse"
                                            [attr.data-target]="'#part1'+i"></i></span>
                                </div>
                            </ng-container>
                            </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryborder"></div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryContentbox ">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarybilling no-padding-left">
                            <div class="cart_summarybillingHeader">Billing Location</div>
                            <div class="cart_summarybillingaddressline" id="cart_summarybillingaddressline1">
                                {{cartListData.billing.addressline1}}</div>
                            <div class="cart_summarybillingaddressline" id="cart_summarybillingaddressline2">
                                {{cartListData.billing.addressline2}}</div>
                            <div class="cart_summarybillingtext">Your billing location is where invoices will be
                                sent. Any billing location edits may take several business days to verify and may
                                impact ability to place orders.</div>
                            <a class="cart_summaryupdatebilling" href="/organization-location" target="_blank">Update
                                Billing Location<div class="global_blackarrow"></div>
                            </a>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarypayer no-padding-right">
                            <div class="cart_summarypayerHeader">Payer Location</div>
                            <div class="cart_summarypayeraddressline" id="cart_summarypayeraddressline1">
                                {{cartListData.paying.addressline1}}</div>
                            <div class="cart_summarypayeraddressline" id="cart_summarypayeraddressline2">
                                {{cartListData.paying.addressline2}}</div>
                            <div class="cart_summarypayertext">Your payer location is where all account statements
                                will be sent, and can be the same as your billing location. Any payer location edits
                                may take several business days to verify and may impact ability to place orders.
                            </div>
                            <a class="cart_summaryupdatepayer" href="/organization-location" target="_blank">Update
                                Payer Location<div class="global_blackarrow"></div>
                            </a>
                        </div>
                        <div class="clearboth"></div>
                        <div class="col-xs-12 cart_summaryPOsection">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarybilling no-padding-left">
                                <div class="cart_summaryPO">Enter PO Numbers</div>
                                <div class="cart_summaryPOlabel">PO NUMBER</div>
                                <div class="cart_summaryPOinput">
                                    <input type="text" id="poRefNumber" class="cart_summaryPOinputbox" maxlength="10"
                                        [(ngModel)]="poNumber" #autogenerateNo="ngModel">
                                    <div class="cart_summaryPOautogenerate" (click)="handleOnAutoGeneratePoNo()">
                                        Autogenerate PO</div>

                                </div>
                                <div class="cart_summaryPO-error cart_summary-error"
                                    *ngIf="(autogenerateNo.dirty && autogenerateNo.value == '') || (showErrorMsg && !poNumber)">
                                    Please add a PO number or use the 'Autogenerate PO' function.</div>
                                <!-- <div class="cart_summaryPO-error cart_summary-error hidden">Please add a PO number
                                    or use the 'Autogenerate PO' function.</div> -->
                                <!-- terms and condition changes -->

                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarypayer no-padding-right">
                                <div class="cart_summaryorderTotal">Order Total</div>
                                <div class="cart_summarysubTotal">
                                    <div class="cart_summarysubTotallabel cart_summaryLabel">Subtotal:</div>
                                    <div class="cart_summarysubTotalno cart_summarySum">{{cartListData.cost.subtotal}}
                                    </div>
                                </div>
                                <div class="cart_summaryexcise">
                                    <div class="cart_summaryexciselabel cart_summaryLabel">Federal Excise Tax:</div>
                                    <div class="cart_summaryexciseno cart_summarySum">{{cartListData.cost.federalTax}}
                                    </div>
                                </div>
                                <div class="cart_summarytotal">
                                    <div class="cart_summarytotallabel cart_summaryLabel">Total Cost :</div>
                                    <div class="cart_summarytotalno cart_summarySum">{{cartListData.cost.totalCost}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clearboth"></div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarybilling no-padding-left">
                            <div class="cart_summaryshipping">Shipping Timeframes</div>
                            <div class="cart_summaryshippingtext">Your <a href="/orders/my-orders"
                                    target="_blank">Orders</a> page offers the most recent shipping information as
                                shipping dates approach.</div>
                            <div class="cart_summaryshippingtext margin-B30">Review <a
                                    href="/terms-conditions-returnability" target="_blank">Influenza Vaccine Returns
                                    FAQ</a></div> <!-- terms and condition changes -->

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarypayer summarypayer no-padding-right"
                            id="summarypayer1" style="display: block;">
                            <div class="cart_summaryexpected">Expected Shipping for <span
                                    *ngIf="seasonValue === 'inSeason'">2024-2025</span><span
                                    *ngIf="seasonValue === 'preSeason'">2025-2026</span></div>
                            <div class="cart_summaryexpectedtext">Shipping for the 2024-2025 season is expected to
                                begin July 2024. Deliveries are prioritized based on when they were ordered. Please
                                refer to your <a href="/orders/my-orders" target="_blank">Orders</a> page for the
                                most recent shipping information and shipping dates.</div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 cart_summarypayer summarypayer no-padding-right"
                            id="summarypayer2" style="display: none;">
                            <div class="cart_summaryexpected">Expected Shipping for 2024-2025</div>
                            <div class="cart_summaryexpectedtext">Shipping for the 2024-2025 season is expected to
                                begin July 2024. Deliveries are prioritized based on when they were ordered. Please
                                refer to your <a href="/orders/my-orders" target="_blank">Orders</a> page for the
                                most recent shipping information and shipping dates.</div>
                        </div>
                        <div class="clearboth"></div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryborder"></div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_summaryContentbox ">
                        <div class="col-xs-12 no-padding-left no-margin-top cart_summaryexpected">Terms &amp;
                            Conditions of Sale</div>
                        <div class="col-xs-12 cart_summarybilling no-padding-left">

                            <div class="pdf-container">
                                <iframe class="pdf desktoppdf hidden-xs hidden-sm" id="pdf-iframe1" [src]="cleanURL()"
                                    width="100%" frameborder="0" scrolling="no"></iframe>

                                <div style="padding-left: 2px;">
                                    <!-- <label class="checkbox-button">
                                        
                                        <span class="checkbox-button__control"></span>
                                    </label> -->
                                    <span
                                        [ngClass]="checkbox.dirty && checkbox.value == true ? 'activeCheckbox' : 'checkbox'"
                                        class="mr-2">
                                        <input type="checkbox" class="" id="" [(ngModel)]="termsCheckbox"
                                            #checkbox="ngModel">
                                    </span>
                                    <span id="summarycheckpayer2">
                                        <label>By placing this order, I am confirming that I have read and agree to
                                            the <a href="/terms-conditions-sales-april-2024-2025" target="_blank">Terms
                                                and Conditions of Sale</a>.</label>
                                    </span>

                                    <!-- <div id="summarycheckpayer1">
                                        <label>By placing this order, I am confirming that I have read and agree to
                                            the <a href="/terms-conditions-sales-april-2024-2025"
                                                target="_blank">Terms and Conditions of Sale</a>.</label>
                                    </div> -->
                                </div>

                            </div>

                        </div>

                        <div class="col-xs-12 cart_summarypayer margin-B30 cart_summarypayer no-padding-right">
                            <div class="cart_summarycheckbox-error cart_summary-error"
                                *ngIf="(checkbox.dirty && checkbox.value == false) || (showErrorMsg && !termsCheckbox)">
                                Please read and agree
                                to the Terms and Conditions of Sale document.</div>

                        </div>
                        <button class="cart_summaryPlaceorder active" (click)="handleOnPlaceOrder()">Place
                            Order</button>
                        <div class="clearboth"></div>

                    </div>
                </div>
            </section>
        </div>
        <div *ngIf="showThankYouContent">
            <section class="col-xs-11 col-md-11 cart_thankyousection" style="display: block;"
                data-gtm-vis-recent-on-screen55327271_15="2134467" data-gtm-vis-first-on-screen55327271_15="2134467"
                data-gtm-vis-total-visible-time55327271_15="100" data-gtm-vis-has-fired55327271_15="1">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_thankyou">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 cart_thankyouContentbox ">
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cart_Thankyouheader">Thank You for
                            Submitting Your Order</div>
                        <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 cart_Thankyoutext">
                            <p>Your order is confirmed! Please note, if you placed an order immediately after creating
                                your flu360 account or after adding a new location, the status of your order will not be
                                available until your account information is verified, which takes 5-7 business days.</p>
                            <p>Once verified, you will be able to see your order information in your account on
                                your&nbsp;<a href="/orders/my-orders">Orders</a>&nbsp;page, as well as further shipping
                                details and exact shipping dates as the shipment window approaches. Thank you for your
                                partnership in the fight against influenza!</p>
                        </div>
                        <div class="clearboth"></div>
                        <button class="cart_thankyouButton" (click)="handleOnViewOrders()">View Orders</button>
                    </div>
                </div>
            </section>
        </div>
        <section class="col-xs-12 col-md-11 cart_selectlocation collapse" id="cart_selectlocation-3"
            style="display: block;" *ngIf="showBulkOrderSection">
            <div class="cart_bulkOrder-desktop d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <div class="col-xs-10 cart_bulkOrder-header">Place a bulk order</div>
                <div class="clearfix"></div>
                <hr>
                <div class="cart_bulkOrder-container">
                    <div class="cart_bulkOrder-instruction">
                        For multiple shipping locations, reduce the time it takes to place an order. Simply follow these
                        directions to upload your entire order at once with the use of a pre-populated spreadsheet.
                        <ol>
                            <li>Download the pre-populated excel file for the correct season.</li>
                            <li> Fill out the Excel file with the desired units of product for each location.</li>
                            <li>Upload and submit the Excel file.</li>
                            <li>View and manage orders in <a href="/orders/my-orders" target="_self">My Orders</a>.</li>
                        </ol>
                        <div class="cart_bulkOrder-info">Please contact <a href="mailto:customerservice.us@seqirus.com"
                                [innerHTML]="'customerservice.us@seqirus.com'"></a>
                            with any questions you may have.</div>
                    </div>
                    <div class="cart_bulkOrder-download">
                        <div class="cart_bulkOrder-downloadText">Excel spreadsheets available for download:</div>
                        <!-- *ngIf="orderLists && orderLists.preSeasonEnable" *ngIf="orderLists && orderLists.inSeasonEnable" -->
                        <div class="cart_bulkOrder-InSeason cart_bulkOrder-season"
                            (click)="handleOnBulkOrderSeasonBtnClick('inseason')">2024 - 2025 Season Spreadsheet</div>
                        <div class="cart_bulkOrder-PreSeason cart_bulkOrder-season"
                            (click)="handleOnBulkOrderSeasonBtnClick('preseason')">2025 - 2026 Season Spreadsheet</div>
                    </div>
                </div>

                <div class="clearfix"></div>
                <div class="col-xs-12 cart_bulkOrder-UploadContainer" *ngIf="orderSubmitContent == '' || orderSubmitContent != 'success'">
                    <div class="drop-area col-xs-12 " id="drop-area-excel" *ngIf="!isUploading">
                        <div class="drag-form" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                            (drop)="onDrop($event)">
                            <div class="dragiconsection">
                                <img src="../../assets/images/cloud-upload.svg">
                                <p class="d-none d-md-block d-lg-block">Drag and drop file here</p>
                            </div>
                            <p class="d-none d-md-block d-lg-block">or</p>
                            <input type="file" class="fileElemInp" id="fileElem-excel" [(ngModel)]="bulkOrderExcel"
                                (change)="onFileChange($event)"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

                            <label class="button" for="fileElem-excel">Browse files</label>
                        </div>
                        <div>
                        </div>
                    </div>

                    <div class="col-xs-9 uploading " id="uploadingscreen">
                        <div *ngIf="isUploading && uploadData == ''" class="filecont">
                            <div class="uplaodingfilenameparent">
                                <i class="fa fa-file-text"></i>
                                <div class="upload-container">
                                    <div class="uplaodingfilename floatLeft" *ngIf="bulkOrderExcel">
                                        {{bulkOrderExcel.name}}</div>
                                    <div class="uploadinglabel floatLeft "> - Uploading</div>
                                    <div class="clearfix"></div>
                                    
                                    <div id="progressContainer" [style.width] = "'100%'">
                                        <div class="progress" id="progressBar" [style.width] = "progressValue + '%'">
                                        </div>
                                    </div>
                                    <div class="uploadFile-size hidden"></div>
                                </div>
                                <div class="uploadingcancel" (click)="removeFile()"> <span
                                        class="glyphicon glyphicon-remove-circle"></span></div>
                            </div>
                        </div>
                    </div>
                    <!-- error section for format and file size -->
                    <div class="col-xs-9 errorformat">
                        <div class="errorsection" *ngIf="uploadData != ''">

                            <div class="errorfilenameParent"><i class="fa fa-file-text"></i>
                                <div class="errorfilename" *ngIf="bulkOrderExcel">
                                    <div>{{bulkOrderExcel.name}}</div><div>{{fileSize}}</div></div>
                                <div class="uploadingcancel uploadingremove" (click)="removeFile()"> <img alt="" class=" floatLeft"
                                    src="/assets/images/trashblackicon.png" width="24"></div>
                            </div>
                            
                        </div>
                        
                        <div class="mt-5 After-uploadSucess" *ngIf="uploadData == 'success' && !showTryAgain">
                            <div class="uploadSubmit" (click)="handleOnUploadSubmit()">Submit</div>
                        </div>
                        <!-- img will be added here -->
                        <div class="errorMessage" id="errorformat1" *ngIf="uploadData == '' && showError1Msg"
                            style="display: flex">
                            <div><img alt="" class="circle-exclamation floatLeft"
                                    src="../../assets/images/exclamation-circle.svg">
                                <img alt="" class="circle-exclamation floatLeft">
                            </div>

                            <div class="uploaderrormessage floatLeft" *ngIf="uploadData == '' && showError1Msg">Unable
                                to upload file.
                                <p> The file must be an Excel file(.xls or .xlsx) that is 5MB or less.<br>Please check
                                    your file and try again.</p>
                            </div>
                            <div class="clearfix"></div>

                        </div>
                        <div *ngIf="showError1Msg || showTryAgain" class="tryagain" (click)="handleOnTryAgainBtnClick()">Try again</div>
                        <div class="errorMessage" id="errorformat2" *ngIf="uploadData == 'failure'"
                            style="display: flex">
                            <div><img alt="" class="circle-exclamation floatLeft"
                                    src="../../assets/images/exclamation-circle.svg">
                                <img alt="" class="circle-exclamation floatLeft">
                            </div>
                            <div class="uploaderrormessage floatleft">Unable to upload file.
                                <p>There's an error with your Excel file. It could be the file formatting, an
                                    unavailable season selection, or zero units entered. Please check your file and try
                                    again.</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div *ngIf="showError1Msg || (uploadData == 'failure')" class="tryagain"
                            (click)="handleOnTryAgainBtnClick()">Try again</div>
                        <div class="errorMessage" id="errorformat3" *ngIf="orderSubmitContent == 'failure'">
                            <!-- <img alt="" class="circle-exclamation floatLeft" src="_ui/responsive/theme-lambda/images/exclamation-circle.svg"> -->
                            <img alt="" class="circle-exclamation floatLeft">
                            <div class="uploaderrormessage floatleft">Unable to submit file. <p> Something went wrong.
                                    Please try uploading your file again or contact <a
                                        href="mailto:customerservice.us@seqirus.com">customerservice.us&#64;seqirus.com</a>
                                    if the issue persists.</p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 cart_bulkOrder-submittedmsg" *ngIf="orderSubmitContent == 'success'">
                    <div class="cart_bulkOrder-msgHeader">Thank you for submitting your order.</div>
                    <!-- sit changes -->
                    <div class="cart_bulkOrder-msgBody">
                        <p>Your order is confirmed!</p> <!-- sit changes -->
                        <p>Once verified, you will be able to see your order information in your account under <a
                                href="/orders/my-orders">My Orders</a>, as well as shipping details and exact shipping
                            dates as the shipment window approaches.<br><br> Please allow 24 hours for your order
                            information to become available.</p> <!-- sit changes -->
                        <p>Thank you for your partnership in the fight against infuenza!</p> <!-- sit changes -->
                    </div>
                    <a href="/orders/my-orders">
                        <div class="cart_bulkOrdervieworder">View Orders</div>
                    </a>
                </div>
            </div>
            <div class=" col-xs-12 d-block d-sm-block d-md-none d-lg-none d-xl-none cart_bulkOrder-mobile">
                <div class="col-xs-12 cart_bulkOrder-header">Bulk ordering unavailable</div>
                <div class="col-xs-12 cart_bulkOrder-MobileMsg">Bulk upload is not supported on mobile devices. Please
                    log in from a desktop or laptop to bulk order for multiple shipping locations.</div>
                <div class="clearfix"></div>
                <a href="/my-account">
                    <div class="cart_bulkOrder-MobileButton">Back to My Dashboard</div>
                </a>
            </div>
            <div class="clearfix"></div>
        </section>
    </div>
</div>

<div id="cart_deletemodal" class="modal" role="dialog" style=" padding-right: 10px;" tabindex="-1"
    aria-labelledby="forgotModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body row">
                <h4 class="modal-title cart_deleteheader col-xs-11">Delete Cart</h4>
                <div class="no-padding pull-right"><span class="cart-delete_close" data-dismiss="modal"
                        aria-label="Close">×</span></div>
                <p class="cart_deletesubheader col-xs-12">
                    Are you sure you want to delete your cart? If yes, location selections and all product selections
                    will be lost.
                </p>
                <div class="clearboth"></div>
                <div class="cart_deletebuttons" style="display:flex">
                    <button class="cart_deleteYes global_redbtn" (click)="handleOnDeleteModelYesBtnClick()">Yes</button>
                    <button type="button" class="cart_deleteNo global_greybtn" data-dismiss="modal"
                        #closeButton>No</button>
                </div>
            </div>
        </div>
    </div>
</div>