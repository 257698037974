import { Component } from '@angular/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-financial-banner',
  templateUrl: './financial-banner.component.html',
  styleUrl: './financial-banner.component.scss'
})
export class FinancialBannerComponent {
  currentseason:any;
  constructor(private userInfoService: UserInfoService){
    
  }
  ngOnInit(): void {
    this.userInfoService.financialcurrentseason().subscribe(value=>{
      this.currentseason=value;
      });
  }
  }

