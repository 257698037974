import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService, OccEndpointsService, SemanticPathService } from "@spartacus/core";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { UserInfoService } from "../spartacus/services/user-info.service";


@Injectable({
  providedIn: 'root'
})
export class SeqirusNotAuthGuard {
  constructor(private authService: AuthService, private router: Router,
    private userInfoService: UserInfoService, private occ: OccEndpointsService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | any {
    return this.authService.isUserLoggedIn().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
         this.userInfoService.getUserData().subscribe(res => {
            if (res) {
              if (res.webEnabled) {
                this.router.navigate(['/my-account']);
              }
              else{
                this.router.navigate(['/homepage']);
              }
            }
          })
          return false;
        }
        if (this.occ.getBaseUrl().includes('seqirusb2b-uk') && !localStorage.getItem('isLandedToSplashPage')) {
          localStorage.setItem('isLandedToSplashPage', 'true');
          return true;
        }
        this.router.navigate(['/homepage']);
        return true;

      })
    );
  }
}

