import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-helpsection',
  templateUrl: './helpsection.component.html',
  styleUrl: './helpsection.component.scss'
})
export class HelpsectionComponent {
  constructor(public component: CmsComponentData<any>){
    
  }
  helpContent: Observable<any> = this.component.data$;

 /*  ngOnInit(): void {
    this.helpContent.subscribe(res => {
      this.helpContent=res;
    }); */
    
   
}
