import { Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrl: './business-details.component.scss'
})
export class BusinessDetailsComponent {
  response:string="";
  pageLabel: any;
  constructor(public component: CmsComponentData<any>,private cmsService:CmsService) {
    this.pageLabel = this.cmsService.getCurrentPage().pipe(map((pageDta) => pageDta.label || ""));
  
    
  }
  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      this.response = data.uid;
     // alert(this.response);
    })
  }
  isThankYouPage():Observable<boolean> | undefined{
    return this.pageLabel?.pipe(map((label) => label === 'register-thank-you'));
  }
}
