<div class="footer_container" *ngIf="(footernode$ | async) as footernodes">
    <div class="container-fluid">
        <div class="row">
            <ng-container *ngFor="let node of footernodes.children">
            <div *ngIf="node.title == 'Footer Logo'" class="col-md-4">
                <div class="footer-logo">
					<div class="logo_seqirus" *ngIf="(logoData | async) as data">
					<img [src]='getImage(data.media)' alt="seqirus">
                    </div>
                    <div class="fotter_txt" *ngIf="(visitsite | async) as site" [innerHTML]="site.content"></div>
				</div>
            </div>
            <ng-container *ngIf="!(isUserLoggedIn | async) && node.title === 'Navigate';else forlogin">
                <div class="mob-footer-md-3">
				
                        <div class="footer_heading footerNavPart">{{node.title}}</div>
                        
                        <ul class="footer_menu_list footerNavPart navigate_menu footer_menu_list_nav">
                            <ng-container *ngFor="let child of node.children">
                             
                                <li *ngIf="child.title != 'Returns'"><a [routerLink]="child.url" target="_blank">{{child.title}}</a></li>
                             
                                <li *ngIf="child.title === 'Returns'"><a [routerLink]="child.url" [queryParams]="{returns: 'true'}" target="_blank" id="return_links">{{child.title}}</a></li>
                            </ng-container>   
                              
                        </ul>
                </div>
                </ng-container>
                <ng-template #forlogin>
                    <div *ngIf="node.title === 'Navigate'" class="mob-footer-md-3"></div>
                </ng-template>
                <div *ngIf="node.title === 'Website Terms of Use'" class="mob-footer-md-5">
                        <div class="footer_heading">{{node.title}}</div>
                        <ul class="footer_menu_list">
                        <ng-container *ngFor="let child of termsnConditionNodes">
                            <li *ngIf="child.title == 'Terms and Conditions of Returnability'"><a [routerLink]="child.url" target="_blank" >{{child.title}}</a></li>
                            <li *ngIf="child.title == 'Terms and Conditions of Privacy'"><a href="{{child.url}}" target="_blank" >{{child.title}}</a></li>
                        
                            <li *ngIf="child.title != 'Terms and Conditions of Privacy' && child.title != 'Terms and Conditions of Returnability'"><a [href]="getImage(child)" target="_blank" >{{child.title}}</a></li>
                        </ng-container>             
                        </ul>
                </div>
            </ng-container>
        </div>
        <div class="row">
            <div class="mob-footer-md-12">
                <div class="d-lg-none d-block d-sm-block margin-T20 margin-B20">
                <div class="logo_seqirus" *ngIf="(logoData | async) as data">
                
                <img [src]='getImage(data.media)' alt="seqirus">
                
                </div>
                <div class="fotter_txt" *ngIf="(visitsite | async) as site" [innerHTML]="site.content"></div>
            </div>
        </div>
        </div>
        <div class="row">
			<div class="mob-footer-md-12 footer_txt_disclaimer" *ngIf="(footerPara | async) as para">
                <div class ="content" [innerHTML]="para.content">
                    
                </div>
            </div>
        </div>

        <div class="row">
            <div class="mob-footer-md-12 fotter_bottom_logo">
               
            </div>
        </div>

    </div>
</div>