import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BaseSiteService, CmsService, PageMeta, PageMetaConfig, PageMetaService, UnifiedInjector } from '@spartacus/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustompagemetatitleService extends PageMetaService {
  currentSite: string='';

  constructor(protected override cms: CmsService,
    protected override unifiedInjector: UnifiedInjector,
    protected override pageMetaConfig: PageMetaConfig,
    @Inject(PLATFORM_ID) protected override platformId: string,
    private baseSiteService:BaseSiteService){
    super(cms,unifiedInjector,pageMetaConfig,platformId);
  }

  public override getMeta():Observable<PageMeta>{

    this.baseSiteService.getActive().subscribe(data =>{
      this.currentSite = data;
    });
    if(this.currentSite == 'seqirusb2b-flu360'){
      return super.getMeta().pipe(
      map((meta) => ({
      ...this.meta$,
      title:`${meta?.title} | CSL Seqirus flu360`,
    })));
    }
    else if(this.currentSite == 'seqirusb2b-uk'){
      return super.getMeta().pipe(
      map((meta) => ({
      ...this.meta$,
      title:`${meta?.title} | flu360 GB`,
      })));
    }
    return super.getMeta().pipe(
    map((meta) => ({
    ...this.meta$,
    title:`${meta?.title}`,
    })));
  }

}


