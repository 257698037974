<div class="col-md-11 col-sm-12 col-xs-12 myprofile_flex myprofile_flex_cdc myprofile_flex_items" id="myProfilepage">
<div class="myprofile_membership paymentcontainer">
    <div class="col-xs-12 myprofile_paymentmanage">
        <a class="anchorunderline" [routerLink]="['/payment-information']">Manage</a>
        <div class="global_blackarrow paymentarrow"></div>
    </div>
    <div class="myprofile_tophybrisheader">Payment Information</div>
    <ng-container *ngIf="paymentDetails$ | async as item">
    <div class="margin-T20 myprofile_hybrisheader" *ngIf="item.hasSavedCard">You have saved card(s).</div>
    <div class="margin-T20 myprofile_hybrisheader" *ngIf="(!item?.hasSavedCard) && (!item?.hasExpiredCard)">You don't have any saved credit cards. You can save a credit card during the payment process or by selecting Manage.</div>
    <div class="margin-T10 myprofile_hybrisheader paymentexpire" *ngIf="item?.hasExpiredCard"><img
            src="/assets/images/exclamation-mark.png" class="financial_alertImg padding-R10">
        Card(s) expired or expiring soon</div>
    </ng-container>
</div>
</div>