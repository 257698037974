import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-second',
  templateUrl: './second.component.html',
  styleUrl: './second.component.scss'
})
export class SecondComponent {

  object : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusSecondComponent');

  constructor(private cmsService:CmsService, protected config: OccConfig,) {
  }
}
