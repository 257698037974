import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-multiple-needs',
  templateUrl: './multiple-needs.component.html',
  styleUrl: './multiple-needs.component.scss'
})
export class MultipleNeedsComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,protected config: OccConfig,public accessService:ContentService,private cd:ChangeDetectorRef){}
  bannerNode: Observable<any> = this.component.data$;
  // data1: any;
  // data2: any;
  // data3: any; 
  // opeffdata1:any;
  // oppeffdata2:any;
  // mergedObject1 :any= [];
  // mergedObject2:any=[];
  tilesarray:any=[];
  // public resourse1: Observable<any> = this.accessService.getWhatif1(); 
  // public resourse2: Observable<any> = this.accessService.getWhatif2(); 
  // public resourse3: Observable<any> = this.accessService.getWhatif3(); 
  //public resourseFinal: Observable<any>= this.accessService.getMultipleResources()
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
  // getContent(){
  //   combineLatest([this.resourse1, this.resourse2, this.resourse3]).subscribe(([data1, data2, data3]) => {
  //     this.data1 = data1;
  //     this.data2 = data2;
  //     this.data3 = data3;
      
  //     console.log('Received data1:', this.data1);
  //     console.log('Received data2:', this.data2);
  //     console.log('Received data3:', this.data3);
  
  //     this.cd.detectChanges();
  //     this.mergedObject1.push(this.data1);
  //     this.mergedObject1.push(this.data2);
  //     this.mergedObject1.push(this.data3);
  //     //  this.mergeObjects( this.data1, this.data2, this.data3);
  //     console.log('Merged data:', this.mergedObject1);
     
  //     //this.isLoading = false;
  //   });
  //   return this.mergedObject1
  // }
  // getoperationefficiencycontent(){
  //   combineLatest([this.resourse1,this.resourse2]).subscribe(([opeffdata1,oppeffdata2])=>{
  //     this.opeffdata1 = opeffdata1;
  //     this.oppeffdata2 = oppeffdata2;
  //     this.cd.detectChanges();
  //     this.mergedObject2.push(this.opeffdata1);
  //     this.mergedObject2.push(this.oppeffdata2);
  //   });
  //   return this.mergedObject2
  // }
  ngOnInit() {
    // this.getContent()
   this.bannerNode.subscribe(res=>{
    console.log('Received datafinal:', res);
    const tilesList = res.pagesList.split(" ")
    tilesList.forEach((el:any) => {this.accessService.multiplewhatif(el).subscribe(res=>{
      this.tilesarray = [...this.tilesarray, res]
      this.cd.detectChanges();
      console.log(this.tilesarray);
     })})
   })

  // })

    
  //  this.getoperationefficiencycontent()
}
ngAfterViewInit(){
  // this.getContent()
  // this.getoperationefficiencycontent()
}
}
