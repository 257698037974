
<section class="col-xs-12 col-sm-12 financial_invoices">
    <div *ngIf="isLoading" class="loading">
        <div class="loading_txt">
        <div class="load_txt">Loading</div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
    <div *ngIf="!isLoading">
    <div class="col-xs-12 col-sm-12 financial_headerparent">
        <div class="col-xs-12 col-sm-12 no-padding financial_invoiceheader">Invoices</div>
    </div>
    <div class="col-xs-12 col-sm-12 financial_invoicebtns">
        <div class="col-xs-12 col-sm-12 financial_invoicebtn" id="financial_invoicebtn-1" (click)="openinvoice()" [ngClass]="openinvoiceflag?'active':''">
              <div class="col-xs-12 col-sm-12 financial_invoicebtnParent"> <!-- total invoice changes -->
                <div class="financial_invoicebtnheader" >Open Invoices</div>
                <ng-container  *ngIf="overdueflag==true">
                    <span class="financial_alert" > <img src="assets/images/exclamation-mark.png" class="financial_alertImg">
                    </span></ng-container>
                </div>
                <div class="col-sm-12 d-md-none d-lg-none financial_alert-msgOpen mobile_alert">{{financialresponse?.overdueInvoiceSeasonText}}</div>

        </div>

        <div class="col-xs-12 col-sm-12 financial_invoicebtn" id="financial_invoicebtn-2" (click)="paidinvoice()" [ngClass]="paidinvoiceflag?'active':''">
            <div class="col-xs-12 col-sm-12 financial_invoicebtnheader">Paid Invoices</div>
            <div class="col-sm-12 d-md-none d-lg-none financial_alert-msgOpen mobile_alert" [innerHtml]="financialresponse?.paidInvoicesText"></div>
        </div>
  
    </div>
    <div class="financial_dropdown  mob_season_dropdown d-md-none d-lg-none col-sm-12 no-padding" [ngClass]="toggleseason?'open':''"><!-- total invoice changes -->
        <div class="button" (click)="openseason()"> <!-- <span *ngIf="financialresponse?.currentSelectedSeason;else defaultseason"> -->
                              
           <span  style="background-image:url(assets/images/exclamation-mark.png)" *ngIf="viewingkey==true" class="seasonyear">{{viewingseason}}&nbsp;Invoices

           </span> 
           <span *ngIf="viewingkey==false" class="seasonyear">{{viewingseason}}&nbsp;Invoices

           </span> 
       
        </div>
                               <ul class="select-list" [ngClass]="toggleseason?'open':''">
                                   <li class="clsAnchor" *ngFor="let season of financialresponse?.overdueinvoiceseason" >

                                       <span  style="background-image:url(assets/images/exclamation-mark.png)" *ngIf="season.value==true" class="seasonyear" (click)="seasonchangeclick(season.key)">{{season.key}}&nbsp;Invoices

                                       </span> 
                                       <span *ngIf="season.value==false" class="seasonyear" (click)="seasonchangeclick(season.key)">{{season.key}}&nbsp;Invoices

                                       </span> 

                                   </li> 
                               </ul>
                           </div>
  <div class=" col-xs-12 col-sm-12 col-lg-12 col-md-12 opensection" [ngClass]="openinvoiceflag?'open':''">
    <div class=" col-sm-12 d-md-block d-lg-block d-none financial_alert-msg financial_alert-msgOpen">{{financialresponse?.overdueInvoiceSeasonText}}</div>
    <div class="col-xs-12 col-sm-12 financial_invoicetable" id="financial_invoicetable1">
        <div class="financial_invoicetableheader col-xs-12 col-sm-12 col-md-5 col-lg-5">Open Invoices</div><!-- total invoice changes -->
             
             <div class="financial_dropdown margin-B20 d-none d-md-block d-lg-block" [ngClass]="toggleseason?'open':''"><!-- total invoice changes -->
             <div class="button" (click)="openseason()"> <!-- <span *ngIf="financialresponse?.currentSelectedSeason;else defaultseason"> -->
                                   
                <span  style="background-image:url(assets/images/exclamation-mark.png)" *ngIf="viewingkey==true" class="seasonyear">{{viewingseason}}&nbsp;Invoices

                </span> 
                <span *ngIf="viewingkey==false" class="seasonyear">{{viewingseason}}&nbsp;Invoices

                </span> 
            
             </div>
                                    <ul class="select-list" [ngClass]="toggleseason?'open':''">
                                        <li class="clsAnchor" *ngFor="let season of financialresponse?.overdueinvoiceseason" >

                                            <span  style="background-image:url(assets/images/exclamation-mark.png)" *ngIf="season.value==true" class="seasonyear" (click)="seasonchangeclick(season.key)">{{season.key}}&nbsp;Invoices

                                            </span> 
                                            <span *ngIf="season.value==false" class="seasonyear" (click)="seasonchangeclick(season.key)">{{season.key}}&nbsp;Invoices

                                            </span> 

                                        </li> 
                                    </ul>
                                </div>
       
        <!-- total invoice changes -->
        <div class="financial_invoicetabledetail">
            <div class="financial_invoicetabledetail1">
            <div class="col-xs-12 col-sm-12 ck-button no-padding" [ngClass]="financialresponse.seqirusFinancialDashboardData?.statuses.overdue==0?'inactive_status':''">
                   <label>
                      <input type="checkbox" class="status" name="name" value="Overdue" (click)="filteropen($event,'overdue')">
                      <span class="financial_invoiceoverdueNo financial_invoiceNo call_status">{{financialresponse.seqirusFinancialDashboardData?.statuses.overdue}}</span>
                      <span class="financial_invoiceoverdueText">overdue status</span>
                      <span class="financial_alert" *ngIf="financialresponse?.seqirusFinancialDashboardData?.statuses.overdue>0"> <img src="assets/images/exclamation-mark.png" class="financial_alertImg">
                      </span>
                   </label>
                </div>
                <div class="col-xs-12 col-sm-12 ck-button no-padding" [ngClass]="financialresponse?.seqirusFinancialDashboardData?.statuses.dueSoon==0?'inactive_status':''">
                   <label>
                      <input type="checkbox" class="status" name="name" value="Due Soon" (click)="filteropen($event,'duespon')">
                      <span class="financial_invoiceduesoonNo financial_invoiceNo call_status">{{financialresponse?.seqirusFinancialDashboardData?.statuses.dueSoon}}</span>
                      <span class="financial_invoiceduesoonText">due soon status</span>
                   </label>
                </div>
                <div class="col-xs-12 col-sm-12 ck-button no-padding" [ngClass]="financialresponse?.seqirusFinancialDashboardData?.statuses.unpaid==0?'inactive_status':''">
                   <label>
                      <input type="checkbox" class="status" name="name" value="Open" (click)="filteropen($event,'open')">
                      <span class="financial_invoiceunpaidNo financial_invoiceNo call_status">{{financialresponse?.seqirusFinancialDashboardData?.statuses.unpaid}}</span>
                      <span class="financial_invoiceunpaidText">open status</span>
                   </label>
                </div>
            </div>
            <div class="financial_invoicetabledetail2 d-none d-md-block d-lg-block" *ngIf="opendata.data.length > 0;">
                <table mat-table [dataSource]="opendata" class="mat-elevation-z8 financial_table">
                    <ng-container matColumnDef="invoiceNumber">
                        <th mat-header-cell *matHeaderCellDef>Invoice #</th>
                        <td mat-cell *matCellDef="let row">{{row.invoiceNumber}}</td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let row">{{row.status}}</td>
                    </ng-container>
                    <ng-container matColumnDef="amountDue">
                        <th mat-header-cell *matHeaderCellDef>Remaining Balance</th>
                        <td mat-cell *matCellDef="let row">${{row.amountDue | number:'1.2-2'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Total Invoice Cost</th>
                        <td mat-cell *matCellDef="let row">${{row.amount | number:'1.2-2'}}</td>
                    </ng-container>
                    <ng-container matColumnDef="invoiceDate">
                        <th mat-header-cell *matHeaderCellDef>Invoice Date</th>
                        <td mat-cell *matCellDef="let row" >{{row.invoiceDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="dueDate">
                        <th mat-header-cell *matHeaderCellDef>Due Date</th>
                        <td mat-cell *matCellDef="let row">{{row.dueDate}}</td>
                    </ng-container>
                    <ng-container matColumnDef="selected">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'" [queryParams]="{invoice: row.invoiceNumber,season:viewingseason}"><span>View Invoice</span><div class="global_blackarrow"></div></a>
                            </td>
                    </ng-container>
                 
                     
                         <!-- Header and Row Declarations -->
                         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                         <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedColumns;"></tr>
                       
                       </table>
            </div>

            <!-- moblie table for invoice start's here -->
            <div class="financial_invoicetabledetail2  d-md-none d-lg-none financial_openinvoiceMobiletable-1">
                <table mat-table [dataSource]="opendata" class="mat-elevation-z8 financial_table">

                    <ng-container matColumnDef="combined" >
                        <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
                             <td mat-cell *matCellDef="let row"> 
                                <div class="financial_mobtab">
                                    <div class="financial_valueName">
                                        <span>Invoice #</span>
                                        <p>{{row.invoiceNumber}}</p>
                                    </div>
                                    <div class="financial_valueName">
                                        <span>Status</span>
                                        <p>{{row.status}}</p>
                                    </div>
                                    <div class="financial_valueName">
                                        <span>Remaining Balance</span>
                                        <p>${{row.amountDue | number:'1.2-2'}}</p>
                                    </div>
                                    <div class="financial_valueName">
                                        <span>Total Invoice Cost</span>
                                        <p>{{row.amount | number:'1.2-2'}}</p>
                                    </div>
                                    <div class="financial_valueName">
                                        <span>Invoice Date</span>
                                        <p>{{row.invoiceDate}}</p>
                                    </div>
                                    <div class="financial_valueName">
                                        <span>Due Date</span>
                                        <p>{{row.dueDate}}</p>
                                    </div>
                                    <div class="financial_invoicearrow anchorunderline">
                                        <span>View Invoice</span>
                                        <div class="global_blackarrow"></div>
                                       
                                    </div>
                                </div>
                               </td>
                                 
                        
                             </ng-container>
                             <tr mat-header-row *matHeaderRowDef="displayedmobColumns"></tr>
                             <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedmobColumns;"></tr>
                </table>

            </div>

            
        </div>
    </div>
    </div>

    <!--  paid section start here -->
    <div class=" col-xs-12 col-sm-12 col-lg-12 col-md-12 opensection" [ngClass]="paidinvoiceflag?'open':''">
        <div class=" col-sm-12 d-md-block d-lg-block d-none financial_alert-msg financial_alert-msgOpen" [innerHtml]="financialresponse?.paidInvoicesText"></div>
        <div class="col-xs-12 col-sm-12 financial_invoicetable" id="financial_invoicetable1">
            <div class="financial_invoicetableheader col-xs-12 col-sm-12 col-md-5 col-lg-5">Paid Invoices</div><!-- total invoice changes -->
                 
                 <div class="financial_dropdown margin-B20 d-none d-md-block d-lg-block" [ngClass]="toggleseason?'open':''"><!-- total invoice changes -->
                 <div class="button" (click)="openseason()"> <!-- <span *ngIf="financialresponse?.currentSelectedSeason;else defaultseason"> -->                   
                    <span  class="seasonyear">{{viewingseason}}&nbsp;Invoices
                    </span> 
                 </div>
                                        <ul class="select-list" [ngClass]="toggleseason?'open':''">
                                            <li class="clsAnchor" *ngFor="let season of financialresponse?.overdueinvoiceseason" >
                                                <span  class="seasonyear" (click)="seasonchangeclick(season.key)">{{season.key}}&nbsp;Invoices
                                                </span> 
    
                                            </li> 
                                        </ul>
                                    </div>
           
            <!-- total invoice changes -->
            <div class="financial_invoicetabledetail">
                <div class="financial_invoicetabledetail1">
               
                    
                    <div class="col-xs-12 col-sm-12 ck-button no-padding" [ngClass]="financialresponse?.seqirusFinancialDashboardData?.statuses.paid==0?'inactive_status':''">
                       <label>
                          <input type="checkbox" class="status" name="name" value="Paid">
                          <span class="financial_invoiceunpaidNo financial_invoiceNo call_status">{{financialresponse?.seqirusFinancialDashboardData?.statuses.paid}}</span>
                          <span class="financial_invoiceunpaidText">paid status</span>
                       </label>
                    </div>
                </div>
                <div class="financial_invoicetabledetail2 d-none d-md-block d-lg-block"  *ngIf="paiddata.data.length > 0;">
                    <table mat-table [dataSource]="paiddata" class="mat-elevation-z8 financial_table paidtable" >
                        <ng-container matColumnDef="invoiceNumber">
                            <th mat-header-cell *matHeaderCellDef>Invoice #</th>
                            <td mat-cell *matCellDef="let row">{{row.invoiceNumber}}</td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>Status</th>
                            <td mat-cell *matCellDef="let row">{{row.status}}</td>
                        </ng-container>
                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Total Invoice Cost</th>
                            <td mat-cell *matCellDef="let row">${{row.amount | number:'1.2-2'}}</td>
                        </ng-container>
                        <ng-container matColumnDef="clearingDate">
                            <th mat-header-cell *matHeaderCellDef>Date Paid</th>
                            <td mat-cell *matCellDef="let row">{{row.clearingDate}}</td>
                        </ng-container>
                       
                        <ng-container matColumnDef="selected">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let row">
                                <span class="anchorunderline">View Invoice</span><div class="global_blackarrow"></div>
                                </td>
                        </ng-container>
                     
                         
                             <!-- Header and Row Declarations -->
                             <tr mat-header-row *matHeaderRowDef="paidcolumn"></tr>
                             <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: paidcolumn;"></tr>
                           
                           </table>
                </div>
    
                <!-- moblie table for invoice start's here -->
                <div class="financial_invoicetabledetail2  d-md-none d-lg-none financial_openinvoiceMobiletable-1">
                    <table mat-table [dataSource]="paiddata" class="mat-elevation-z8 financial_table">
    
                        <ng-container matColumnDef="combined" >
                            <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
                                 <td mat-cell *matCellDef="let row"> 
                                    <div class="financial_mobtab">
                                        <div class="financial_valueName">
                                            <span>Invoice #</span>
                                            <p>{{row.invoiceNumber}}</p>
                                        </div>
                                        <div class="financial_valueName">
                                            <span>Status</span>
                                            <p>{{row.status}}</p>
                                        </div>
                                        <div class="financial_valueName">
                                            <span>Total Invoice Cost
                                            </span>
                                            <p>${{row.amount | number:'1.2-2'}}</p>
                                        </div>
                        
                                        <div class="financial_valueName">
                                            <span>Date Paid</span>
                                            <p>{{row.clearingDate}}</p>
                                        </div>
                                       
                                        <div class="financial_invoicearrow">
                                            <span class="anchorunderline">View Invoice</span>
                                            <div class="global_blackarrow"></div>
                                           
                                        </div>
                                    </div>
                                   </td>
                                     
                            
                                 </ng-container>
                                 <tr mat-header-row *matHeaderRowDef="displayedmobColumns"></tr>
                                 <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedmobColumns;"></tr>
                    </table>
    
                </div>
    
                
            </div>
        </div>
        </div>

   <!--  paid section end's here -->
    <!-- all invoice link section -->
    <div class="col-xs-12 col-sm-12 mobpadding">
    <div class="col-xs-12 col-sm-12 financial_allinvoice">
        <div class="financial_allinvoiceClick" id="financial_allinvoiceClick1">
            <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'" > <span>View All Invoices</span>
                <div class="global_blackarrow"></div>
                
            </a>
        </div>
     
    </div>

    <div class="col-xs-12 col-sm-12 financial_headerparent no-padding margin-T20">
        <div class="col-xs-12 col-sm-12 no-padding financial_invoiceheader margin-T20">Credits Information</div>
    </div>

    <div class="col-xs-12 col-sm-12 financial_invoicebtns financial_creditbtns mobile_credits">
        <div class=" financial_creditbtn active" id="financial_creditbtn-1"  (click)="opencredit(1)">
            <div class="financial_invoicebtnheader">Available Credits</div>
        </div>
         <!--pending credit button added-->
        <div class=" financial_creditbtn" id="financial_creditbtn-2" (click)="opencredit(2)">
            <div class="financial_invoicebtnheader">Credits Pending Application</div>
        </div>
        <div class=" financial_creditbtn lastcreditbtn " id="financial_creditbtn-3" (click)="opencredit(3)">
            <div class="financial_invoicebtnheader">Credits Applied</div>
        </div>
    </div>
<!-- avaiable credit -->
    <div class="col-xs-12 col-sm-12 financial_credittable open" id="financial_credittable1">
        <div class="financial_totalbox float_left ">
        <div class="financial_creditheader floatleft">
            <div class="financial_creditheaderText">
                Total available credits: <span class="financial_creditavail">${{financialresponse?.seqirusFinancialDashboardData?.credit.totalAvailableCredit | number:'1.2-2'}}</span>
            </div>
            
            <div class="financial_creditheaderTooltip" id="financial_creditheaderTooltip-1">
                <span (click)="tooltipopen($event)">How do I use my credits?</span>
                <div class="financial_triangle hide"></div>
                <div class="messagetooltip hide">
                     <p>To apply available credit(s) to an open invoice and pay the balance by credit card, go to <a class="financial_invoice_link"  [routerLink]="'/financial-dashboard/all-invoices'">All Invoices</a> and select the invoice you would like to pay.</p><p>To apply available credit(s) to an open invoice and pay the balance by ACH or check, or to receive a check for your credit amount, email our finance team at <a class="emaillink" href="mailto:usainc.accountsreceivable@seqirus.com" >usainc.accountsreceivable&#64;seqirus.com</a>.</p><p>Please note your billing account must be in good standing prior to credit application or check dispersal.</p><p>It may take several business days for a credit to be removed from the Available Credits tab if the credit was applied to an invoice outside of flu360.com.</p>
                 </div>
            </div>
            
            </div>
            <div class="financial_viewandManage d-none  d-md-block d-lg-block"> <!-- mobile changes for apply credit-->
        <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'"> <span>View &amp; Manage Invoices</span>
            <div class="global_blackarrow"></div>
            
        </a>
    </div>
    

        </div>
    
        <div class="financial_locationsearch financial_locationsearch-1" style="display: none;">
            <div class="input-group seach_order"> <div class="input-group-addon"><i class="fa fa-search"></i></div>
             <input type="search" placeholder="Search, filter, or sort your orders" id="financial_locationsearch-1" class="financial_Searchinput" (keyup)="applyFilter($event,'available')"></div>
        </div>
       
        <div class="financial_creditabledetail1 credittablebox float_left">
            <div class="financial_locationsearch financial_locationsearch-2" *ngIf="creditavaible.data.length > 0;">
                <div class="input-group seach_order">
                <div class="input-group-addon"><i class="fa fa-search"></i></div>
                 <input type="search" placeholder="Search, filter, or sort your orders" class="financial_Searchinput" (keyup)="applyFilter($event,'available')"></div>
            </div>
            <div class="financial_viewandManage  d-md-none d-lg-none d-sm-block col-sm-12 no-padding"> <!-- mobile changes for apply credit-->
                <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'"> <span>View &amp; Manage Invoices</span>
                    <div class="global_blackarrow"></div>
                    
                </a>
                                    </div>
                                    <div *ngIf="creditavaible.data.length > 0;">
            <table  class="credittable d-none d-md-block d-lg-block" mat-table [dataSource]="creditavaible" matSort (matSortChange)="announceSortChange($event)">

                <ng-container matColumnDef="billTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address">
                        Billing Address
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.billTo.billingAddress}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date">
                        Date Issued
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Credit">
                        Credit Note #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="formattedAmountWithTax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Amount">Credit Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.formattedAmountWithTax}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="col-sm-12 no-padding"><div class="viewreportchild"><a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a></div></div>
                         </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
            </table>
        </div>

            
           
        </div>
        <table  class="mobcredittable  d-md-none d-lg-none" mat-table [dataSource]="creditavaible">
            <ng-container matColumnDef="combined" >
                <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
                     <td mat-cell *matCellDef="let row"> 
                        <div class="financial_mobtab">
                           
                                <div class="billing">Billing Address</div>
                              <div class="billingvalue">{{row.billTo.billingAddress}}</div>
                           
                            <div class="financial_valueName">
                                <span> Date Issued</span>
                                <p>{{row.invoiceDate}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Note #</span>
                                <p>${{row.invoiceNumber}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Amount</span>
                                <p>{{row.formattedAmountWithTax}}</p>
                            </div>
                            
                            <div class="financial_invoicearrow">
                                <a class="anchorlink" (click)="financialgetDownloadInvoiceData(row.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a>
                            </div>
                        </div>
                       </td>
                         
                
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="displayedmobColumns"></tr>
                     <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedmobColumns;"></tr>

        </table>
        
    </div>
    <!-- pending credit -->
    <div class="col-xs-12 col-sm-12 financial_credittable " id="financial_credittable2">
        <div class="financial_totalbox float_left ">
        <div class="financial_creditheader floatleft">
            <div class="financial_creditheaderText">
               Total credits pending application: <span class="financial_creditavail">${{financialresponse?.seqirusFinancialDashboardData?.credit.totalPendingCredit | number:'1.2-2'}}</span>
            </div>
            
            <div class="financial_creditheaderTooltip" id="financial_creditheaderTooltip-1">
                <span (click)="tooltipopen($event)">What’s next for my credits?</span>
                <div class="financial_triangle hide"></div>
                <div class="messagetooltip hide">
                    <p>Transactions can take several business days to be approved. Once your transaction is approved, your credit(s) will be applied to your invoice.</p>
                </div>
            </div>
            
            </div>
            <div class="financial_viewandManage d-none  d-md-block d-lg-block"> <!-- mobile changes for apply credit-->
        <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'" target="_self" > <span>View &amp; Manage Invoices</span>
            <div class="global_blackarrow"></div>
            
        </a>
    </div>
    

        </div>
    
        <div class="financial_locationsearch financial_locationsearch-1" style="display: none;">
            <div class="input-group seach_order"> <div class="input-group-addon"><i class="fa fa-search"></i></div>
             <input type="search" placeholder="Search, filter, or sort your orders" id="financial_locationsearch-1" class="financial_Searchinput" (keyup)="applyFilter($event,'pending')"></div>
        </div>
       
        <div class="financial_creditabledetail1 credittablebox float_left">
            
            <div class="financial_locationsearch financial_locationsearch-2" *ngIf="pending.data.length > 0;">
                <div class="input-group seach_order">
                <div class="input-group-addon"><i class="fa fa-search"></i></div>
                 <input type="search" placeholder="Search, filter, or sort your orders" class="financial_Searchinput" (keyup)="applyFilter($event,'pending')"></div>
            </div>
            <div class="financial_viewandManage  d-md-none d-lg-none d-sm-block col-sm-12 no-padding"> <!-- mobile changes for apply credit-->
                <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'"> <span>View &amp; Manage Invoices</span>
                    <div class="global_blackarrow"></div>
                    
                </a>
                                    </div>
                                    <div *ngIf="pending.data.length > 0;">
            <table  class="credittable d-none d-md-block d-lg-block" mat-table [dataSource]="pending" matSort (matSortChange)="announceSortChange($event)">

                <ng-container matColumnDef="billTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address">
                        Billing Address
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.billTo.billingAddress}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date">
                        Date Issued
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Credit">
                        Credit Note #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="formattedAmountWithTax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Amount">Credit Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.formattedAmountWithTax}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="col-sm-12 no-padding"><div class="viewreportchild"><a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a></div></div>
                         </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
            </table>
            </div>

            
           
        </div>
        <table  class="mobcredittable  d-md-none d-lg-none" mat-table [dataSource]="pending">
            <ng-container matColumnDef="combined" >
                <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
                     <td mat-cell *matCellDef="let row"> 
                        <div class="financial_mobtab">
                           
                                <div class="billing">Billing Address</div>
                              <div class="billingvalue">{{row.billTo.billingAddress}}</div>
                           
                            <div class="financial_valueName">
                                <span> Date Issued</span>
                                <p>{{row.invoiceDate}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Note #</span>
                                <p>${{row.invoiceNumber}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Amount</span>
                                <p>{{row.formattedAmountWithTax}}</p>
                            </div>
                            
                            <div class="financial_invoicearrow">
                                <a class="anchorlink" (click)="financialgetDownloadInvoiceData(row.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a>
                            </div>
                        </div>
                       </td>
                         
                
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="displayedmobColumns"></tr>
                     <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedmobColumns;"></tr>

        </table>
        
    </div>
    <!-- applied credit -->
    <div class="col-xs-12 col-sm-12 financial_credittable " id="financial_credittable3">
        <div class="financial_totalbox float_left ">
        <div class="financial_creditheader floatleft">
            <div class="financial_creditheaderText">
                Total credits used: <span class="financial_creditavail">${{financialresponse?.seqirusFinancialDashboardData?.credit.totalUsedCredit | number:'1.2-2'}}</span>
            </div>
            
            <div class="financial_creditheaderTooltip" id="financial_creditheaderTooltip-1">
                <span (click)="tooltipopen($event)">Where did my credits go?</span>
                <div class="financial_triangle hide"></div>
            
                <div class="messagetooltip hide">
                    Credits listed were applied to open invoice(s) as requested during online payment by credit card or payment by phone. For more information on which invoice(s) your credit was applied, contact <a class="emaillink" href="mailto:customerservice.us@seqirus.com">customerservice.us&#64;seqirus.com</a>.
                </div>
            </div>
            
            </div>
            <div class="financial_viewandManage d-none  d-md-block d-lg-block"> <!-- mobile changes for apply credit-->
        <a class="anchorlink" [routerLink]="'/financial-dashboard/all-invoices'" target="_self" > <span>View &amp; Manage Invoices</span>
            <div class="global_blackarrow"></div>
            
        </a>
    </div>
    

        </div>
    
        <div class="financial_locationsearch financial_locationsearch-1" style="display: none;">
            <div class="input-group seach_order"> <div class="input-group-addon"><i class="fa fa-search"></i></div>
             <input type="search" placeholder="Search, filter, or sort your orders" id="financial_locationsearch-1" class="financial_Searchinput" (keyup)="applyFilter($event,'applied')"></div>
        </div>
       
        <div class="financial_creditabledetail1 credittablebox float_left">
            <div class="financial_locationsearch financial_locationsearch-2" *ngIf="applied.data.length > 0;">
                <div class="input-group seach_order">
                <div class="input-group-addon"><i class="fa fa-search"></i></div>
                 <input type="search" placeholder="Search, filter, or sort your orders" class="financial_Searchinput" (keyup)="applyFilter($event,'applied')"></div>
            </div>
            <div class="financial_viewandManage  d-md-none d-lg-none d-sm-block col-sm-12 no-padding"> <!-- mobile changes for apply credit-->
                <a class="anchorunderline" [routerLink]="'/financial-dashboard/all-invoices'"> <span>View &amp; Manage Invoices</span>
                    <div class="global_blackarrow"></div>
                    
                </a>
                                    </div>
                                    <div *ngIf="applied.data.length > 0;">
            <table  class="credittable d-none d-md-block d-lg-block" mat-table [dataSource]="applied" matSort (matSortChange)="announceSortChange($event)">

                <ng-container matColumnDef="billTo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Address">
                        Billing Address
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.billTo.billingAddress}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date">
                        Date Issued
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceDate}} </td>
                </ng-container>

                <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Credit">
                        Credit Note #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.invoiceNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="formattedAmountWithTax">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Amount">Credit Amount
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.formattedAmountWithTax}} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="col-sm-12 no-padding"><div class="viewreportchild"><a class="anchorlink" (click)="financialgetDownloadInvoiceData(element.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a></div></div>
                         </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="creditcolumns;"></tr>
                <tr mat-row *matRowDef="let row; columns: creditcolumns;"></tr>
            </table>
            </div>

            
           
        </div>
        <table  class="mobcredittable  d-md-none d-lg-none" mat-table [dataSource]="applied">
            <ng-container matColumnDef="combined" >
                <th class="odertableheader d-none" mat-header-cell *matHeaderCellDef> order Data </th>
                     <td mat-cell *matCellDef="let row"> 
                        <div class="financial_mobtab">
                           
                                <div class="billing">Billing Address</div>
                              <div class="billingvalue">{{row.billTo.billingAddress}}</div>
                           
                            <div class="financial_valueName">
                                <span> Date Issued</span>
                                <p>{{row.invoiceDate}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Note #</span>
                                <p>${{row.invoiceNumber}}</p>
                            </div>
                            <div class="financial_valueName">
                                <span>Credit Amount</span>
                                <p>{{row.formattedAmountWithTax}}</p>
                            </div>
                            
                            <div class="financial_invoicearrow">
                                <a class="anchorlink" (click)="financialgetDownloadInvoiceData(row.invoiceNumber)"><span class="anchorunderline">View Report</span><div class="global_blackarrow"></div></a>
                            </div>
                        </div>
                       </td>
                         
                
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="displayedmobColumns"></tr>
                     <tr mat-row id="{{row.invoiceNumber}}" *matRowDef="let row; columns: displayedmobColumns;"></tr>

        </table>
        
    </div>
</div>
</div>
</section>

