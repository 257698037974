import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-influenza-65-plus',
  templateUrl: './influenza-65-plus.component.html',
  styleUrl: './influenza-65-plus.component.scss'
})
export class Influenza65PlusComponent {
  items : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusFirstComponent');

  constructor(private cmsService:CmsService, protected config: OccConfig,) {
  }
}

  // getImage(image: any): string {
  //   return this.config.backend?.occ?.baseUrl + image.url;
  // }
 


