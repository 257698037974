<ng-container *ngIf="topContent | async as topData">
<div class="col-xs-12 no-padding contact_dashboard">
    <section class="col-xs-12 col-sm-12 contact_titlesection">
        <div class="col-xs-12 col-sm-12 col-md-12 contact_headerparent">
            <h1 class="contact_header new_header_contactus">{{topData.title}}</h1>
            <div class="contact_subheaderparent">
                <div class="contact_subheadertext col-xs-12 col-md-6">{{topData.content}}</div>
                <div class="contact_subheaderbutton col-xs-12 col-md-6">
                    <a [routerLink]="topData.urlLink"><div class="contact_explorebutton">{{topData.subcontent}}</div></a>
                    <div class="clearboth"></div>
                </div>
            </div>
        </div>
    </section>
</div>
</ng-container>
