import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CmsService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { HttpParams } from '@angular/common/http';
import { CmsComponentData } from '@spartacus/storefront';



@Component({
  selector: 'app-join-account',
  templateUrl: './join-account.component.html',
  styleUrl: './join-account.component.scss'
})
export class JoinAccountComponent {

  jointaccountFormUS:FormGroup;
  accountName:Observable<string> | undefined;
  requestStatus: Observable<any> | undefined;
  showReqAssistanceSuccess: boolean = false;
  isChecked = false;
  joinAccSubmitStatus: string="";
  submitted: boolean = false;

  constructor(protected service:ContentService,private cdr:ChangeDetectorRef,private fb:FormBuilder,public joinAccComponent: CmsComponentData<any>,private router:RoutingService) {
    this.jointaccountFormUS = this.fb.group({
      account:["",[Validators.required,Validators.pattern('^[0-9]*$'),Validators.minLength(8)]],
      accessCodeFlag:["",[Validators.required,Validators.pattern('^\\d{5}(-\\d{4})?$')]]
    });
  }

  joinAccData: Observable<any> = this.joinAccComponent.data$;

  onCheckboxChange(event:Event){
    const check = event.target as HTMLInputElement;
    this.isChecked = check.checked;
  }
  
  hasError(controlName:string,errorName:string){
    return this.jointaccountFormUS.controls[controlName].hasError(errorName);
  }

  submitForm(){
    this.submitted =true;
    if(this.jointaccountFormUS.valid){
      this.accountName = this.service.getSearchAccount(getJoinAccFormData(this.jointaccountFormUS.value));
      this.cdr.detectChanges();
    }
  }

  submitJoinAccount(orgName:string){
    this.service.submitJoinAccount(getSubmitJoinAccFormData(this.jointaccountFormUS.value,orgName)).subscribe(response => {
     // console.log(response);
      this.joinAccSubmitStatus = response.result;
      //console.log(this.joinAccSubmitStatus);
      if(this.joinAccSubmitStatus === "join-account-success"){
        this.router.go('/my-account');
      }
    });
     

  }
  
  raiseAssistanceReq():any{
    
    this.requestStatus = this.service.getRequestAssistance(getFormDataForReqAss(this.jointaccountFormUS.value));
     
    this.requestStatus.subscribe(data =>{
      if(data.status === 200){
        this.showReqAssistanceSuccess = true;
        this.cdr.detectChanges();
      }
    })
  }


  ngOnInit(){
    this.joinAccData.subscribe(data=>{
     //console.log(data);
    })
  
  }

}
function getSubmitJoinAccFormData(value: any,orgName:string): any {
  return {
    accountNumber: value.account,
    zipCode: value.accessCodeFlag,
    orgName: orgName
    
  };

}

function getJoinAccFormData(value: any): any {
  return {
    account:value.account,
    accessCodeFlag:value.accessCodeFlag,
  };
}
function getFormDataForReqAss(value: any): any {
  return {
    account:value.account,
    zipCode:value.accessCodeFlag,
  };
}

