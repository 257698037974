<div class="col-md-12 col-sm-12 col-xs-12">
    <div class="myprofile_content_location myprofile_location_us ">
        <div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationheader">My Location</div>
        <div class="col-md-6 col-sm-12 col-xs-12 myprofile_locationmangeheader">
            <a class="anchorunderline" [routerLink]="['/organization-location']">Manage Locations<div class="global_blackarrow"></div></a>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 myprofile_selectoptions">
            <div class="myprofile_locationsearch"> <i class="myprofile_global_searchicon"></i>
                <input type="search" #search placeholder="Search for location">
                <!-- id="myprofile_location_searchbox" -->
                <button (click)="applyFilter($event,search.value)">Search</button>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 margin-T20">
            <div class="row myprofilelocation myprofile_us example-container">
                    <table  class="demo-table" mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                            Type
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                            Name
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                            Address
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.address}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                  
                </div>
        </div>
    </div>
</div>