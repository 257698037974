import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpWidgetComponent } from './help-widget/help-widget.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { BannerModule, OutletPosition, provideOutlet } from '@spartacus/storefront';
import { HomepageSolutionComponent } from './homepage-solution/homepage-solution/homepage-solution.component';
import { AccessResourcesComponent } from './access-resources/access-resources.component';
import { MiddleGreybannerComponent } from './middle-greybanner/middle-greybanner.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { CustomfilterPipe } from './Pipes/customfilter.pipe';
import { DateformatPipe } from './Pipes/dateformat.pipe';
import { HomepageReferenceComponent } from './homepage-reference/homepage-reference.component';
import { HomepageUKProductsComponent } from './homepage-uk-products/homepage-uk-products.component';
import { HomepageUKPISlotComponent } from './homepage-uk-pislot/homepage-uk-pislot.component';
import { JobbagcodeUkComponent } from './jobbagcode-uk/jobbagcode-uk.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HelpWidgetComponent,
    HomepageSolutionComponent,
    AccessResourcesComponent,
    AlertBannerComponent,
    CustomfilterPipe,
    DateformatPipe,
    HomepageReferenceComponent,
    HomepageUKProductsComponent,
    HomepageUKPISlotComponent,
    JobbagcodeUkComponent
  ],
  imports: [
    CommonModule,
    BannerModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RotatingImagesComponent:{
          component:HomepageSolutionComponent
         },
         SeqirusHomePageParagraphComponent:{
          component:MiddleGreybannerComponent
         },
         SeqirusAccessFeaturedResources:{
          component: AccessResourcesComponent
         },
         SeqirusAlertBoxComponent:{
          component: AlertBannerComponent
         },
         SeqirusReferenceComponent:{
          component: HomepageReferenceComponent
         },
         SeqirusHomePageProductBannerComponent:{
          component: HomepageUKProductsComponent
         },
         SeqirusCMSLinkComponent:{
          component: HomepageUKPISlotComponent
         },
         SeqirusJobBagCodeComponent:{
          component: JobbagcodeUkComponent
         }
      }
  
      } as CmsConfig)
  ],
  exports: [
    HelpWidgetComponent,
    DateformatPipe,
    CustomfilterPipe

  ],
  providers:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CmsCommonModule { }
