import { Component } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrl: './alert-banner.component.scss'
})
export class AlertBannerComponent {
  isUserLoggedIn: boolean=false;
 
  constructor(public PagealertComponent : CmsComponentData<CmsParagraphComponent>,public usersrvice:UserInfoService){
  }
 // logincheck:Observable<any>= this.usersrvice.userLoggedIn$;
  loginalertcontent: Observable<any> = this.PagealertComponent.data$; 
 
  ngOnInit(){
    this.usersrvice.userLoggedIn$.subscribe(res=>{
      this.isUserLoggedIn=res;
    });
  }
 
  

  

}
