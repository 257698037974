import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { SafeResourceUrl } from '@angular/platform-browser';

import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';


@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrl: './payment-information.component.scss',
})
export class PaymentInformationComponent implements OnInit {
  cardsData: any[] = [];
creditdetails: any ={cardsData :[]}
  cardservice: any;
  apiResponse: any;
  Key:string='';
token:any;
cardType:any;
selectedtoken:string ='';
confirmDeletemodal :boolean=false;
event:any;
  occEndPointsService: any;
  http: any;
  sanitizer: any;
  iframeUrl: any;

  constructor(
     
     private contentservice: ContentService,  
     private userinfoservice: UserInfoService, 
     private cdr: ChangeDetectorRef,
     
  ){}
  ngOnInit(): void {    
      this.contentservice.savedCreditDetail().subscribe((data: any) => {
         this.creditdetails = data;        
        console.log(this.creditdetails.cardsData);
        this.cdr.detectChanges();     
      }); 
      }
    
    getExpiryClass(expiryDate: string): string {
      const currentDate = new Date();
      const [month, year] = expiryDate.split('/').map(Number);
      const expiry = new Date(year, month - 1);
      
      if (expiry < currentDate) {
        return 'expired';
      } else if (expiry.getFullYear() === currentDate.getFullYear() &&
                 expiry.getMonth() === currentDate.getMonth() +1 ) {
        return 'expiring-soon';
      } else {
        return 'future-expiry';
      }
    }
    getExpiryMessage(expiryDate: string): string {
      const currentDate = new Date();
      const [month, year] = expiryDate.split('/').map(Number);
      const expiry = new Date(year, month - 1);
    
      if (expiry < currentDate) {
        return `Expired on ${expiryDate}`;
      } else if (expiry.getFullYear() === currentDate.getFullYear() &&
                 expiry.getMonth() === currentDate.getMonth() + 1) {
        return `Expiring soon ${expiryDate}`;
      } else {
        return `Expires on ${expiryDate}`;
      }
    }
    handleOnDelete(event: Event) {
      this.selectedtoken= (event.target as HTMLInputElement).value;
             console.log('Delete button clicked for token:', this.selectedtoken);
             this.confirmDeletemodal=true;
             console.log(this.confirmDeletemodal);
             this.cdr.detectChanges();  
            //  this.userinfoservice.deleteCreditDetails(this.selectedtoken)
            //  .subscribe(
            //    (response:any) => {
            //     console.log('Deleted token:', this.selectedtoken);
            //      // console.log('Delete response:', Response);
                //  this.creditdetails.cardsData = this.creditdetails.cardsData.filter((cards: any) => cards.token !== this.selectedtoken);
            //     this.cdr.detectChanges();
            //    },  
            //    (error: any) => {
            //      console.error('Error deleting card:', error);
            //      alert('There was an error deleting the card. Please try again.');
            //    }
            //    );
            
             }
      
  confirmDelete(): void {
    if (this.selectedtoken) {
      this.userinfoservice.deleteCreditDetails(this.selectedtoken).subscribe(
        (response: any) => {
          this.creditdetails.cardsData = this.creditdetails.cardsData.filter((cards: any) => cards.token !== this.selectedtoken);
          console.log('Deleted token:', this.selectedtoken);
          this.creditdetails.cardsData = this.creditdetails.cardsData.filter(
            (card: any) => card.token !== this.selectedtoken
          );
          this.cdr.detectChanges();
          // this.confirmDeletemodal=!this.confirmDeletemodal
        },
        (error: any) => {
          console.error('Error deleting card:', error);
          alert('There was an error deleting the card. Please try again.');
        }
      );
    }
  }
       
      
      }



  //       confirmDelete(): void {
  //         if (this.selectedtoken) {
  //           this.userinfoservice.deleteCreditDetails(this.selectedtoken).subscribe(
  //             (response: any) => {
  //               console.log("Deleted token:", this.selectedtoken);
  //               if (this.creditdetails && this.creditdetails.cardsData) {
  //                 this.creditdetails.cardsData = this.creditdetails.cardsData.filter(
  //                   (card: any) => card.token !== this.selectedtoken
  //                 );
  //               }
  //               this.closeDialog(); // Close the dialog after deletion
  //             },
  //             (error: any) => {
  //               console.error("Error deleting card:", error);
  //               alert("There was an error deleting the card. Please try again.");
  //               this.closeDialog(); // Close the dialog if there's an error
  //             }
  //           );
  //         }
  //       }
      
  //       // Method to open the delete dialog
  //       openDeleteDialog(token: string): void {
  //         this.selectedtoken = token;
  //         const dialog = document.getElementById("deleteConfirmationDialog");
  //         if (dialog && dialog.style) {
  //           dialog.style.display = "block";
  //         }
  //       }
      
  //       // Method to cancel the delete action
  //       cancelDelete(): void {
  //         this.selectedtoken = undefined;
  //         this.closeDialog();
  //       }
  // closeDialog() {
  //   throw new Error('Method not implemented.');
  // }
  //     }



          
        
          // Method to handle deletion on confirmation
         
  //  this.userinfoservice.deleteCreditDetails(this.token).subscribe(
    //   (response: any) =>{
    //     console.log('card is deleted', response);
    //     this.handleOnDelete(this.token);
    //   },
    //   (error) =>{
    //     console.error('error deleting card',error);
    //   }
    //  );
