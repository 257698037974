<div *ngIf="seasonData | async as item ">
    <div class="row orders_rowsection">
    <div class="col-xs-12 orders_newordertitle">New Order</div>
    <div class="col-xs-12 orders_neworderbox2">
                <a class="anchorunderline" routerLink="/start-order">
                    <div class="orders_boxtext1">Order for</div>
                    <div class="orders_boxtext2" >{{item.inSeason}} Season<i class="orders_global_blackarrow pull-right"></i>
                        </div>
                    </a>
                   
    </div>
 
   
    <div class="row orders_rowsection borderClass " *ngIf="orderSummary | async as item1 ">
        <div class="row">
            <div class="col-xs-12 col-md-12 orders_producttitle">Product Availability</div>
            <div class="col-xs-12 col-md-12 orders_productyear">{{item.inSeason}}</div>
        </div>

        <div class="col-xs-12 orders_prodbox" >            
            <div class="col-xs-12 orders_prodtext1">FLUCELVAX®</div>
            <div *ngFor="let details of getProductsArray1(item1)">
            <div  class="col-xs-7 orders_prodtext2">{{details.presentationName}}</div>
        
            <span *ngIf="details.sellable" class="col-xs-5 orders_avilabletxt pull-right">AVAILABLE</span>   
                
                 <span *ngIf="!details.sellable" class=" col-xs-5 orders_soldouttxt pull-right">SOLD OUT</span>                           
            
                </div>   
                <div class="col-xs-12 orders_prodtext1">FLUAD®</div>
                <div *ngFor="let details of getProductsArray2(item1)">
                    <div  class="col-xs-7 orders_prodtext2">{{details.presentationName}}</div>
                    <span *ngIf="details.sellable" class="col-xs-5 orders_avilabletxt pull-right">AVAILABLE</span>   
                    <span *ngIf="!details.sellable" class=" col-xs-5 orders_soldouttxt pull-right">SOLD OUT</span>                           
                </div> 
                <div class="col-xs-12 orders_prodtext1">AFLURIA®</div>
                <div *ngFor="let details of getProductsArray3(item1)">
                    <div  class="col-xs-7 orders_prodtext2">{{details.presentationName}}</div>
                    <span *ngIf="details.sellable" class="col-xs-5 orders_avilabletxt pull-right">AVAILABLE</span>   
                    <span *ngIf="!details.sellable" class=" col-xs-5 orders_soldouttxt pull-right">SOLD OUT</span>                           
                </div> 
            </div>  
    

</div>
    <!-- <div class="product-availability">
        <h3>Product Availability</h3>
        <p class="item">{{item.inSeason}}</p>
        <ul>
        <li>
        <div class="orders">FLUAD®</div>
        <span class="orders_prodtext2">0.5-mL pre-filled syringe</span>
        <span class="availability pull-right">AVAILABLE</span>
        </li>
        <li>
            <div class="orders">FLUCELVAX®</div><br />
            <span class="orders_prodtext2">0.5-mL pre-filled syringe</span><span class="availability pull-right">AVAILABLE</span><br/>
        <span class="orders_prodtext2">5-mL multi-dose vial</span> <span class="orders_soldout pull-right">SOLD OUT</span>
    </li>
    <li>
        <div class="orders">AFLURIA®</div><br />
    <span class="orders_prodtext2">0.5-mL pre-filled syringe</span> <span class="availability pull-right">AVAILABLE</span>
    <br/>
    <span class="orders_prodtext2">5-mL multi-dose vial</span> <span class="availability pull-right">AVAILABLE</span>
</li>
   
    </ul>
    </div> -->
</div>
</div>




