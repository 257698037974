
<div class="col-xs-12 col-sm-12 financial_supportTiles" *ngIf="helpContent | async as helpmessage">
				
    <div class="financial_righttoreturn">
        <div class="financial_supportheader">{{helpmessage.title}}</div>
        <div class="financial_supportfhelp">{{helpmessage.paragraphcontent}}</div>
        <div class="financial_help">
            <a class="anchorunderline" [routerLink]="'/help-and-faq'"> 

                    <span>View all Help &amp; FAQs </span>
                    

                
                <div class="global_blackarrow"></div>
                <div class="clearboth"></div>
            </a>
        </div>
    </div>


    
       <div class="rightcontainer" [innerHtml]="helpmessage.content"></div>
</div>