<div class="account-section-content createprofile_cdccontent col-xs-12 col-md-12 margin-T10" *ngIf="userGuide | async  as item">
    <div class="col-xs-12 col-md-12 createprofile_helptext">
        <!-- <div *ngIf="!userLoggedIn" id="breadcrumb" class="col-xs-12 col-md-6">
            <ol class="breadcrumb">
                <li><a href="/">Home</a></li>
               &nbsp; &gt; &nbsp;
                <li class="bc_bold">Create Account</li>
            </ol>
            <a [routerLink]="['/profileverification']">verify profile</a>
        </div> -->
        <div class="userguideparent">
            <span class="userguidegrey">{{item.headline}} </span>
            <a class="userguideanchor" [href]="getImage(item.media)" target="_blank"><span
                    class="userguideblack anchorunderline">{{item.h2content}}</span>
                <div class="global_blackarrow"></div>
            </a>
        </div>
    </div>
</div>

  