import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyOrdersDashboardComponent } from './my-orders-dashboard/my-orders-dashboard.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { FormsModule } from '@angular/forms';
import { MyOrdersLeftSectionComponent } from './my-orders-left-section/my-orders-left-section.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar'
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    MyOrdersDashboardComponent,
    MyOrdersLeftSectionComponent, 
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    I18nModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusMyOrdersBannerComponent : {
          component: MyOrdersDashboardComponent ,
        },
        SeqirusOrderDetailsComponent:{
          component: MyOrdersLeftSectionComponent ,
        }
     
     
      }
    } as CmsConfig)
  ],



})
export class MyOrdersModule { }
