<div class="col-md-11 col-sm-12 col-xs-12 myprofile_flex myprofile_flex_cdc myprofile_flex_items" *ngIf="!toggleEdit">
    <div class="myprofile_content_cdc myprofile_flex ">
        <div class="blkfirst col-xs-12">
            <div class="col-md-12 white-background-profile marBottom40" style="padding-bottom: 0px;">
                <div class="col-md-12 no-pad"></div>
                <div *ngIf="profileDetails$ | async as item" class="profile-details col-xs-8 myprofile_cdccontent myprofilecdcboxus">
                    <div class="col-xs-12 myprofile_tophybrisheader Org_header">My Profile</div>
                    <div class="col-xs-12 mypro_labels">Name</div>
                    <div class="col-xs-12 mypro_data" id="profile_name">{{item.firstName +' ' +item?.lastName}}</div>
                    <div class="clearfix1"></div>
                    <div class="col-xs-12 mypro_labels">Job Title</div>
                    <div class="col-xs-12 mypro_data" id="profile_job">{{item?.jobTitle}}</div>
                    <div class="clearfix1"></div>

                    <div class="col-xs-12 mypro_labels">Contact Email</div>
                    <div class="col-xs-12 mypro_data" id="profile_email">{{item?.email}}</div>
                    <div class="clearfix1"></div>

                    <div class="col-xs-12 mypro_labels">Phone Number</div>
                    <div class="profileDisplayInline mypro_data" id="profile_phone" [innerHTML]="item.phone"></div>
                    <div class="clearfix1"></div>
                </div>
                <div class="col-xs-4" style="padding:0" (click)="edit()">
                    <div class="myprofile_edit edit-profile profile_edit_section" id="myprofile_cdceditbtn">Edit<img
                            src="assets/images/edit.png" width="20" alt="Edit">
                    </div>
                </div>
            </div>

        </div>
        <div class="clearfix1"></div>
    </div>
</div>