import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { I18nModule } from '@spartacus/core';
import { BusinessDetailsComponent } from './business-details/business-details.component';
import { ThankYouComponent } from './thank-you/thank-you.component';




@NgModule({
  declarations: [
    BusinessDetailsComponent,
    ThankYouComponent,
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        
        RegistrationCMSTabParagraphComponent : {
          component: BusinessDetailsComponent ,
        },

        RegisterThankyouCMSTabParagraphComponent : {
          component: ThankYouComponent,
        },
     
     
      }
    } as CmsConfig)
  ],
})
export class RegistrationModule { }
