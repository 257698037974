import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-custom-slide-resources',
  templateUrl: './custom-slide-resources.component.html',
  styleUrl: './custom-slide-resources.component.scss'
})
export class CustomSlideResourcesComponent {
 constructor(public component: CmsComponentData<CmsBannerComponent>,protected config: OccConfig, public accessService:ContentService, public cd:ChangeDetectorRef){
 }
slideNode: Observable<any> = this.component.data$; 
mergedObject1 :any= [];
getImage(image: any): string {
  return this.config.backend?.occ?.baseUrl + image.url;
}
ngOnInit() {
  this.slideNode.subscribe(res=>{
   console.log('Received datafinal:', res);
   const tilesList = res.flu360VaccineSlideResources.split(" ")
   tilesList.forEach((el:any) => {this.accessService.allproductsslides(el).subscribe(res=>{
     this.mergedObject1 = [...this.mergedObject1, res]
     this.cd.detectChanges();
     console.log(this.mergedObject1);
    })})
  })
 }
}
