
<div class="yCmsContentSlot row dashboard-splitscetion">    
    <div class="col-sm-12 col-lg-6 dashboard-invoicesection dashboard-invoicesection float_left">
        <div class="dashboard-invoicesection-header">{{'invoice.title' | cxTranslate}}</div>
            <div class="dashboard-invoicesection-text" *ngIf="invoiceNode | async as item" >
               
                <div [innerHTML]="'invoice.paragraph1' | cxTranslate : { invoice: item.invoiceCount }"></div>
                
            <div class="dashboard-invoicesection-text" *ngIf="invoiceNode | async as item">
                <div [innerHTML]="'invoice.paragraph2' | cxTranslate : { invoiceamount: item.invoiceTotalAmount | number:'1.2-2'}"></div>
               
            </div>
            <div class="dashboard-viewall dashboard-viewinvociemargin dashboard-viewinvociemargin">
                <a class="anchorunderline" routerLink="/financial-dashboard">{{'invoice.linktext' | cxTranslate}}<i class="global_blackarrow"></i></a>
            </div> 
        </div>
    </div>
    <div class="col-sm-12 col-lg-6 dashboard-creditsection dashboard-creditsection float_left">
        <div class="dashboard-invoicesection-header">{{'credit.title' | cxTranslate}}</div>
            <div class="dashboard-invoicesection-text dashboard-invoicesection-text" *ngIf="invoiceNode | async as item" [innerHTML]="'credit.paragraph1' | cxTranslate : { creditavailable: item.returnsandcreditsTotalAmount | number:'1.2-2' }"></div>
           
        <div class="dashboard-invoicesection-text dashboard-invoicesection-text1">{{'credit.paragraph2' | cxTranslate}}</div>
        
        <div class="dashboard-viewall dashboard-viewcreditmargin">
                <a class="anchorunderline" routerLink="/financial-dashboard">{{'credit.linktext' | cxTranslate}}<i class="global_blackarrow"></i></a>
            </div>
        </div>