import { Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-custom-top-banner',
  templateUrl: './custom-top-banner.component.html',
  styleUrl: './custom-top-banner.component.scss'
})
export class CustomTopBannerComponent {
  constructor(public userInfoService: UserInfoService) {
  }
  userData$:Observable<any>=this.userInfoService.getProfileData();

  ngOnInit() {
  }
}
