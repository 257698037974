import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fourth',
  templateUrl: './fourth.component.html',
  styleUrl: './fourth.component.scss'
})
export class FourthComponent {
  object1 : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusFourthComponent');

  constructor(private cmsService:CmsService, protected config: OccConfig,) {
  }
}
