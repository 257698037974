import { Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-help-section-dashboard',
  templateUrl: './help-section-dashboard.component.html',
  styleUrl: './help-section-dashboard.component.scss'
})
export class HelpSectionDashboardComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,public contentService:ContentService) {

  }
  bannerNode: Observable<any> = this.component.data$;
  vssdata:Observable<any> = this.contentService.vsscontent();
}
