<!-- <section class="financial_titlesection">
    <div class="col-xs-12 col-sm-12 col-md-7 col-lg-8">
        <div class="financial_header">Financial Dashboard</div>

    </div>
    <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 ">
        <div class = "financial_dateheader">
        Current Season:<span> 2024-2025</span>
    </div>
    </div>
</section> -->

<div class="col-xs-12 col-sm-12 financial_supportTiles">
				
    <div class="financial_righttoreturn">
        <div class="financial_supportheader">Right of Return</div>
        <div class="financial_supportfhelp">Your right of return is determined by your contract terms and agreements. It states how much product you can return for credit each season. Returns exceeding right of return can be eligible for Federal Excise Tax credits.</div>
        <div class="financial_help">
            <a class="anchorunderline" routerLink="/help-and-faq"> 

                    <span>View all Help &amp; FAQs </span>
                    

                
                <div class="global_blackarrow"></div>
                <div class="clearboth"></div>
            </a>
        </div>
    </div>


    
        <div class="financial_supportbox">
            <div class="financial_supportheader">flu360 Customer Service</div>
            <div class="financial_supportfhelp">If you have have questions, contact
                 <a href="/contact-us">flu360 Customer Service</a> by email or phone, or find answers on our <a href="/help-and-faq">Help &amp; FAQs </a>page.
                </div>
                <div class="financial_supportfhelp">For questions specific to credits, contact our finance team at 
            <!-- <a href="mailto:usainc.accountsreceivable@seqirus.com">usainc.accountsreceivable@seqirus.com</a> -->
        </div>
    </div>
</div>