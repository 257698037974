import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-opefficiency-first',
  templateUrl: './custom-opefficiency-first.component.html',
  styleUrl: './custom-opefficiency-first.component.scss'
})
export class CustomOpefficiencyFirstComponent {
  // firstheadline: Observable<any> = this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent');
  leftBannerNode: Observable<any> = this.bannerComponent.data$; 
  constructor(private cmsService:CmsService, protected config: OccConfig,public bannerComponent: CmsComponentData<CmsBannerComponent>) {
     }
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }

}
  //    ngOnInit(): void {
  //     this.cmsService.getComponentData('SeqirusOperationalEfficiencyFirstComponent').subscribe(res=>{
  //     this.firstheadline=res;
  //    })
  // }
