

<div *ngIf="seasonData | async as item">

  <div class="col-12" id="orders_topcontent">
    <div class="row">
    <div class="col-9 col-md-3 col-lg-3 right_border" id="orders_topcontentleftcontent">
      <h1 class="col-xs-12 orders_titeltop modified-h1">My Orders</h1>
    </div>
    <div class="col-3  col-md-4 col-lg-5  no-dis" id="orders_topcontentleftcontent">
      <div class="col-xs-12 orders_titeltop orders_titeltopNo" >{{item.orderCount}}</div>
    </div>
  
    <div class="col-12 col-md-5 col-lg-4" id="orders_topcontentleftcontent">
      
      
      <div class="row" >
        <select  (change)="selectedSeason($event)" class="current_sesson_dropdown" name="session" id="select_season">
          <!-- <option value="item.inSeason" selected>Current Season: {{item.inSeason}}</option> -->
          <!-- <option selected="selected" value="2024-2025">Current Season: 2024-2025</option> -->
        <option *ngFor="let obj of item.seqirusSeasonList; let i= index" [value]="obj" selected="obj == item.inSeason"> {{(obj == item.inSeason)?'Current Season': 'Previous Season'}} : {{obj}} </option>
<!--                             
                              <option  value="2023-2024">Previous Season: 2023-2024</option>
                              <option value="2022-2023">Previous Season: 2022-2023</option>
                              <option value="2021-2022">Previous Season: 2021-2022</option>
                              <option value="2020-2021">Previous Season: 2020-2021</option>
                              <option value="2019-2020">Previous Season: 2019-2020</option> -->
                              </select>
        
                            </div>
      </div>
    </div>
  </div>
    </div>
  