 

<div  [ngClass]="isUserLoggedIn ? 'alertloginbox':'alertbox'" id="alertlogin" *ngIf="loginalertcontent | async as alertmessage" >
    <div  class="collapse alerttext show col-md-11 col-11 float_left"
      
        [innerHtml]=alertmessage.content>
      
    </div>
    <button type="button" class="alertclose col-md-1 col-1 float_left" data-toggle="collapse" data-target="#alertlogin" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>


