import { ChangeDetectorRef, Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-custom-opefficiency-third',
  templateUrl: './custom-opefficiency-third.component.html',
  styleUrl: './custom-opefficiency-third.component.scss'
})
export class CustomOpefficiencyThirdComponent {
  constructor(private accessService: ContentService, public bannerComponent: CmsComponentData<CmsBannerComponent>, private cmsService: CmsService, private cd: ChangeDetectorRef, protected config: OccConfig) { }
  thirdheadline: Observable<any> = this.bannerComponent.data$;
  data1: any;
  data2: any;
  data3: any;
  mergedObject1: any = [];
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  ngOnInit() {
    this.thirdheadline.subscribe(res => {
      console.log('Received datafinal:', res);
      const tilesList = res.featuredResources.split(" ")
      tilesList.forEach((el: any) => {
        this.accessService.opeffeaturedResources(el).subscribe(res => {
          this.mergedObject1 = [...this.mergedObject1, res]
          this.cd.detectChanges();
          console.log(this.mergedObject1);
        })
      })
    })
  }
}
