import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sixth',
  templateUrl: './sixth.component.html',
  styleUrl: './sixth.component.scss'
})
export class SixthComponent {
  ref2 : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusSixthComponent');

  constructor(private cmsService:CmsService, protected config: OccConfig,) {
  }
}
