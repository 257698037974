<div class="col-xs-12 col-md-10 col-lg-12 payment-info_headersection">
  <div class="payment-info_headertext">Payment Information </div>
</div>
 <div class="col-xs-12 col-lg-12 payment-info_section main">  
  <div class=" payment-info_savedCards "> 
  <div class=" payment-info_Cardsheader col-md-12 col-12 no-padding">
  <div class="payment-info_Cardsheader-text ">Saved Credit Cards</div>
  <div class="payment-info_Cardsheader-tooltip">Need to edit?
  <span class="payment-info_Cardsheader-tooltiptext">If you need to edit your card details, delete the credit card and add it again with the new information.</span>
  </div>
</div>
                   <div id="DataTables" class="col-sm-12 dataTables_wrapper no-footer">
                    <table class="payment-info_savedCards-table dataTable " id="DataTables_Table_" role="grid">
    <tbody>
    <tr *ngFor="let card of creditdetails?.cardsData; let p=index">
     <td>    
    <div class="savedcard-parent" id=p>
   
      <img width ="75" class="image" *ngIf="card.cardType == '1'" src="../../assets/images/Visa_icon.png" alt="Visa">
      <img  width ="75" class="image" *ngIf="card.cardType == '2'" src="../../assets/images/amex_icon.png" alt="American Express">
      <img width ="75" class="image" *ngIf="card.cardType == '3'" src="../../assets/images/master_icon.png" alt="MasterCard">
      <img width ="75" class="image" *ngIf="card.cardType == '128'" src="../../assets/images/Discover_icon.png" alt="Discover">
    <div class="savedcard-cardno">{{card.cardNumber}}
      <p class="savedcard-expireDate" [ngClass]="getExpiryClass(card.expiryDate)">
        {{ getExpiryMessage(card.expiryDate) }}
        <img *ngIf="getExpiryClass(card.expiryDate) === 'expired'"
              class="icon" src="../../assets/images/exclamation-mark.png"
              >
        </p>
    </div>  
    
    <button class="savedcard-delete" data-toggle="modal" data-value="{{ card.token }}" data-target="#payment-info_deletemodal" (click)="handleOnDelete($event)">Delete <i class="fa fa-trash-o" aria-hidden="true"></i>
    </button>
 
    </div>
    </td>
    </tr>
    </tbody>
    </table>
    </div>
    </div>
 
<div class="payment-info_nosavedCards hidden">You don't have any saved credit cards. You can save a credit card during the payment process or be adding a credit card below.</div>
 
  <div class="payment-info_addCards ">
    <div class="payment-info_Cardsheader-text ">Add Credit Card<i class="fa fa-question-circle-o payment-info-tooltip">
  <span class="payment-info_Cardsheader-tooltiptext newcredit-tooltip">New credit cards or debit cards may be subject to a temporary authorization hold from your bank. No action will be needed from you - holds typically disappear in 1-3 business days.</span>
  </i>
  </div>
  <div class="payment-info_iframe ">
    <iframe id="iframe" src="https://payment.pay2.preprod.checkout.worldline-solutions.com/checkout/11122-9404de475ed84c4e95470139a8a5e128:06720e0e-0467-71ff-94e3-8f3b8fad0de9:b71e9c02d780402ea857e45fff7300bc" width="100%" height="350" frameborder="0">
 
  </iframe></div>
   
  </div>
  </div>
  <div id="payment-info_deletemodal" class="modal fade" role="dialog" [ngClass]="confirmDeletemodal?'show':'hide'" >
        <div class="modal-dialog">
         
            <!-- Modal content-->        
              <div class="modal-content">      
                <div class="modal-body row">
                    <h4 class="modal-title payment-info_deleteheader col-xs-9">Delete Card</h4>
                    <div class="no-padding pull-right"><span class="payment-info_close" data-dismiss="modal">×</span></div>
                    <p class="payment-info_deletesubheader col-xs-12">
                        Are you sure you would like to permanently delete this credit card from flu360.com?
                    <div class="clearboth"></div>
                    <div class="payment-info_deletebuttons">
                      <button class="payment-info_deleteYes global_redbtn"  data-toggle="modal" data-dismiss="modal" (click)="confirmDelete()">Yes</button>
                        <button class="payment-info_deleteNo global_greybtn" data-dismiss="modal" #closeButton>No</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>