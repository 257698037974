<div *ngIf="bannerNode | async  as item" id="laptop-row" class="row">
    <!-- <div class="col-xs-12 col-md-6 hide-laptop-desktop">
        <img class="laptop-image-mobile" [src]="getImage(item.media)">
    </div> -->
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 laptopParent">
        <div class="laptop-paragraph-content">
            <h2>{{item.headline}}</h2>
            <p>{{item.h2content}}</p>
            <ul [innerHTML]="item.paragraphcontent"></ul>
        </div>
    </div>

    <div class="col-xs-12 col-md-6 hide-laptop-mobile dashlapimage">
        <img class="laptop-image" alt="laptop with the flu360 account dashboard on screen" [src]="getImage(item.media)">
            </div>
</div>