import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { UserInfoService } from '../spartacus/services/user-info.service';

@Injectable({
  providedIn: 'root'
})
export class SeqirusAuthGuard {
  constructor(private authService: AuthService,
     private router: Router,
    private userInfoService:UserInfoService) {
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot
  // ): Observable<boolean | UrlTree> | boolean | any {
    
  //  return this.authService.isUserLoggedIn().pipe(
  //     map(isLoggedIn => {
  //       if (!isLoggedIn) {
  //         console.log(route, 'auth guard');
  //         this.router.navigate(['/login']);
  //         return false;
  //       }     
  //       return true;
  //     })
  //   );
  // }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return of(false);
        }
        return this.userInfoService.getUserData().pipe(
          map(userData => {
            // console.log(userData)
            if (userData && userData.webEnabled) {
              return true;
            } else {
              return true;
            }
          }),
          catchError(error => {
            this.router.navigate(['/error-page']);
            return of(false);
          })
        );
      })
    );
  }
}





