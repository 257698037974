import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftBannerComponent } from './left-banner/left-banner.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { HomepageBannerComponent } from './homepage-banner/homepage-banner.component';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LeftBannerComponent,
    HomepageBannerComponent,
  ],
  imports: [
    CommonModule,
    CmsCommonModule,
    FormsModule,
    ReactiveFormsModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusBackgroundBannerComponent: {
          component: HomepageBannerComponent
        },
        SeqirusLeftBannerComponent: {
          component: LeftBannerComponent
        }
      }
    } as CmsConfig)
  ]
})
export class BannerModule { }
