<div *ngIf="bannerNode | async  as item">
<div id="multiple-needs-header" class="row center-xs">
    <h2>{{item.headLine}}<br> {{item.subHeadLine}}</h2>
</div>
<div  class="container section-spacing">
	<div id="what-if-row" class="row center-xs" >
	<div *ngFor="let item2 of tilesarray; let i= index" class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content">
			<div class="whatif--card">
				<div class="whatif--card-content">
					<!-- <p class="text--red-100 whatif--card-header">{{item2.t1content}}</p> -->
					<p [ngClass]="{'text--red-100 whatif--card-header': i === 0, 'text--teal-100 whatif--teal-card-header': i === 1, 'text--black-100 whatif--black-card-header':i===2}">{{item2.t1content}}</p>
						<p class="content">{{item2.linkurl}}</p>
						<!-- <p class="text--red-100 callout">{{item2.t2content}}</p> -->
						<p [ngClass]="{'text--red-100 callout': i === 0, 'text--teal-100 calloutteal': i === 1, 'text--black-100 calloutblack': i === 2}">{{item2.t2content}}</p>
					<a href="{{item2.t3content}}" class="text-dark-gray cta">{{item2.t3content}}<img alt="Next screen" src="/assets/images/arrow-right.svg">
					</a>
				</div>
			</div>
		</div>	
	</div>
	</div>
</div>
<!--Tiles for operational-efficiency-->
	<!-- <div  class="container section-spacing "  >
		<div id="what-if-row" class="row center-xs  opefficinecy-whatif-row" >
		<div *ngFor="let item2 of tilesarray; let i= index" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 center-content">
				<div class="whatif--card opeff-whatif-card">
					<div class="whatif--card-content">
						
						<p [ngClass]="{'text--red-100 whatif--card-header': i === 0, 'text--teal-100 whatif--teal-card-header': i === 1}">{{item2.t1content}}</p>
							<p class="content">{{item2.linkurl}}</p>
							<p [ngClass]="{'text--red-100 callout': i === 0, 'text--teal-100 calloutteal': i === 1}">{{item2.t2content}}</p>
						<a href="{{item2.linkurl}}" class="text-dark-gray cta"  [innerHTML]="item2.t3content"><img alt="Next screen" src="/assets/images/arrow-right.svg">
						</a>
					</div>
				</div>
			</div>	
		</div>
	</div> -->
		<!--Tiles for Clinical support-->
		<!-- <div  class="container section-spacing " *ngIf="(item.uid === 'seqirusClinicalSupportMultipleNeedsComponent')" >
			<div id="what-if-row" class="row center-xs" >
			<div *ngFor="let item2 of mergedObject2; let i= index" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 center-content">
					<div class="whatif--card">
						<div class="whatif--card-content">
							<p [ngClass]="{'text--teal-100 whatif--teal-card-header': i === 0, 'text--black-100 whatif--black-card-header': i === 1}">{{item2.t1content}}</p>
								<p class="content">{{item2.linkurl}}</p>
								<p [ngClass]="{'text--black-100 calloutblack': i === 1, 'text--teal-100 calloutteal': i === 0}">{{item2.t2content}}</p>
							<a href="/clinical-support" class="text-dark-gray cta">{{item2.t3content}}<img alt="Next screen" src="/assets/images/arrow-right.svg">
							</a>
						</div>
					</div>
				</div>	
			</div>
			</div> -->
