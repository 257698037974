import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, switchMap, throwError, of, filter, BehaviorSubject, catchError, map } from 'rxjs';
import { GigyaService } from './gigya';
import { CdcJsService } from '@spartacus/cdc/root';
import {  BillingPayload, Bussinesspayload, LicenceValidationPayload, Payingpayload, ShippingPayload } from '../../registration/register.model';
declare var clickToAddress:any;

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private  apiUrllicense = this.occ.buildUrl("/users/current/register/validateLicence?fields=DEFAULT");
  private  apiBussinessSave = this.occ.buildUrl("/users/current/register/saveBusinessData?fields=DEFAULT");
  private  apiBillPayingSave = this.occ.buildUrl("/users/current/register/saveBillPayData?fields=DEFAULT");
  private  apiShippingSave = this.occ.buildUrl("/users/current/register/saveShippingData?fields=DEFAULT");
  private  apiSaveTemplate = this.occ.buildUrl("/users/current/register/saveTemplateform?fields=DEFAULT");
  private  apiGetUserdetails = this.occ.buildUrl("/users/current?fields=DEFAULT");
  private apiGetNewcustomer = this.occ.buildUrl("/users/current/register/newcustomer?fields=DEFAULT");
  constructor(private http: HttpClient, 
    private occ:OccEndpointsService) { 
  }
  public togglemabmenu:boolean=false;

  getnavigation(){
    const url = this.occ.buildUrl("/cms/components/AboutLink"); 
    return this.http.get(url);
  } 
  getimage(){
    const url = this.occ.buildUrl("/cms/components/SiteLogoComponent"); 
     return this.http.get(url);
  } 
  getimage1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusSiteLogoComponent"); 
     return this.http.get(url);
  }
  getNavigation(componentName:string){
    const url = this.occ.buildUrl("/cms/components/"+componentName); 
     return this.http.get(url);
  }
  getAccessResources(){
    const url = this.occ.buildUrl("/cms/components?fields=DEFAULT&currentPage=0&pageSize=16&componentIds=SeqirusResourceProduct1%2CSeqirusResourceProduct2%2CSeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  getAccess1(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct1"); 
     return this.http.get(url);
  }
  getAccess2(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct2"); 
     return this.http.get(url);
  }
  getAccess3(){
    const url = this.occ.buildUrl("/cms/components/SeqirusResourceProduct3"); 
     return this.http.get(url);
  }
  getBannerNode(){
    const url = this.occ.buildUrl("/cms/components/HomepageUserGuidePDFComponent"); 
    return this.http.get(url);
  }

  getSearchAccount(params:HttpParams):Observable<any>{
    return this.http.get(this.occ.buildUrl("/users/current/join-account/searchAccount"),{params:params,responseType:'text'});
  }

  getRequestAssistance(params: HttpParams): Observable<any> {
    //console.log(params);
    return this.http.get(this.occ.buildUrl("/users/current/join-account/requestAssistance"),{params:params,responseType:'text',observe:'response'});
  
  }
  submitJoinAccount(data: any): Observable<any> {
    return this.http.post(this.occ.buildUrl("/users/current/join-account/submit"),data);
  
  }
  
  getLeftContentForCreateAccount(){
    const url = this.occ.buildUrl("/cms/components/RegistrationBanner"); 
     return this.http.get(url);
  }
  vsscontent(){
    const url = this.occ.buildUrl("/users/current/my-account/vss?fields=FULL"); 
    return this.http.get(url);
  }
  verifyRecaptcha(captchaResp:string) {
    
    const headers = new HttpHeaders({
      'Accept':'application/json'
    })
    return this.http.get(this.occ.buildUrl(`/users/current/captcha-check/${captchaResp}`),{headers});
  }
  savedCreditDetail(){
    const url = this.occ.buildUrl("/users/current/financials/fetch-saved-cards?fields=DEFAULT");
    return this.http.get(url);
  }

  fetchifyCall(){
    return new clickToAddress({
      accessToken: '252fa-0e273-cf8ab-03341',
      countryMatchWith: 'text', 
      enabledCountries: ['United States'],
      domMode: 'name',
      gfxMode: 1,
      style: {
        ambient: 'light', // Use white as main interface color
        accent: 'default' // Use default secondary color for interface
      },
      texts: {
      default_placeholder: 'Start with post/zip code or street',
      },
      showLogo: true,
      disableAutoSearch:false,
     
    })
  }
  
  validateLicence(payload: LicenceValidationPayload): Observable<any> {
    const params = new HttpParams()
      .set('licenceName', payload.licenceName)
      .set('licenceNumber', payload.licenceNumber)
      .set('licenceState', payload.licenceState);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.get<any>(this.apiUrllicense, { headers, params });
  }
  
  saveBussiness(payload: Bussinesspayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(this.apiBussinessSave, payload, { headers });
  }
  
  savePaying(payload: Payingpayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(this.apiBillPayingSave, payload, { headers });
  }
  
  saveShipping(payload: ShippingPayload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    return this.http.post<any>(this.apiShippingSave, payload, { headers });
  }
  
  uploadExcelFile(file: File, uid: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('loginEmailId', uid);
    return this.http.post(this.apiSaveTemplate, formData, { responseType: 'text' }).pipe(
      catchError(this.handleError)
    );
      
  }
  
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  
  getUserData(): Observable<any> {
    return this.http.get<any>(this.apiGetUserdetails);
  }
  
  getnewUserData(): Observable<any> {
    return this.http.get<any>(this.apiGetNewcustomer);
  }
  
  
  SaveBilling(billingAddress: BillingPayload): Observable<BillingPayload> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<BillingPayload>(this.apiBillPayingSave, billingAddress, { headers });
  }
}
