<div id="OM_exitPopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1" style="display: block; padding-right: 10px;" *ngIf="showExitPopUp">hiii
    <div class="modal-dialog">

        <div class="modal-content">
            <div class="modal-body">
                <h4 class="modal-title OMexit_popHeader col-xs-9">{{exitPopupData.headLine}}</h4>
                <div class="col-xs-3">
                    <span class="OMexit_popClose" (click)="handleOnCloseButton()">×</span>
                </div>
                <div [innerHTML]="exitPopupData.content"></div>
                <div class="clearboth"></div>
                <div class="OM_exitPopBtnParent">
                    <button class="OM_popExitBtn" routerLink="/my-account">{{exitPopupData.t1content}}</button>
                    <button class="OM_popBackBtn" data-dismiss="modal" (click)="handleOnCloseButton()">{{exitPopupData.t2content}}</button>
                </div>
            </div>
        </div>
    </div>
</div>