import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrl: './landingpage.component.scss'
})
export class LandingpageComponent {
  constructor(public PageParagraphComponent : CmsComponentData<any>,private contentservice:ContentService,private cd:ChangeDetectorRef){}
  listarray=[];
  startlandingcontent: Observable<any> = this.PageParagraphComponent.data$;
  startdata:any;
  landing:any=[];
  landingapi:any;
  seasonModal:boolean= false;
  @ViewChild('seasonModal')
  seasonModalTemplate: any;
  seasonclick:string=''
  ngOnInit() {

    this.startlandingcontent.subscribe(res => {
      this.startdata=res;
      const list=this.startdata.ourCommitmentsList;
      this.listarray=list.split(" ");
   
    });
  
      for(let i=0;i<this.listarray.length;i++){
        this.contentservice.startorder_Landing(this.listarray[i]).subscribe(res => {
          this.landing[i]=res;
          this.cd.detectChanges();
        });
      }
      this.contentservice.startapi().subscribe(data => {
      this.landingapi=data;
      console.log(this.landingapi);
      this.cd.detectChanges();
      });
     
    
  }
  openModal(seasonvalue:string){
  this.seasonModal=true;
  this.seasonclick=seasonvalue;
  }

}

