import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, I18nModule, OccConfig, provideConfig, SiteContextConfig } from "@spartacus/core";
import { defaultB2bOccConfig } from "@spartacus/setup";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { translationOverwrites } from '../translations/en/custom_asm';

@NgModule({
  declarations: [],
  imports: [I18nModule
  ],
  providers: [provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<SiteContextConfig>{
    context: {
      baseSite: ['seqirusb2b-flu360'],
      currency:['USD'],
      language: ['en']
    },
  }), provideConfig({
    i18n: {
      backend: {
        loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json'
      },
      resources: {
        en: translations
      },
      chunks: {
        ...translationChunksConfig,
        dashboardPage: ['invoice','credit'],
        joinAccountPage: ['breadcrumb','form'],
        customAsm: ['asm'],
        startOrderPage: ['startOrder','memebership'],
        orgLocation:['orgLocation','orgLocform']
      },
      fallbackLang: 'en',
    }
  }), provideConfig(<FeaturesConfig>{
    features: {
      level: '2211.25'
    }
  }), provideConfig(defaultB2bOccConfig),
  provideConfig({
    i18n: {
      resources: translationOverwrites
    },
  })]
})
export class SpartacusConfigurationModule { }
