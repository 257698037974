<div *ngIf="creditBlockModal">
<div  id="creditpopup" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
				tabindex="-1" >
		<div class="modal-dialog">
            <div class="modal-content">
							<div class="modal-body" *ngIf="creditPopUpData">
								<div class="col-xs-12 returnopenheader">
								<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{creditPopUpData.headline}}</h4>
								<div class="col-xs-3 no-padding">
									<span class="OMexit_popClose creditBlockExit_pop" data-dismiss="modal" (click)="closeCreditPopUp()">&times;</span>
								</div>
							</div>
								<p class="returnpopupsub col-xs-12 margin-T20">{{creditPopUpData.message1}}</p>
								<p class="returnpopupsub col-xs-12">{{creditPopUpData.message2}}</p>
								
								<ng-container *ngIf="popupsData.hasOverDueInvoice == false;else noOverDue">
									<p class="returnpopupsub  col-xs-12">{{creditPopUpData.message3}}</p>
									<div class="clearboth"></div>
									<div class="OM_exitPopBtnParent">
										<button class="returnbtn allinvoiceredirect creditBlockExit_pop" [innerHTML]="creditPopUpData.content"></button>
									</div>
                                </ng-container>
								<ng-template #noOverDue>
									<p class="returnpopupsub  col-xs-12 margin-B20">{{creditPopUpData.message3}}</p>
									<div class="clearboth"></div>
								</ng-template>
								<div class="clearboth"></div>
								<div class="returnpopupjobcode">
									{{creditPopUpData.jobBagCode}}
                                </div>
							</div>
						</div>
					</div>
</div>
</div>

<div *ngIf="showConsentPopup">
<div id="consentpopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <h4 class="modal-title consentheader col-xs-9">{{ukConsentPopupData.headline}}</h4>
                <p class="consentsubheader col-xs-12"><span>{{ukConsentPopupData.message1}}</span>
                    <span class="consentsubheader_mail">{{marketingEmail}}</span>
                        <span>{{ukConsentPopupData.message2}}</span>
                        <span>
                            <a target="_blank" href="https://accounts.eu1.gigya.com/accounts.store.downloadPublicConsentDocument?docID=12630585_587824270786_fd6084f03830416db38a57e84e3c79a8">privacy policy</a>.
                        </span>
                </p>
                <p class="consentsubheader col-xs-12">{{ukConsentPopupData.message3}}</p>
                <div class="clearboth"></div>
                <div class="consentpopbtnparent">
                    <button class="consentyesbtn" (click)="isConsentGranted('true')">{{ukConsentPopupData.content}}</button>
                    <button class="consentNobtn" data-dismiss="modal" (click)="isConsentGranted('false')">{{ukConsentPopupData.label1}}</button>
                </div>
                <div class="consentPopupDocUk">{{ukConsentPopupData.jobBagCode}}<div>
            </div>
        </div>
    </div>
</div>
    </div>
</div>
</div>

<div *ngIf="showApiFailurePopup">
<div id="apifailurepopup" class="modal fade in show" role="dialog" data-backdrop="static" data-keyboard="false" tabindex="-1">
			
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
				<div class="col-xs-12 returnopenheader">
					<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{apiFailurePopupData.headline}}</h4>
					<div class="col-xs-3 no-padding">
											<!-- <span class="OMexit_popClose" data-dismiss="modal">&times;</span> -->
					</div>
				</div>
				<p class="returnpopupsub col-xs-12 margin-T20">{{apiFailurePopupData.message1}}</p>
				<div class="clearboth"></div>
				
                <div class="OM_exitPopBtnParent">
                    <button class="returnbtn"><a class="exitSkipBtn" href="/my-account">{{apiFailurePopupData.label1}}</a></button>
				</div>
				<div class="clearboth"></div>
				<div class="returnpopupjobcode">{{apiFailurePopupData.jobBagCode}}</div>
			</div>
		</div>
	</div>
</div>
</div>

<div *ngIf="showReturnsFirstPopup">
<div id="returnpopupactive" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
				tabindex="-1">
					<div class="modal-dialog">
	
						<div class="modal-content">
							<div class="modal-body">
								<div class="col-xs-12 returnopenheader">
								<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{returnsFirstPopupData.headline}}</h4>
								<div class="col-xs-3 no-padding">
									<span class="OMexit_popClose returnsExit_pop" (click)="closeReturnsFirstPopup()" data-dismiss="modal">&times;</span>
								</div>
							</div>
								<p class="returnpopupsub col-xs-12 margin-T20">{{returnsFirstPopupData.message1}}</p>
								<p class="returnpopupsub col-xs-12" [innerHTML]="returnsFirstPopupData.message2"></p>
								<div class="clearboth"></div>
								<div class="OM_exitPopBtnParent">
									<button class="ReturnDont" data-dismiss="modal" (click)="closeReturnsFirstPopup()">{{returnsFirstPopupData.message3}}</button>
									<button class="returnbtn returnsExit_pop"><a routerLink="/returns">{{returnsFirstPopupData.label1}}</a></button>
								</div>
								<div class="clearboth"></div>
								<div class="returnpopupjobcode">
									{{returnsFirstPopupData.jobBagCode}}
                                </div>
							</div>
						</div>
					</div>
</div>
</div>

<div *ngIf="showReturnsSecPopup">
<div id="returnpopup" class="modal fade in show" role="dialog"  data-backdrop="static" data-keyboard="false" 
				tabindex="-1">
					<div class="modal-dialog">
	
						<div class="modal-content">
							<div class="modal-body">
								<div class="col-xs-12 returnopenheader">
								<h4 class="modal-title return_popHeader col-xs-9 no-padding">{{returnsSecPopupData.headline}}</h4>
								<div class="col-xs-3 no-padding">
									<span class="OMexit_popClose returnsExit_pop" (click)="closeReturnsSecPopup()" data-dismiss="modal">&times;</span>
								</div>
							</div>
								<p class="returnpopupsub col-xs-12 margin-T20">{{returnsSecPopupData.message1}}</p>
								<p class="returnpopupsub col-xs-12" [innerHTML]="returnsSecPopupData.message2"></p>
								<div class="clearboth"></div>
								<div class="OM_exitPopBtnParent">
									<button class="ReturnDont" data-dismiss="modal" (click)="closeReturnsSecPopup()">{{returnsSecPopupData.message3}}</button>
									<button class="returnbtn returnsExit_pop"><a routerLink="/returns">{{returnsSecPopupData.label1}}</a></button>
								</div>
								<div class="clearboth"></div>
								<div class="returnpopupjobcode">
									{{returnsSecPopupData.jobBagCode}}
                                </div>
							</div>
						</div>
					</div>
</div>
</div>