<ng-container *ngIf="sideContent | async as sideData">
    <!-- <section class="col-xs-12 col-md-12 contact_midsection contact_midsectionUS"> -->
    <!-- <div class="col-xs-12 col-md-6 contact_Support contact_SupportUS"> -->
    <div class="col-12 contact_Support contact_SupportUS">
    <div class="contact_supportheader1">Reporting & Product Queries</div>
    <div class="contact_textcontainerParent">
    <div class="col-xs-12 contact_textcontainer" id="contact_textcontainer1">
        
            <div class="contact_supportheader2">{{sideData.headline}}</div>
            <div class="contact_supporttext">{{sideData.subcontent}}</div>
            <div class="contact_email" >
                <div [innerHTML]="sideData.adverseemail"></div>
                <div [innerHTML]="sideData.adversecall"></div>
            </div>
    </div>
    <div class="col-xs-12 contact_textcontainer" id="contact_textcontainer2">
        <div class="contact_supportheader2">{{sideData.headline2}}</div>
            <div class="contact_supporttext" [innerHTML]="sideData.subcontent2"></div>
            <div class="contact_email">
            <div [innerHTML]="sideData.medicalvisit"></div>
            <div [innerHTML]="sideData.medicalemail"></div>
            <div [innerHTML]="sideData.medicalcall"></div>
            </div>
    <div class="clearboth"></div>
    </div>
</div>
</div>
<!-- </section> -->
</ng-container>
