import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';
@Component({
  selector: 'app-my-orders-dashboard',
  templateUrl: './my-orders-dashboard.component.html',
  styleUrl: './my-orders-dashboard.component.scss'
})
export class MyOrdersDashboardComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService) {
  }
  bannerNode: Observable<any> = this.component.data$;
  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
  seasons$ = this.userInfoService.seasonValue$;
  data:any;
  selectedSeason(e :any){    
    this.userInfoService.sendDropdownValue((e.target as HTMLInputElement).value)
   // console.log('event', (e.target as HTMLInputElement).value)
  }
}
