import { Component } from '@angular/core';
import { CmsBannerComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fifth',
  templateUrl: './fifth.component.html',
  styleUrl: './fifth.component.scss'
})
export class FifthComponent {
  banner : Observable<any> = this.component.data$;

  constructor(public component: CmsComponentData<CmsBannerComponent>,private cmsService:CmsService, protected config: OccConfig,) {
  }
}
