import { Component } from '@angular/core';
import { CmsService, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-third',
  templateUrl: './third.component.html',
  styleUrl: './third.component.scss'
})
export class ThirdComponent {
  object1 : Observable<any> = this.cmsService.getComponentData('SeqirusInfluenzaPlusThirdComponent');

  constructor(private cmsService:CmsService, protected config: OccConfig,) {
  }
}
