import { NgModule } from '@angular/core';
import { cdcTranslationChunksConfig, cdcTranslations } from "@spartacus/cdc/assets";
import { CdcConfig, CdcRootModule, CDC_FEATURE } from "@spartacus/cdc/root";
import { CmsConfig, ConfigModule, I18nConfig, provideConfig } from "@spartacus/core";

@NgModule({
  declarations: [],
  imports: [
    CdcRootModule,
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [CDC_FEATURE]: {
        module: () =>
          import('@spartacus/cdc').then((m) => m.CdcModule),
      },
    }
  }),
  provideConfig(<I18nConfig>{
    i18n: {
      resources: cdcTranslations,
      chunks: cdcTranslationChunksConfig,
    },
  }),
  provideConfig(<CdcConfig>{
    cdc: [{
      baseSite: 'seqirusb2b-flu360',
        javascriptUrl: 'https://cdns.us1.gigya.com/JS/gigya.js?apikey=3_aaXUBrtGk2tjRxJHLWpYo8tNWiJRqTSnWC51C5gKx-cgcXiRLvHCzXW_06HhpH1C',
        sessionExpiration: 3600
    },
    {
      baseSite: 'seqirusb2b-uk',
        javascriptUrl: 'https://cdns.eu1.gigya.com/JS/gigya.js?apikey=3_TxiHsceFIdFRRXyLMpS5LWgc5IIx6bJgTYDzAACXENPfZCPoHfYJ0DCfqBZf4NMi',
        sessionExpiration: 3600
    }
      // {
      //   baseSite: 'BASE_SITE_PLACEHOLDER',
      //   javascriptUrl: 'JS_SDK_URL_PLACEHOLDER',
      //   sessionExpiration: 3600
      // },
    ],
  })
  ]
})
export class CdcFeatureModule { }
