import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { BaseSiteService } from '@spartacus/core';


@Component({
  selector: 'app-open-credit-invoice-dashboard',
  templateUrl: './open-credit-invoice-dashboard.component.html',
  styleUrl: './open-credit-invoice-dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class OpenCreditInvoiceDashboardComponent {
  currentSite: string="";
  
  constructor(public contentService: ContentService,public userInfoService:UserInfoService,private baseSiteService:BaseSiteService ) {}
  invoiceNode: Observable<any> = this.userInfoService.getInvoiceData();

  ngOnInit(){
    this.baseSiteService.getActive().subscribe(site => {
      this.currentSite = site;
    })
  }
}
