<ng-container *ngIf="currentSite === 'seqirusb2b-flu360'">
<div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssection">
    <strong [innerHTML]="this.headline"></strong></div>
    <div class="col-xs-12 margin-T25 dashborad_announceleftcontent"  [innerHTML]="this.largeparagraphcontent"></div>
    <div class="col-xs-12 margin-T25 dashborad_readmore" (click)="routerlinkClicked($event)" [innerHTML]="this.startOrder">
    </div>
</ng-container>

<ng-container *ngIf="currentSite === 'seqirusb2b-uk'">
    <div class="col-xs-12 dashborad_anoucementssection dashborad_anoucementssectionuk">{{headline}}</div>
        <div class="col-xs-12 margin-T25 dashborad_announceleftcontentuk" [innerHTML]="this.largeparagraphcontent"></div>
        <div class="col-xs-12 margin-T25 dashborad_Exploreuk dashboardtoomsuk" [innerHTML]="this.startOrder">
    </div>
    <div class="dashboarddocnumberUk">{{jobbagcode}}</div>
</ng-container>

