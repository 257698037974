

<ng-container *ngIf="joinAccData | async as para" class="joinAccount col-xs-12 no-padding">
    <div class="joinAccount col-xs-12 no-padding" >
    
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 joinAccount_parent_header no-padding-left">
        <div class="joinAccount_right_header">{{'breadcrumb.home' | cxTranslate}} &gt; <span>{{'breadcrumb.createAcc' | cxTranslate}}</span></div>
    </div>
    <div class="clearboth"></div>

    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 joinAccount_leftside no-padding-right">
        <div class="col-lg-7 col-md-7 joinAccount_nagivation no-padding">
                
            <div [innerHTML]="para.leftContent"></div>
              
        </div>
    </div>
    
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 joinAccount_rightside no-padding-left">
        <section class="joinAccount_section" id="joinAccount_section1">
            <div [innerHTML]="para.topContent">{{para.topContent}}</div>

            <div class="padding-R15">
					   
                <form (ngSubmit)="submitForm()" id= "jointaccountFormUS" [formGroup]="jointaccountFormUS">
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 joinAccount_parent form-group">
                    <label class="joinAccount_inputLabel" for="joinAccount-number">{{'form.accountNum' | cxTranslate}}</label>
                    <div class="input-group">
                    <input class="joinAccount_input form-control" id="joinAccount-number" name="joinAccount-number" [ngClass]="{'is-invalid':submitted && jointaccountFormUS.get('account')?.invalid}" formControlName="account"/>
                    <span class="input-group-addon" *ngIf="submitted && jointaccountFormUS.get('account')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>
                    <div class="error help-block" *ngIf="submitted  && hasError('account','required')">{{'form.errorAccNum' | cxTranslate}}</div>
                    <div class="error help-block" *ngIf="submitted && hasError('account','minlength')">{{'form.errorAccNumMin' | cxTranslate}}</div>
                
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAccount_mob form-group">
                    <label class="joinAccount_inputLabel"  for="joinAccount-zipcode">{{'form.zipCode' | cxTranslate}}</label>
                    
                    <div class="input-group">
                    <input class="joinAccount_input form-control" id="joinAccount-zipcode" name="joinAccount-zipcode" [ngClass]="{'is-invalid':submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid}" formControlName="accessCodeFlag"/>
                    <span class="input-group-addon" *ngIf="submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid">
                        <span class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
                    </span>
                    </div>

                    <div class="error help-block" *ngIf="submitted && jointaccountFormUS.get('accessCodeFlag')?.invalid">{{'form.errorZipCode' | cxTranslate}}</div>
                
                </div>
                <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 joinAcc_search">
                    <button type="submit" class="joinAccount_search active">{{'form.search' | cxTranslate}}</button>
                </div>

                <div class="clearboth"></div>
                
                <div *ngIf="submitted && (hasError('account','required') || hasError('accessCodeFlag','required') || hasError('account','minlength'))">
                    <p class="joinAccount_errortext">{{'form.errorBothField' | cxTranslate}}</p>
                </div>

                <div *ngIf="(accountName | async) as response">
                 <p *ngIf="response === 'accountBlocked'" class="joinAccount_errortext">{{'form.errorAccBlocked' | cxTranslate}}</p>
                </div>                
                </form>

                
                
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20">
                <div class="joinAccount_text3" id="jointaccount-existing-orgname" style="display:none" ></div>
            </div>
            
    <div *ngIf="(accountName | async) as acc">
            <div *ngIf="acc != 'Exception' && acc != 'accountBlocked' && acc != 'notFound';else requestAssist" class="suceesshowsection">
            
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20">
                <div class="joinAccount_text3" id="jointaccount-orgname">{{acc}}</div>
                </div>
           
                <div class="margin-B20 joinAccount_text4parent joinAccount_checkparent">
                    <label class="checkbox-button">
                        <input type="checkbox" class="checkbox-button__input" id="jointacccheckbox" (change)="onCheckboxChange($event)">
                            <span class="checkbox-button__control"></span> 
                            <span class="jointaccountchecklable">&nbsp;{{'form.acknowledgeText' | cxTranslate}}</span>
                    </label>
                </div>
            
            <button [ngClass]="isChecked ?'joinAccountBtn active':'joinAccountBtn inactive'" [disabled]="!isChecked" (click)="submitJoinAccount(acc)">{{'form.joinAcc' | cxTranslate}}</button>
            
            <div class="clearboth"></div>
            
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20">
                    <div class="joinAccount_text5" [innerHTML]="para.requestAssistanceContent">{{para.requestAssistanceContent}}</div>
            </div>
            
            <div class="clearboth"></div>

            </div>
        
    
        <ng-template #requestAssist>
            <div *ngIf="acc != 'accountBlocked'" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-B20 searchFailText no-padding-left">  
                		  
                   <div class="joinAccount_text6">{{'form.accNotFound' | cxTranslate}}</div>
                      <div *ngIf="!showReqAssistanceSuccess" class="col-lg-4 col-md-4 col-sm-4 col-xs-12 margin-T20 requestAssistance_hideButton">
                       <button type="button" class="joinAccount_request active" (click)="raiseAssistanceReq()">
                        {{'form.reqAssistance' | cxTranslate}}</button> 
                    </div>
                    <div *ngIf="!showReqAssistanceSuccess" class=" col-lg-8 col-md-8 col-sm-8 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_hideText" [innerHTML] ="para.bottomContent"></div>
                    <div *ngIf="showReqAssistanceSuccess" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 joinAccount_text7 margin-T20 requestAssistance_showSuccessMessage">{{'form.thankyouText' | cxTranslate}}</div>
                
            </div>
        </ng-template>
    
    </div>

    </section>
    </div>
</div>
</ng-container>
