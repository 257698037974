import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { CustomTopBannerComponent } from './custom-top-banner/custom-top-banner.component';
import { CustomAccountInfoComponent } from './custom-account-info/custom-account-info.component';
import { CustomManageLocationComponent } from './custom-manage-location/custom-manage-location.component';
import { CustomInfoComponent } from './custom-info/custom-info.component';
import { CustomPaymentInfoComponent } from './custom-payment-info/custom-payment-info.component';
import { CustomMembershipComponent } from './custom-membership/custom-membership.component';
import { CustomUpdateProfileComponent } from './custom-update-profile/custom-update-profile.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import { CustomMemberModalComponent } from './custom-member-modal/custom-member-modal.component';
import { CustomRequestThankyouComponent } from './custom-request-thankyou/custom-request-thankyou.component';

@NgModule({
  declarations: [CustomTopBannerComponent,
    CustomManageLocationComponent,
    CustomAccountInfoComponent, 
    CustomPaymentInfoComponent,
    CustomInfoComponent, 
    CustomUpdateProfileComponent,
    CustomMembershipComponent,
  CustomMemberModalComponent,
  CustomRequestThankyouComponent],
  imports: [
    CommonModule,
    UrlModule,
    RouterModule,
    MatTableModule,
    MatSortModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusAccountInformationComponent:{
        component: CustomAccountInfoComponent,
        },
        SeqirusProfilePaymentInfoComponent:{
          component: CustomPaymentInfoComponent,
        },
        SeqiruslocationsComponent:{
          component: CustomManageLocationComponent,
        },
        SeqirusAccountMembershipComponent:{
          component: CustomMembershipComponent,
        },
        SeqirusContactInformationComponent:{
          component: CustomInfoComponent,
        },
        myprofileTopbannerComponent:{
          component:CustomTopBannerComponent,
        },
        ProfileThankYouCMSParagraphComponent:{
          component:CustomMemberModalComponent
        }
      },
    } as CmsConfig)
  ],
  exports:[CustomUpdateProfileComponent]
})
export class CustomMyProfileModule { }
