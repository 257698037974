import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { PaymentInformationComponent } from './payment-information/payment-information.component';



@NgModule({
  declarations: [PaymentInformationComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SeqirusPaymentInformationComponent :{
          component: PaymentInformationComponent,
      },
    }
  }as CmsConfig)
  ]
})
export class PaymentInfoModule { }
