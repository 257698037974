import { Component } from '@angular/core';
import { UserInfoService } from './spartacus/services/user-info.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'flu360_csf';
  userData: any;
  constructor(private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.userInfoService.fetchUserData();
  }


}

