<div *ngIf="isLoading" class="loading">
    <div class="loading_txt">
      <div class="load_txt">Loading</div>
       <span></span>
       <span></span>
       <span></span>
       <span></span>
    </div>
</div>
<ng-container *ngIf="formContent | async as formContentData">
<div class="col-12 contact_Support">
    
    <div class="contact_supportheader1">{{formContentData.title}}</div>
    <div class="contact_textcontainer">	                        
        <div class="contact_supporttext" [innerHTML]="formContentData.h1content"></div>								
        <div class="contact_supporttext">{{formContentData.h2content}}</div>
        <div class="contact_supporttext">{{formContentData.h3content}}</div>	                         
        <form class="loggedinform feedbackForm" id="feedbackForm_loggedin" [formGroup]="loggedInFeedbackForm" (submit)="submitFeedbackForm()">
        <div class="padding-R15">
            <div class="col-lg-4 col-sm-12 col-xs-12 no-padding margin-B10 has-feedback form-group" [ngClass]="{'has-error':loggedInFeedbackForm.get('firstName')?.invalid}">
                <label class="registration_inputLabel" for="feedbackForm-firstname">
                    First Name*
                </label>
                <input class="feedback_input form-control" id="feedbackForm-firstname" name="firstName"
                    autocomplete="off" formControlName="firstName"/>
                    <cx-form-errors [control]="loggedInFeedbackForm.get('firstName')"></cx-form-errors>
                <span *ngIf="loggedInFeedbackForm.get('firstName')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <!-- <label *ngIf="loggedInFeedbackForm.get('firstName')?.hasError('pattern')" id="feedbackForm-firstname-error" class="error has-error help-block" for="feedbackForm-firstname">Please enter only letters</label>
                <label *ngIf="loggedInFeedbackForm.get('firstName')?.hasError('required')" id="feedbackForm-firstname-error" class="error has-error help-block" for="feedbackForm-firstname">This field is required</label>
             --></div>
            <div class="col-lg-4 col-sm-12 col-xs-12 no-padding-right margin-B10 has-feedback form-group" [ngClass]="{'has-error':loggedInFeedbackForm.get('lastName')?.invalid}">
                <label class="registration_inputLabel" for="feedbackForm-lastname">
                    Last Name*
                </label>
                <input class="feedback_input form-control" id="feedbackForm-lastname" name="lastName"
                    autocomplete="off"  formControlName="lastName"/>
                    <cx-form-errors [control]="loggedInFeedbackForm.get('lastName')"></cx-form-errors>
                    <span *ngIf="loggedInFeedbackForm.get('lastName')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <!-- <label *ngIf="loggedInFeedbackForm.get('lastName')?.hasError('pattern')" id="feedbackForm-lastname-error" class="error has-error help-block" for="feedbackForm-lastname">Please enter only letters</label>
                <label *ngIf="loggedInFeedbackForm.get('lastName')?.hasError('required')" id="feedbackForm-lastname-error" class="error has-error help-block" for="feedbackForm-lastname">This field is required</label>
                 -->
            </div>
            <div class="clearboth"></div>
        </div>
        <div class="col-lg-8 col-sm-12 col-xs-12 no-padding-left margin-B10 form-group has-feedback feedback_email" [ngClass]="{'has-error':loggedInFeedbackForm.get('email')?.invalid}">
            <label class="registration_inputLabel" for="feedbackForm-email">
                Email Address*
            </label>
            <input class="feedback_input form-control" id="feedbackForm-email" name="emailAddress"
                autocomplete="off" formControlName="email"/>
                <!-- <cx-form-errors [control]="loggedInFeedbackForm.get('email')"></cx-form-errors> -->
                <span *ngIf="loggedInFeedbackForm.get('email')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <label *ngIf="loggedInFeedbackForm.get('email')?.hasError('email')" id="feedbackForm-email-error" class="error has-error help-block" for="feedbackForm-email">E-mail address is invalid</label>
                <label *ngIf="loggedInFeedbackForm.get('email')?.hasError('required')" id="feedbackForm-email-error" class="error has-error help-block" for="feedbackForm-email">This field is required</label>
        </div>
        <div class="clearboth"></div>
        <div class="padding-R15">                                   
            <div class="col-lg-5 col-sm-12 col-xs-12 no-padding margin-B10 has-feedback form-group" [ngClass]="{'has-error':loggedInFeedbackForm.get('phnNumber')?.invalid}">
                <label class="registration_inputLabel" for="feedbackForm-phone">
                    Phone Number*
                </label>
                <input class="feedback_input form-control" id="feedbackForm-phone" name="phoneNumber" mask="(999) 999-9999" [showMaskTyped]="true"
                    autocomplete="off" formControlName="phnNumber" />
                <span *ngIf="loggedInFeedbackForm.get('phnNumber')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <label *ngIf="loggedInFeedbackForm.get('phnNumber')?.hasError('pattern')" id="feedbackForm-phnNumber-error" class="error has-error help-block" for="feedbackForm-phnNumber">Please enter US Format Phone No</label>
                <label *ngIf="loggedInFeedbackForm.get('phnNumber')?.hasError('required')" id="feedbackForm-phnNumber-error" class="error has-error help-block" for="feedbackForm-phnNumber">This field is required</label>
                
            </div>
            <div class="col-lg-3 col-sm-12 col-xs-12 no-padding-right margin-B10 has-feedback form-group" [ngClass]="{'has-error':loggedInFeedbackForm.get('phnExt')?.invalid}">
                <label class="registration_inputLabel" for="feedbackForm-extn">
                    Ext
                </label>
                <input class="feedback_input form-control" id="feedbackForm-extn" name="phoneExt"
                    maxlength="4" autocomplete="off" formControlName="phnExt" />
                <span *ngIf="loggedInFeedbackForm.get('phnExt')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <label *ngIf="loggedInFeedbackForm.get('phnExt')?.invalid" id="feedbackForm-phnExt-error" class="error has-error help-block" for="feedbackForm-phnExt">Please enter your Valid Extn Number</label>
            
            </div>
            <div class="clearboth"></div>
        </div>
        <div class="col-xs-12 no-padding-left margin-B10 registration_mob form-group has-feedback feedbackForm_topic" [ngClass]="{'has-error':loggedInFeedbackForm.get('topic')?.touched && loggedInFeedbackForm.get('topic')?.invalid}">
                <label class="registration_inputLabel " for="feedbackForm-topic">
                    Topic*
                </label>
                <select class="registration_state form-control" id="feedbackForm-topic" name="topic" formControlName="topic">
                    <option value="" selected="selected"></option>
                    <ng-container *ngFor="let topic of topics">
                        <option value="topic" >{{topic}}</option>
                    </ng-container>
                    
                </select>
                <span *ngIf="loggedInFeedbackForm.get('topic')?.touched && loggedInFeedbackForm.get('topic')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <label *ngIf="loggedInFeedbackForm.get('topic')?.touched && loggedInFeedbackForm.get('topic')?.invalid" id="feedbackForm-topic-error" class="error has-error help-block" for="feedbackForm-topic">This field is required</label>
            
        </div>
            <div class="clearboth"></div>
        <div class="feedback_message col-xs-12 no-padding margin-B10 has-feedback form-group" [ngClass]="{'has-error':loggedInFeedbackForm.get('message')?.touched && loggedInFeedbackForm.get('message')?.invalid}">
                <label class="registration_inputLabel" for="feedbackForm-message">
                    Message*
                </label>
                <textarea class="support-textarea form-control" placeholder='How can we help? Please provide your comments or questions here. Note the 5,000 character limit.' maxlength='5000' minlength='0' id="feedbackForm-message" formControlName="message" name = "message" autocomplete="off"></textarea>
                <div class="pull-right"><span class="character_used">{{charCount}}</span><span>/5000</span></div>
                <span *ngIf="loggedInFeedbackForm.get('message')?.touched && loggedInFeedbackForm.get('message')?.invalid" class='glyphicon glyphicon-exclamation-sign form-control-feedback'></span>
                <label *ngIf="loggedInFeedbackForm.get('message')?.touched && loggedInFeedbackForm.get('message')?.invalid" id="feedbackForm-message-error" class="error has-error help-block" for="feedbackForm-message">This field is required</label>
            
        </div>
            <div class="clearboth"></div>
            <div class="feedback_drag-form col-xs-12">
                <div class="feedback-drag-area col-xs-12" id="feedback-drag-area" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">                                       
                    <div class="dragiconsection">
                        <img  alt="" class="" src="assets/images/cloud-upload.svg" >
                        <p class="d-none d-md-block d-lg-block">Drag and drop files here</p>
                        <p class="d-md-none d-lg-none">Upload Files</p>
                    </div>
                    <p class="d-none d-md-block d-lg-block">or</p>
                    <input type="file" formControlName="contactUsFiles" class="fileElemInp" id="fileElem-excel" (change)="onFileChange($event)" accept="image/jpeg, image/png, application/vnd.openxmlformats-officedocument.wordprocessingml.document,  .eml, application/pdf, .msg" [multiple]="true"/>
                        
                    <input type="hidden" 
                    name="${_csrf.parameterName}" 
                    value="${_csrf.token}"/>	
                    <label class="button" for="fileElem-excel">Browse files</label>
                </div> 
                <div class="feedback-file_list col-xs-12">
                    <ng-container *ngFor="let file of filesToUpload;let i= index">
                    <div class="file-container file-container-797" [ngClass]="errors[i]?'error':''">
                        <div class="file-name"><i class="fa-regular fa-file"></i>&nbsp;<span>{{file.name}}</span></div>
                        <div *ngIf="!errors[i]" class="file-info" data-file-size="file.size">{{(file.size/1024/1024).toFixed(2)}}MB</div>
                        <div *ngIf="errors[i]" class="error"><i class="fa fa-exclamation-circle"></i>File format incorrect</div>
                        <div *ngIf="isUploading" class="uploadingbar">Uploading</div>
                        <span class="file-remove" (click)="removeFile(i)"><img alt="" src="/assets/images/trashblackicon.png" width="18"></span>
                    </div>
                    </ng-container>
                </div>
                <div class="feedback-file_error col-xs-12 no-padding">
                    <div class="feedback-file_erroMessage" *ngIf="loggedInFeedbackForm.get('contactUsFiles')?.errors?.['maxFileSize'] || maxFileSizeError"><i class="fa fa-exclamation-circle"></i>Total max file size exceeds 5 MB limit. Please remove a file.</div>
                    <div class="feedback-file_erroMessage1" *ngIf="loggedInFeedbackForm.get('contactUsFiles')?.errors?.['maxFiles'] || maxNumFilesError"><i class="fa fa-exclamation-circle"></i>Total file upload exceeds 5 files. Please remove a file.</div>
                </div>
            </div>
        
        
        
        <div class="col-xs-12 no-padding margin-B20 feedback-upload-format">
            <div>{{formContentData.filetype}}</div>
            <div>
                <div>{{formContentData.size}}</div>
                <div>{{formContentData.sizeupload}}</div>
            </div>
        </div>
        <div class="clearboth"></div>
        <div class="Support_flu360Content boxContent support_checkParent">
            <label class="registration_checkboxLabel" [innerHTML]="formContentData.h4content"></label>
        </div>
        
         <div class="col-xs-12 no-padding captcha-exclamation has-feedback" [ngClass]="{'has-error':loggedInFeedbackForm.get('recaptcha')?.touched && loggedInFeedbackForm.get('recaptcha')?.invalid}">
        <!-- <div class="form_field-elements control-group js-recaptcha-captchaaddon"></div>
        <input type="hidden" id="recaptchaChallangeAnswered" name="hiddenRecaptcha" value="${fn:escapeXml(requestScope.recaptchaChallangeAnswered)}" />
         -->
         <re-captcha
         (resolved)="resolved($event)"
         siteKey="6LeioWgqAAAAALSXsbShExRptpbBSgQc4ZFDIH80"
         formControlName="recaptcha"
       ></re-captcha>
        <span *ngIf="loggedInFeedbackForm.get('recaptcha')?.touched && loggedInFeedbackForm.get('recaptcha')?.hasError('required')" class="glyphicon glyphicon-exclamation-sign form-control-feedback"></span>
        <label *ngIf="loggedInFeedbackForm.get('recaptcha')?.touched && loggedInFeedbackForm.get('recaptcha')?.hasError('required')" id="recaptchaChallangeAnswered-error" class="error help-block captcha-help" for="recaptchaChallangeAnswered">This field is required</label>
        
        </div> 
        
        <button type="submit" class="support_submit active">Submit</button>
        <div class="clearboth"></div>
        </form>
    </div>
   
</div>
</ng-container>

<div id="feedbackThankyou_popup" class="modal fade in feedbackThankyou_popup" role="dialog">
    <div class="modal-dialog thankyou-dialog">        
        <div class="modal-content thankyou-content">
            <div class="modal-body">        
                <div class="col-xs-9 no-padding">
                    <h4 class="modal-title Feedbackthankyou-header col-xs-12">Thank you!</h4>
                </div>
                <div class="col-xs-3">
                    <span class="feedback-close feedback-cross" data-dismiss="modal" (click)="onclose()">&times;</span>
                </div>                                    
                <p class="CountrypopuSubheader col-xs-12">Your message has been submitted. Please expect to wait one business day for our Customer Service team to contact you.</p>        
                <div class="clearboth"></div>        
                <button class="feedback-close pull-right" data-dismiss="modal" (click)="onclose()">Close</button>
                    <div class="clearboth"></div>
            </div>                
        </div>
    </div>
</div>

