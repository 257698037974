import { Component } from '@angular/core';

@Component({
  selector: 'app-custombreadcrumb',
  templateUrl: './custombreadcrumb.component.html',
  styleUrl: './custombreadcrumb.component.scss'
})
export class CustombreadcrumbComponent {

}
