import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService, CmsService, OccEndpointsService } from '@spartacus/core';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CommonApiService } from '../../spartacus/services/common-api.service';

@Component({
  selector: 'app-popupdashboard',
  templateUrl: './popupdashboard.component.html',
  styleUrl: './popupdashboard.component.scss'
})
export class PopupdashboardComponent {
  popupsData: any;
  creditBlockModal: boolean=false;
  showConsentPopup: boolean = false;
  creditPopUpData: any="";
  ukConsentPopupData: any="";
  marketingEmail: any;
  showApiFailurePopup: boolean=false;
  apiFailurePopupData: any="";
  showReturnsSecPopup: boolean=false;
  returnsSecPopupData: any="";
  returnsFirstPopupData: any="";
  showReturnsFirstPopup: boolean=false;

  constructor(private router: Router,private baseSiteService:BaseSiteService,private component: CmsService,private userInfoService: UserInfoService,
    private occEndpointsService:OccEndpointsService,private commonService:CommonApiService,private cdr:ChangeDetectorRef) {
    
  }
  closeCreditPopUp(){
    this.creditBlockModal=false;
    
  }
 closeReturnsSecPopup(){
  this.showReturnsSecPopup=false;
 }
 closeReturnsFirstPopup(){
  this.showReturnsFirstPopup=false;
 }

  isConsentGranted(text:string){
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/my-profile/marketingconsent/${text}`);
    let payload: any = {
      url: endpoint,
    }
    this.commonService.postAPICall(payload).subscribe(res => {
      console.log(res);
      this.showConsentPopup = false;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(){
    this.userInfoService.dashboardOrderData$.subscribe((data) => {
      
      data.subscribe((dta:any) => {
        this.popupsData = dta;
        if(this.popupsData.creditBlockPopup == true){
          this.creditBlockModal = true;
          this.component.getComponentData('creditsPopup').subscribe((data)=>{
            this.creditPopUpData = data;
            this.cdr.detectChanges();
          });
          
        }
        if(this.popupsData.isConsentDisplay == false){
          this.showConsentPopup = true;
          this.marketingEmail = this.popupsData.marketingEmailId;
          this.component.getComponentData('ukConsentPopup').subscribe((data)=>{
            this.ukConsentPopupData = data;
            this.cdr.detectChanges();
          });
        }
        if(this.popupsData.apiFailurePopUpEnable == true){
          this.showApiFailurePopup = true;
          this.component.getComponentData('apiFailurePopup').subscribe((data)=>{
            this.apiFailurePopupData = data;
            this.cdr.detectChanges();
          });
        }
        if(this.popupsData.returnsFirstPopupShow == true){
          this.showReturnsFirstPopup = true;
          this.component.getComponentData('returnsPopupfirst').subscribe((data)=>{
            this.returnsFirstPopupData = data;
            this.cdr.detectChanges();
          });
        }
        if(this.popupsData.returnsSecondPopupShow == true){
          this.showReturnsSecPopup = true;
          this.component.getComponentData('returnsPopupSecond').subscribe((data)=>{
            this.returnsSecPopupData = data;
            this.cdr.detectChanges();
          });
        }
      })
    })
  }

}
