import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recent-order',
  templateUrl: './recent-order.component.html',
  styleUrl: './recent-order.component.scss'
})
export class RecentOrderComponent implements OnInit {
  getdata:Observable<any> = this.userInfoService.getOrdersDashboard();
     constructor(private userInfoService: UserInfoService) {}
    ngOnInit(): void {}
 
}
