import { Component } from '@angular/core';

@Component({
  selector: 'app-helpsection',
  templateUrl: './helpsection.component.html',
  styleUrl: './helpsection.component.scss'
})
export class HelpsectionComponent {

}
