import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../spartacus/services/content.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage-solution',
  templateUrl: './homepage-solution.component.html',
  styleUrl: './homepage-solution.component.scss'
})
export class HomepageSolutionComponent {

  banners:any[]=[];
  h2content:string="";
  paracontent:string="";
  safeh2content:SafeHtml | undefined;

  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig) {
    
  }
  getMediaUrl(media:any):string{
    return this.config.backend?.occ?.baseUrl + media.url;
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      this.h2content = data.h2content;
      this.paracontent = data.paragraphcontent;

      this.safeh2content = this.sanitizer.bypassSecurityTrustHtml(this.h2content);
    
      const bannerIds = data.banners.split(' ');
      bannerIds.forEach((bannerId: any)=> {
        this.contentService.getNavigation(bannerId).subscribe((data:any) => {
          
          this.banners.push(data);
          this.cdr.detectChanges();
        });
      })
      
    }
  );
}
  }



