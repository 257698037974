<section class="col-xs-12" id="home_solutionssection">
    <div [innerHTML]="safeh2content"></div>
    <div id="home_solutiontabs">
         <ng-container *ngFor="let banner of banners;let i=index">
             <div class="home_tabs col-xs-12 col-md-4">
                <div class="home_tabsimg" id="home_tabimg{{i}}"
                    style="background-image: url('{{ getMediaUrl(banner.media) }}');"></div>
                <div class="home_tabsheader" id="home_tabheader{{i}}">{{banner.h2content}}</div>
                <div class="home_tabscontent">{{banner.paragraphcontent}}</div>
            </div>
        </ng-container>
        
    </div>
    
    <div class="col-xs-12 global_greybtncontainer" [innerHTML]="paracontent">
    </div>
</section>
