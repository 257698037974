import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsBannerComponent, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-left-panel',
  templateUrl: './custom-left-panel.component.html',
  styleUrl: './custom-left-panel.component.scss'
})
export class CustomLeftPanelComponent {

  pageLabel: string="";
  leftNode:any;

  constructor(private bannerComponent: CmsService,private route:ActivatedRoute) {
 
  }

  
  
  //leftNode: Observable<any> = this.component.data$;

  ngOnInit(){
    this.bannerComponent.getCurrentPage().subscribe((data)=>{
      //console.log(data.pageId);
      this.pageLabel = data.pageId?data.pageId:"";
      
    })
    this.loadLeftData(this.pageLabel);
    
  }
  loadLeftData(pageLabel: string) {
    if(this.pageLabel === 'changepassword'){
      this.bannerComponent.getComponentData('ChangePasswordBanner_US').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }
    if(this.pageLabel === 'changepasswordsuccess'){
      this.bannerComponent.getComponentData('ChangePasswordSuccessBanner_US').subscribe((data)=>{
        this.leftNode = data;
      });
    
    }
    if(this.pageLabel === 'forgotpassword'){
      this.bannerComponent.getComponentData('ForgotPasswordBanner').subscribe((data)=>{
        this.leftNode = data;
      });
    }
    if(this.pageLabel === 'resetpassword'){
      this.bannerComponent.getComponentData('ResetPasswordBanner_US').subscribe((data)=>{
        this.leftNode = data;
      });
    }
    else{
    this.bannerComponent.getComponentData('RegistrationBanner').subscribe((data)=>{
      this.leftNode = data;
    });
  }
}
  
 
}
