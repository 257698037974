<div id="influenza-hero" *ngIf="object1 | async as item" class="row-flex">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <img class="influenza-hero-image" alt="Flu vaccination in older adult patient" src="/assets/images/influenza-vaccine-65-hero.png">
        </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 influenza-callout-desktop">
        <div class="influenza-hero-container">
            <img class="rectangle-down" alt="angled corner" src="/assets/images/rectangle-down.svg">
                <p class="callout" [innerHTML]="item.headline"></p>
                <img class="rectangle-up" alt="angled corner" src="../../../app/../assets/images/rectangle-up.svg">
                    <p class="small-callout"[innerHTML]="item.h2content"></p>
            </div>
    </div>
</div>
