import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-homepage-uk-pislot',
  templateUrl: './homepage-uk-pislot.component.html',
  styleUrl: './homepage-uk-pislot.component.scss'
})
export class HomepageUKPISlotComponent {
  paracontent:string="";
  urllink:string="";
  constructor(public component: CmsComponentData<any>,protected contentService:ContentService,private cdr:ChangeDetectorRef,private sanitizer:DomSanitizer,protected config: OccConfig) {  
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      
      this.paracontent = data.name;
      this.urllink = data.url;
      console.log(this.urllink);

    })
  }

}
