import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { CmsNavigationComponent, CmsLinkComponent, AuthService, User, UserIdService, CmsBannerComponent, OccConfig} from '@spartacus/core';
import { Observable, Subscription, map } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrl: './top-header.component.scss'
})

  export class TopHeaderComponent {
    toggleListView:boolean=false;
    node$: Observable<NavigationNode | null | undefined | any> = this.service.createNavigation(
      this.componentData?.data$
    );
    isCartExist: Observable<any> = this.userInfoService.checkCartExist();
    styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
      map((d) => d?.styleClass)
    );
  navigation: any=[];
  mobnav:any=[];

  iconClass:string="fa fa-bars";
  isOpen: boolean=false;
  afluriaurl: any;
  fluadurl: any;
  flucelvaxurl: any;
  isUserLoggedIn: boolean=false;
  userInfo: any;
  glyphiconIcon = "glyphicon-menu-down";   
  iconClassMob: string="fa fa-bars";
  subscription:Subscription[]=[];
    constructor(
      public userInfoService:UserInfoService,public component: CmsComponentData<CmsBannerComponent>,
      private cdr:ChangeDetectorRef,
      protected componentData: CmsComponentData<CmsNavigationComponent>,
      protected service: NavigationService, protected _mobmenu:ContentService,
      protected config: OccConfig
    ) {
    }
    afluriaPdf:Observable<any>  = this._mobmenu.getNavigation("AfluriaHeaderLink");
    
    fluadPdf:Observable<any> = this._mobmenu.getNavigation("FluadHeaderLink");
    
    flucelvaxPdf:Observable<any> = this._mobmenu.getNavigation("FlucelvaxHeaderLink");
  
    openList(){
      this.toggleListView=!this.toggleListView;
      
    }
    toggleSlideLoggedInMob(){
      if(this.iconClassMob === 'fa fa-bars'){
        this.iconClassMob = 'fa fa-times';
        this.isOpen=true;
        this.userInfoService.setMobileHeader(true);
      }
      else{
        this.iconClassMob = 'fa fa-bars';
        this.isOpen=false;
        this.userInfoService.setMobileHeader(false);
      }
    }
    toggleSlide(){
      this._mobmenu.togglemabmenu=!this._mobmenu.togglemabmenu;
 
      const temp= <HTMLElement> document.getElementsByClassName("mob_exp_menu")[1];
  
      if(this._mobmenu.togglemabmenu){
        temp.style.display="block";
      }
      else{
        temp.style.display="none";
      }
      if(this.iconClass === 'fa fa-bars'){
        this.iconClass = 'fa fa-times';
        this.isOpen=true;
      }
      else{
        this.iconClass = 'fa fa-bars';
        this.isOpen=false;
      }
    }
    setDifferentTopHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("TopHeaderNavSlot")[0];
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
          temp.style.display='none';
      }
      else if((!this.isUserLoggedIn) || ((this.isUserLoggedIn) && (!this.userInfo.webEnabled))){
        temp.style.display='block';
        }
      }
    }
    setDifferentBottomHeader(){
      const temp= <HTMLElement> document.getElementsByClassName("BottomHeaderSlot")[1];
      if(temp){
      if(this.isUserLoggedIn && this.userInfo.webEnabled){
          temp.style.display='none';
      }
      else if((!this.isUserLoggedIn) || ((this.isUserLoggedIn) && (!this.userInfo.webEnabled))){
        temp.style.display='none';
        }
      }
    }
    ngOnInit(){
      this.userInfoService.userLoggedIn$.subscribe(res=>{
        this.isUserLoggedIn=res;
        if(this.isUserLoggedIn){
          this.userInfoService.getUserData().subscribe(res=>{
            if(res){
            this.userInfo=res;
            this.setDifferentTopHeader();
            this.setDifferentBottomHeader();
            }
          })
        }
        else{
        this.setDifferentTopHeader();
        this.cdr.detectChanges();
        }
      })
      
      this.node$.subscribe((res:any)=>{
        if(res){
          if(res.title=='For US Healthcare Professionals Only'){
            let a=res.children[0].children.find((x:any)=>x.title==='For US Healthcare Professionals Only');
            let b=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            b.children[0].url = this.fluadurl;
            b.children[1].url = this.flucelvaxurl;
            b.children[2].url = this.afluriaurl;
            
            this.navigation=res.children[0].children;
            this.navigation.splice(this.navigation.findIndex((x:any)=>x.title==='For US Healthcare Professionals Only') , 1);
            this.navigation.unshift(a)
          }
          if(res.title=='Main Navigation Component'){
            let a=res.children[0].children.find((x:any)=>x.title==='Prescribing Information');
            a.children[0].url = this.fluadurl;
            a.children[1].url = this.flucelvaxurl;
            a.children[2].url = this.afluriaurl;
            this.mobnav = res.children[0].children;
            
          }
        }
      })

    this.afluriaPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
        this.afluriaurl = res;
    });
    this.fluadPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.fluadurl = res;
    });
    this.flucelvaxPdf.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.flucelvaxurl = res;
    });
    }
    getImage(image: any): string {
      return this.config.backend?.occ?.baseUrl + image.url;
    }
    ngOnDestroy(){
      // this.subscription.forEach(sub=>{
      //   sub.unsubscribe();
      // })
    }
  }