import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { CommonApiService } from '../../spartacus/services/common-api.service';
import { OccEndpointsService } from '@spartacus/core';
import { ContentService } from '../../spartacus/services/content.service';
import { Router } from '@angular/router';

declare var window:any;

export interface FeedbackFormData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber:number;
  phoneExt:number;
  
} 

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrl: './contact-us-form.component.scss'
})
export class ContactUsFormComponent {
  loggedInFeedbackForm: FormGroup;

  emailPattern = new RegExp("^(?=(.{1,64}@.{1,255}))([!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{1,64}(\\.[!#$%&'*+\\-\\/=?\\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\)|([a-zA-Z0-9-]{1,63}(\\.[a-zA-Z0-9-]{2,63}){1,}))$");
  
  //topics=['Billing & Payments','Delivery Issue','My Account','Orders & Shipping','Pricing','Product Information','Product Stability','Returns','Sales Rep Request','Serialization','Other']
  topics:string[]=[];
  acceptedFiles =['image/jpeg', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',  '.eml', 'application/pdf', '.msg','msg'];
  filesToUpload: File[]=[];

  maxFiles: number=5;
  maxFileSize=5*1024*1024;
  maxNumFilesError: boolean=false;
  isUploading: boolean=false;
 
  maxFileSizeError: boolean=false;
  isValid: boolean = false;
  thankYouModal: any;
  isLoading: boolean = false;
  acceptedFile: boolean = false;
  charCount: any=0;

  constructor(public component: CmsComponentData<any>, private fb:FormBuilder,private commonService:CommonApiService,private occEndpointsService: OccEndpointsService,
    private cdr:ChangeDetectorRef,private contentService:ContentService,private router:Router){
    this.loggedInFeedbackForm = this.fb.group({
      firstName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      lastName:['',[Validators.required,Validators.pattern('^[a-zA-Z]+$')]],
      email:['',[Validators.required,Validators.email]],
      phnNumber:['',[Validators.required,Validators.pattern('^(\\([2-9]([02-9]\\d|1[02-9])\\)|[2-9]([02-9]\\d|1[02-9])) ?[2-9]\\d{2}-?\\d{4}$'),Validators.minLength(10)]],
      phnExt:['',[Validators.pattern('^[0-9]*$'),Validators.maxLength(4)]],
      topic:['',[Validators.required]],
      message:['',[Validators.required,Validators.maxLength(5000)]],
      contactUsFiles:[[],[this.filesValidator.bind(this)]],
      recaptcha:['',[Validators.required]]
    });
    
  }
  formContent: Observable<any> = this.component.data$;

  filesValidator(control:any){
    const files: any=[];
    files.push(control.value);

    let totalSize =0;
    if(files && files.length > this.maxFiles){
      return {maxFiles :true};
    }
    for(const file of files){
      totalSize += file.size; 
    }
    if(totalSize > this.maxFileSize){
      return {maxFileSize:true};
    }
    return null;
  }

  ngOnInit(){
    this.formContent.subscribe(data => {
      
      this.topics = data.content.split(',');
    });
    this.getCustomerDetail();
    this.loggedInFeedbackForm.get('message')?.valueChanges.subscribe(value => {
      this.charCount = value.length;
    })
    this.thankYouModal = new window.bootstrap.Modal(document.getElementById('feedbackThankyou_popup'));
  }
  getCustomerDetail() {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/contactUserData`);
    let payload = {
      url: endpoint,
    }
    this.commonService.getAPICall(payload).subscribe({
      next: (res: FeedbackFormData) => {
        if (res) {
          this.patchFeedbackForm(res);
          this.cdr.detectChanges();
        }
      },
      error: (e) => {
      },
    })
  }
  patchFeedbackForm(data: FeedbackFormData) {
    this.loggedInFeedbackForm.patchValue({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.emailAddress,
      phnNumber:data.phoneNumber,
      phnExt:data.phoneExt?data.phoneExt:''
  });
  }

  onFileChange(event:any){
    this.isUploading =true;
    const files = Array.from(event.target.files || []) as File[];
    this.isFileAccepted(files[0]?.name);
    this.addFiles(files);
  }
  addFiles(files:File[]){
    
    let totalSize = 0;
    for(const file of this.filesToUpload){
      totalSize += file.size; 
    }
    if(files.length + this.filesToUpload.length > this.maxFiles){
      this.maxNumFilesError = true;
      this.filesToUpload.push(...files);
      this.cdr.detectChanges();
    }
    let currTotal = totalSize;
    for(const f of files){
      currTotal += f.size;
    }
    if(currTotal > this.maxFileSize){
      this.maxFileSizeError =true;
      this.filesToUpload.push(...files);
      this.cdr.detectChanges();
      
    }
    
    else{
      this.filesToUpload.push(...files);
      this.isUploading =false;
      //this.loggedInFeedbackForm.get('contactUsFiles')?.patchValue([...this.filesToUpload]);
      //this.loggedInFeedbackForm.get('contactUsFiles')?.updateValueAndValidity();
    }
    
  
  }

  onDragOver(event:DragEvent){
    event.preventDefault();
  }
  onDragLeave(event:DragEvent){
    event.preventDefault();
  }
  onDrop(event:DragEvent){
    event.preventDefault();
    if(event.dataTransfer?.files){
      const files = Array.from(event.dataTransfer.files) as File[];
      this.addFiles(files);
    }
  }
  removeFile(index:number){
    this.filesToUpload.splice(index,1);
    this.maxNumFilesError=false;
    this.maxFileSizeError=false;
    this.isUploading=false;
    /* this.loggedInFeedbackForm.patchValue({
      contactUsFiles:[...this.filesToUpload]
    }); */
    let totalSize=0;
    for(const file of this.filesToUpload){
      totalSize += file.size; 
    }
    if(this.filesToUpload.length > this.maxFiles){
      this.maxNumFilesError = true;
      this.isUploading=true;
      this.cdr.detectChanges();
    }
    if(totalSize > this.maxFileSize){
      this.maxFileSizeError =true;
      this.isUploading=true;
      this.cdr.detectChanges();
    }
   // this.loggedInFeedbackForm.get('contactUsFiles')?.updateValueAndValidity();
    
  }
  submitFeedbackForm(){
    
    this.loggedInFeedbackForm.markAllAsTouched();
    this.loggedInFeedbackForm.updateValueAndValidity();

    if(this.isValid && this.loggedInFeedbackForm.valid){
      this.isLoading=true;
      this.cdr.detectChanges();
      
      const formData = new FormData();
      this.filesToUpload.forEach((file,index) => {
        formData.append('contactUsFiles',file,file.name);
      })
      formData.append('firstName',this.loggedInFeedbackForm.get('firstName')?.value);
      formData.append('lastName',this.loggedInFeedbackForm.get('lastName')?.value);
      formData.append('emailAddress',this.loggedInFeedbackForm.get('email')?.value);
      formData.append('phoneNumber',this.loggedInFeedbackForm.get('phnNumber')?.value);
      formData.append('phoneExt',this.loggedInFeedbackForm.get('phnExt')?.value);
      formData.append('topic',this.loggedInFeedbackForm.get('topic')?.value);
      formData.append('message',this.loggedInFeedbackForm.get('message')?.value);

     
      this.commonService.postAPICall(this.getRequestData(formData)).subscribe((data)=>{
        console.log(data);
        this.isLoading=false;
        this.cdr.detectChanges();
        this.thankYouModal.show();
        
      });
      
      
    }
  }
  getRequestData(formData: FormData): any {
    let endpoint = this.occEndpointsService.buildUrl(`/users/${this.commonService.userId}/contactUsForm`);
    return {
      url:endpoint,
      contentType:'multipart/formData',
      data:formData
    }
  }
  //recaptcha check
  resolved(captchaResp:string | null){
    
    if(captchaResp){
      this.loggedInFeedbackForm.controls['recaptcha'].patchValue(captchaResp);
      this.loggedInFeedbackForm.updateValueAndValidity();

    this.contentService.verifyRecaptcha(captchaResp).subscribe((data) => {
      
      this.isValid=true;
      
    });
    }
  }

  onclose(){
    const url = (this.router.url).split('?')[0].split('#')[0];
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([url.substring(url.lastIndexOf('/')+1)]);
  }

  isFileAccepted(fileName:string){
    const fileExt = fileName.split('.').pop()?.toLowerCase();
    console.log(fileExt);
    if(this.acceptedFiles.includes(fileExt??'')){
      this.acceptedFile=true;
      this.cdr.detectChanges();
    }
    console.log(this.acceptedFile);
  }

}
