import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { Observable, of } from 'rxjs';
import { on } from '@ngrx/store';

@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrl: './order-overview.component.scss'
})
export class OrderOverviewComponent implements OnInit{
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService, private cd:ChangeDetectorRef) {}
 
  orderTable :Observable<any> =  this.userInfoService.getMyOrdersShipmentTable();
  displayedColumns: string[] = ['address', 'deliveredQty', 'orderNum'];
  displayedColumnsMobile : string[] =['combined']
  dataSource:any;
  isLoading!: boolean;
  ngOnInit(): void {
    this.isLoading = true;
    this.orderTable.subscribe(data=>{
      console.log(data);
      this.isLoading = false
      this.dataSource = data.shipmentData;
    })
    
  }
  // getProgress(deliveredQty:number,totalQty:number):Observable<number>{
  //   console.log(of(Math.floor((deliveredQty/totalQty)*100)))
  //   return of(Math.floor((deliveredQty/totalQty)*100))
  // }
  getProgress(deliveredQty:number,totalQty:number){
    //console.log(of(Math.floor((deliveredQty/totalQty)*100)))
    //this.cd.detectChanges()
    return (Math.floor((deliveredQty/totalQty)*100))
    
  }
  viewOrder(){
    
  }
}
