import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
@Component({
  selector: 'app-news-announcement-component',
  templateUrl: './news-announcement-component.component.html',
  styleUrl: './news-announcement-component.component.scss'
})
export class NewsAnnouncementComponentComponent {
  largeparagraphcontent:string="";
  headline:any;
  startOrder:any;
  contents:any[]=[];
  constructor(public component: CmsComponentData<any>) {
    
  }

  ngOnInit(){
    
    this.component.data$.subscribe((data) =>{
      this.largeparagraphcontent = data.largeparagraphcontent;
      this.headline = data.headline;
      this.startOrder = data.content;
    })
  }

}
