import { Component,AfterViewInit,OnInit,ViewChild, ChangeDetectorRef, ElementRef, HostListener, Renderer2, ComponentFactoryResolver } from '@angular/core';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { UserInfoService } from '../../spartacus/services/user-info.service';
import { CustomfilterPipe } from '../../cms-common/Pipes/customfilter.pipe';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsService, OccEndpointsService } from '@spartacus/core';
export interface PeriodicElement1 {
  loc: string;
  ordernum: number;
  prodname: string;
  season:string;
  status:string;
  unit:number;

}
@Component({
  selector: 'app-all-invoices-left-section',
  templateUrl: './all-invoices-left-section.component.html',
  styleUrl: './all-invoices-left-section.component.scss'
})

export class AllInvoicesLeftSectionComponent implements AfterViewInit {
 
  @HostListener('document:click', ['$event']) 
  onMessage(event: MessageEvent)
   { 
    if (event.data === 'iframeClicked') {
       console.log('Iframe was clicked!'); 
     } } 

  togglefilterView:boolean=false;
  togglesortView:boolean=false;

  displayedColumns: string[] = ['combined'];
 //dataSource = new MatTableDataSource<any>();
 dataSource:any=[];
 dataSource1= new MatTableDataSource<any>();
 displayedColumns1: string[] = ['fullProductName','totalOrderdQty','totalInvoiceAmount','totalShippedQty','latestStatus']; 
displayproduct:string[]=['quantity','shipmentStatus'];
 dataSourceWithPageSize = new MatTableDataSource<PeriodicElement1>;
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort)
sort!: MatSort;
currentDivCredit = false;
currentDivAch = false;
showCardSection = false
  iframeUrl: any;
  iframredirect = false;
  creditsSection: any;
  availableCreditsSection: any;
  dataSource2 = new MatTableDataSource<any>();
  creditOnToal :any= [];
  displayedColumns2: string[] = ['billTo', 'invoiceDate', 'invoiceNumber','formattedAmountWithTax','status'];
  showApply = true;
  showRemove = false;
 
  substractCredit= false;
  totalAfterCredits:number = 0;
  finalCreditList: any;
  topRemoveClicked = false;
  errorCode: any;
  showSuccessOrError: boolean = false;
  rightisLoading: boolean =false;
  showSuccessBanner: boolean =false;
  payMessage: any;
  payErrorMsg1: any;
  payErrorMsg2: any;
  payErrorMsg3: any;
  disableNext: boolean = false;
  isNegative: boolean = false;
  checkOutId: any;
  isCancel: any;
  subjectStatus: boolean =false;
filteropen(){
  this.togglefilterView=!this.togglefilterView;
  this.togglesortView=false;
}
sortopen(){
  this.togglefilterView=false;
  this.togglesortView=!this.togglesortView;
  
} 

iclicked(){
  console.log('iframeclickedinside')
  console.log(document.getElementById('iframeHolder'));
  
}
  // @ViewChild('paymentIframe')
  // iframe!: ElementRef;
dropdownValue:any;
getfilterdata:any;
apiresponse:any;
rightsectionresponse:any;
savedCardsData:any
count=0;
getstatus:any
selectedproducts:any[]=[];
selectedstatus:any[]=[];
isLoading = false;
showingLabel: string = "Showing";
  pageSize: number = 6;
  pageSizeControl = new FormControl(6);
  totalLength = 0;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 15];
firstvalue:string='';
filterValues = {
  search: '',
  filterbyproduct:[],
  filterbystatus:[''],

};

   constructor(private cmsService: CmsService,private userInfoService: UserInfoService,private cd:ChangeDetectorRef, private sanitizer:DomSanitizer, private renderer: Renderer2, private occ: OccEndpointsService,) {}
   

applyFilterNew(event: Event){
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource2.filter = filterValue.trim().toLowerCase();

}
//getStatus(){
  // if(localStorage.getItem('isCancelled') == 'true'){
  //   this.iframredirect = false;
   
  // }
  // this.cd.detectChanges();
 // this.isCancel = localStorage.getItem('isCancelled')
  //this.cd.detectChanges();
  //return this.isCancel;

//}
getStatus(){
 
  this.userInfoService.isCancelled$.subscribe(res=>{
   // this.iframredirect = false;
   this.subjectStatus =res;
   if(this.subjectStatus == true){
    this.iframredirect = false;
    this.cd.detectChanges()
   }
   })
   return this.iframredirect;
}
  ngOnInit(): void {
   // setCancelStatus
   
     this.userInfoService.isCancelled$.subscribe(res=>{
      this.iframredirect = false;
     })
    // this.userInfoService.getCancelStatus().subscribe(res=>{
    //   this.iframredirect = false;
    // })
    this.cd.detectChanges()
    // this.isCancel = localStorage.getItem('isCancelled')
    // localStorage.removeItem('isCancelled');
    this.isLoading = true;
   // console.log(this.occ.buildUrl('/payment-status'))
    this.cmsService.getComponentData('SeqirusAllInvoiceMessageComponent').subscribe(data => {
     // console.log('banner', data);
      this.payMessage = data
   })
   this.cmsService.getComponentData('SeqirusInvoicePaymentError1').subscribe(data => {
    //console.log('banner', data);
    this.payErrorMsg1 = data
 })
 this.cmsService.getComponentData('SeqirusInvoicePaymentError2').subscribe(data => {
  //console.log('banner', data);
  this.payErrorMsg2 = data
})
this.cmsService.getComponentData('SeqirusInvoicePaymentError3').subscribe(data => {
  //console.log('banner', data);
  this.payErrorMsg3 = data;
 // console.log('banner', this.payErrorMsg3);
})
   //SeqirusInvoicePaymentError1

   this.getstatus=[ "OPEN", "DUESOON", "OVERDUE", "PAID" ]
    this.userInfoService.getDropdownValue().subscribe(value => {
      this.dropdownValue = value;
      console.log(this.dropdownValue)
   
    this.userInfoService.getAllInvoices(this.dropdownValue).subscribe(value=>{
        this.apiresponse=value.invoiceList.invoices;
        this.userInfoService.setInvoiceCount(this.apiresponse.length)
        this.dataSource = new MatTableDataSource(this.apiresponse);
        this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
        console.log(this.dataSourceWithPageSize);
        this.isLoading = false;
        this.cd.detectChanges();
        this.dataSource.paginator = this.paginator;
        console.log(this.dataSource.paginator);
        this.dataSource.sort = this.sort; 
        this.totalLength = this.apiresponse.length;
        this.updateTableData()
        this.firstvalue=this.apiresponse[0].invoiceNumber;
        
        console.log(this.firstvalue)
        if(this.firstvalue && this.firstvalue != 'N/A'){
        this.openrightsectio(this.firstvalue,this.apiresponse[0].status);
        }
    console.log(this.apiresponse)
   }); 
  });
  // this.apiresponse=this.invoiceData.invoices;
  //       this.dataSource = new MatTableDataSource(this.apiresponse);
  //       this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
  //       console.log(this.dataSourceWithPageSize);
  //       this.isLoading = false;
  //       this.cd.detectChanges();
  //       this.dataSource.paginator = this.paginator;
  //       console.log(this.dataSource.paginator);
  //       this.dataSource.sort = this.sort; 
  //       this.totalLength = this.apiresponse.length;
  //       this.updateTableData()
  //       this.firstvalue=this.apiresponse[0].ordernum;
  //       if(this.firstvalue){
  //       this.openrightsectio(this.firstvalue);
  //       }
   
  }
  ngAfterViewInit(){
    
    console.log('ngafterview')
  }
  ngOnChanges(){
    console.log('on changes')
  }
ngDoCheck(){
  console.log('do check')
}
ngAfterContentInit(){
  console.log('after content')
}
ngAfterContentChecked(){
  console.log('content checked')
}

ngAfterViewChecked(){
  console.log('view checked')
}
ngOnDestroy(){
  console.log('on destroy')
}
  inputsearch(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
  
    this.filterValues.search =filterValue.trim().toLowerCase(); 
  }

  incrementcountstatus(event:any,i:number){
   
    if (event.target.checked) {

      this.count++;
      this.selectedstatus.push(event.target.value);
      this.filterValues.filterbystatus=this.selectedstatus;
     
    } else {
      var index = this.selectedstatus.indexOf(event.target.value);
      this.selectedstatus.splice(index, 1);
      this.filterValues.filterbystatus=this.selectedstatus;
     this.count--;
    } 
  } 
  sortTest(val:any){
    console.log(this.dataSource)
    console.log(val);
    let newdir :string = 'desc'
    if(val == '1to9' || val == '9to1'){
    const sortState: Sort = {active: 'invoiceNumber', direction: val == '1to9'?'asc': 'desc'};    
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    console.log(this.dataSource)
    }
    else if(val == 'AtoZ' || val == 'ZtoA'){
      const sortState: Sort = {active: 'location', direction: val == 'AtoZ'?'desc': 'asc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      console.log(this.dataSource)
    }
    else if(val == 'LeastRecent' || val == 'MostRecent'){
      const sortState: Sort = {active: 'invoiceDate', direction: val == 'LeastRecent'?'asc': 'desc'};    
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
      console.log(this.dataSource)
    }
  }
  applyFilter() {
     
     const filteredData1 = new CustomfilterPipe().transform(this.apiresponse, this.filterValues);
    this.dataSource = new MatTableDataSource(filteredData1);
    this.cd.detectChanges();
    this.dataSourceWithPageSize = new MatTableDataSource(this.apiresponse);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort; 
    this.totalLength = filteredData1.length;
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = filteredData1.slice(starti, endi); 
  }
  updateTableData() {
    const starti = (this.currentPage - 1) * this.pageSize;
    const endi = starti + this.pageSize;
    this.dataSource.data = this.apiresponse.slice(starti, endi);
  }
  onPageSizeChange(event: any) {
    this.pageSize = parseInt(event.target.value, 10);
    this.currentPage = 1;
    this.updateTableData();
  }
  goToLastPage() {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    this.currentPage = totalPages;
    this.updateTableData();
  } 
  scrollToTop(): void { 
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }
 
   goToFirstPage() {
    this.currentPage = 1;
    this.updateTableData();
  }
  
  getPages(): any {
    const totalPages = Math.ceil(this.totalLength / this.pageSize);
    const pages: any = [];
    const startPage = Math.max(1, this.currentPage - 1);
    const endPage = Math.min(totalPages, startPage + 2);
 
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
 
 
    }
    if (endPage < totalPages - 1) {
      pages.push('...');
    }
    if (endPage < totalPages) {
      pages.push(totalPages);
    }
    return pages;
  }
   goToPage(page: number) {
    this.currentPage = page;
    const starti = (page - 1) * this.pageSize;
    this.dataSource.data = this.apiresponse.slice(starti, starti + this.pageSize);
  } 
   previousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  } 
     nextPage() {
    const totlpages = Math.ceil(this.totalLength / this.pageSize);
    if (this.currentPage < totlpages) {
      this.goToPage(this.currentPage + 1);
    }
  } 
  isFirstPage(): boolean {
    return this.currentPage == 1;
  }
  isLastPage(): boolean {
    return this.currentPage == Math.ceil(this.totalLength / this.pageSize);
  }
  isCurrentPage(page: number): boolean {
    return this.currentPage == page;
  }
  ShowCredit(){
    this.currentDivAch = false;
    this.showCardSection = false;    
    this.showSuccessOrError = false;
    this.iframredirect = false;
    this.currentDivCredit = ! this.currentDivCredit;    
    
      //this.creditsSection = res.seqirusFinancialDashboardData.credit; totalcreditamount
      console.log(this.finalCreditList);
      this.dataSource2 =  new MatTableDataSource((this.finalCreditList));

      this.cd.detectChanges();
     
      console.log(this.availableCreditsSection);
      console.log(this.creditsSection);
    
  }
  ShowAch(){
    this.currentDivCredit = false;
    this.showCardSection = false;
    this.showSuccessOrError = false;
    this.iframredirect = false;
    this.currentDivAch = ! this.currentDivAch;

    
  }
  hideCredit(){
    this.currentDivCredit = false
  }
  showCards(){
    this.rightisLoading = true;
    console.log(this.currentDivCredit)
    console.log("next clicked api not done")
    this.userInfoService.fetchSavedCards().subscribe((data :any)=>{
      this.savedCardsData = data.cardsData;  
      this.showCardSection = true    
      this.iframredirect = false;
      this.currentDivCredit = false;  
      this.rightisLoading = false;
      this.cd.detectChanges();      
      console.log("next clicked api done")
      console.log(this.currentDivCredit)
     // this.rightisLoading = false;
      
    })
  
  }
  retryClicked(){
    this.showCardSection = true;
    this.showSuccessOrError = false;
  }
  cancelTransactionClicked(){
    this.showSuccessOrError = false;
    this.currentDivCredit = true;
  }
  savedCardClicked(data:any){
   
    this.rightisLoading = true;

    let obj1:any ={
      invoiceAmt : this.rightsectionresponse.total,
      invoiceNumber: this.rightsectionresponse.invoiceNumber,
      tokenId: data.token,
      poNumber: this.rightsectionresponse.poNumber,
      creditsAppliedFlag: "applied",
       appliedCredits: {               
         creditNotes: [...this.creditOnToal],
        paymentFlag: "",
        invoiceNumber: this.rightsectionresponse.invoiceNumber
      }      
    }
    let obj2:any ={
      invoiceAmt : this.rightsectionresponse.total,
      invoiceNumber: this.rightsectionresponse.invoiceNumber,
      tokenId: data.token,
      poNumber: this.rightsectionresponse.poNumber,      
      appliedCredits: {               
        creditNotes: [...this.creditOnToal],
       paymentFlag: "",
       invoiceNumber: this.rightsectionresponse.invoiceNumber
     }                
    
           
    }
    let FinalObj = this.creditOnToal.length >0? obj1 :obj2
    
   
    this.userInfoService.savedCardClicked(FinalObj).subscribe((res)=>{
    
       
        this.showCardSection = false;
        this.currentDivCredit = false;
        this.rightisLoading = false;
       this.cd.detectChanges();
       this.errorCode = res.errorCode;
       if(this.errorCode && this.errorCode == 200){
        this.showSuccessBanner = true;
        this.currentDivCredit =false;
       }
       this.showSuccessOrError = true;
       this.cd.detectChanges();
    })
    // {
    //   "invoiceAmt": "string",
    //   "invoiceNumber": "string",
    //   "tokenId": "string",
    //   "poNumber": "string",
    //   "creditsAppliedFlag": "string",
    //   "schemeTransactionId": "string",
    //   "appliedCredits": {
    //     "invoiceNumber": "string",
    //     "creditNotes": [
    //       {
    //         "creditNoteNumber": "string",
    //         "creditAmount": "string"
    //       }
    //     ],
    //     "paymentFlag": "string"
    //   }
    // }
  }
  iframClicked(){
    
    this.getIframeUrl()
    
  }

  getIframeUrl() {
    let obj:any ={
      invoiceAmt : this.rightsectionresponse.total,
      invoiceNumber: this.rightsectionresponse.invoiceNumber,     
      poNumber: this.rightsectionresponse.poNumber,
      creditsAppliedFlag: "string",
      //returnUrl :this.occ.buildUrl('/payment-status')
      //returnUrl :"http://local.flu360:4200/payment-status"
      returnUrl :"https://dev3.flu360.com/payment-status"

    }
    this.userInfoService.redirectIframeUrl(obj).subscribe(res=>{
      console.log(res);
      this.checkOutId = res.hostedCheckoutId;
      this.userInfoService.setcheckOutId(res.hostedCheckoutId)
      let obj1:any ={
        invoiceAmt : this.rightsectionresponse.total,
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        tokenId: res.hostedCheckoutId,
        poNumber: this.rightsectionresponse.poNumber,
        creditsAppliedFlag: "applied",
         appliedCredits: {               
           creditNotes: [...this.creditOnToal],
          paymentFlag: "",
          invoiceNumber: this.rightsectionresponse.invoiceNumber,
        }      
      }
      let obj2:any ={
        invoiceAmt : this.rightsectionresponse.total,
        invoiceNumber: this.rightsectionresponse.invoiceNumber,
        tokenId: res.hostedCheckoutId,
        poNumber: this.rightsectionresponse.poNumber,      
        appliedCredits: {               
          creditNotes: [...this.creditOnToal],
         paymentFlag: "",
         invoiceNumber: this.rightsectionresponse.invoiceNumber,
       }                
      
             
      }
      let FinalObj = this.creditOnToal.length >0? obj1 :obj2
      localStorage.setItem('iframeObj', JSON.stringify(FinalObj))
      //this.userInfoService.setPaymentObj(FinalObj)
      this.iframeUrl= res.partialRedirectUrl;
      this.iframredirect = true;
    this.cd.detectChanges();
    })

   
    // const iframeUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/terms-conditions-sales-2024-2025?fields=DEFAULT`);
    // this.http.get(iframeUrl,   { responseType: 'text' }).subscribe(data => {
    //   let url = this.occEndPointsService.buildUrl(`${data}`); //&embedded=true
    //   url = url.replace("occ/v2/seqirusb2b-flu360/","");
    //   this.iframeUrl.next(url);
    // })
  }
  cleanURL(): SafeResourceUrl {
    //this.iframredirect = true;
    //this.getIframeUrl();
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
  }
  

  downloadInvoice(invoicenum : string) {  
   this.rightisLoading = true;
   
    this.userInfoService.downloadInvoiceApi(invoicenum).
    subscribe((data: ArrayBuffer) => {
         
         // this.cd.detectChanges()
          // Create a Blob from the ArrayBuffer
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          //window.open(fileURL); // Open in a new tab
 
          const a = document.createElement('a');
          a.href = fileURL;
          a.target = '_blank';
          a.download = invoicenum;
          document.body.appendChild(a);
          a.click();
         // this.rightisLoading = false;
         this.rightisLoading = false;
         this.cd.detectChanges()
        },
       
        (error) => {
          console.log('getPDF error: ', error);
          this.rightisLoading = false;
          this.cd.detectChanges()
        }
        
      );
 
 
   
  }
  makeRemovetoggle(){
    this.topRemoveClicked = true;
  }
  getFinalCreditAmmount(){
    let newAmount = this.rightsectionresponse.total- this.getFullCredit();
    if(newAmount < 0){
      this.isNegative = true;
      //this.cd.detectChanges()
    }else if(newAmount >= 0){
      this.isNegative = false
      //this.cd.detectChanges()
    }
    console.log(newAmount < 0 ? newAmount * -1: newAmount )
    return this.rightsectionresponse.total- this.getFullCredit();
    //$(".invoice_creditnote-sumAmt").text().replace(/[$,]/g, '')
  }
  creditApplied(inv:Event, credit:number, toggleButton:string, creditNum:number, index:number){
    this.isNegative = false;
    //this.cd.detectChanges()
    const event = inv.target as HTMLElement;
    if(toggleButton == 'credit'){
    event.classList.add("creditApplyClicked");
    this.substractCredit = true;
    let obj={
      creditAmount : credit,
      creditNoteNumber : creditNum,
      id:index
      
    }
  if(this.rightsectionresponse.total < credit ){
    this.disableNext = true
  }
    this.creditOnToal.push(obj);
    console.log(this.creditOnToal,index); 

    // for(let i=0; i <= this.creditOnToal.length;i++ ){
    // this.totalAfterCredits = this.totalAfterCredits+ this.creditOnToal[i].creditAmount
    // }
    event.nextElementSibling?.classList.add("removeEnabled")
    //this.creditsSection.totalAvailableCredit = this.creditsSection.totalAvailableCredit-credit;
    //console.log(this.creditsSection.totalAvailableCredit);
    }
    else if(toggleButton == 'remove'){
      let newIndex = index
      //if(this.topRemoveClicked){
        this.disableNext = false;
    
      const removeId = document.getElementById('removeId-'+ index);
      
      removeId?.classList.remove("removeEnabled");
    //  if(clickedClass == 'topClicked'){
    //   let finalindex = this.creditOnToal.findIndex((item:any)=> item.id == index );
    //   newIndex = finalindex
    //   console.log(finalindex)
    //  }else if(clickedClass == 'tableClicked'){
    //   newIndex = index
    //  }
     
     let finalindex = this.creditOnToal.findIndex((item:any)=> item.id == index );
     
      this.creditOnToal.splice(finalindex,1);
      //console.log(this.creditOnToal,index);
      removeId?.previousElementSibling?.classList.remove("creditApplyClicked");
      
     // this.creditsSection.totalAvailableCredit = this.creditsSection.totalAvailableCredit+credit;
     //this.topRemoveClicked =false;
    }

  }
  // creditRemoved(inv:number, credit:number,i:number){
  //   this.creditsSection.totalAvailableCredit = this.creditsSection.totalAvailableCredit+credit;
  //   console.log(this.creditsSection.totalAvailableCredit);
  //   this.showRemove = true
  // }
  getFullCredit(){
   return this.creditOnToal.reduce((sum:any, el:any) => sum += el.creditAmount, 0)
  }
  openrightsectio(invoiceId:string, status:string){
    const temp= <HTMLElement>document.getElementsByClassName("activeorder")[0];
    if(temp){
    temp.classList.remove("activeorder");
    }
    const temp1= <HTMLElement> <unknown>document.getElementById(invoiceId);
    if(temp1){
    temp1.classList.add('activeorder');
    }
    this.rightisLoading = true;
    // if(invoiceId){
    //   invoiceId=invoiceId.split("#")[1];
   
    //   }
      //'9130226308'
      this.currentDivAch =false;
      this.currentDivCredit = false;
      this.showCardSection = false;
    this.userInfoService.AllInvoicesRightsection(this.dropdownValue, invoiceId,status ).subscribe(value=>{
      localStorage.removeItem('isCancelled');
      this.rightsectionresponse=value;
      this.finalCreditList = JSON.parse(this.rightsectionresponse.availablecreditsList)
      this.rightisLoading = false;
      console.log(this.rightsectionresponse);
      console.log(this.rightsectionresponse.totalcreditamount);
      
      
      this.dataSource1 = new MatTableDataSource(this.rightsectionresponse);
      this.cd.detectChanges();
      
     });
    
    // this.rightsectionresponse= this.rightSection.SeqirusInvoiceDetailsDTO; 
      
      
      
    //   this.dataSource1 = new MatTableDataSource(this.rightsectionresponse);
    //   this.cd.detectChanges();
      

  }
  expandedElement: any | null;

  toggleRow(element: any) {
    element.expanded = !element.expanded;
  //  this.expandedrowdata=element.shippedStatusTable;
    this.cd.detectChanges();
  }
  
}
