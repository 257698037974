import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class StartOrderService {
  constructor(private http: HttpClient,
    private occEndPointsService: OccEndpointsService) {
  }
  getOrderDetails() {
    const orderListUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/order?fields=DEFAULT`);
    return this.http.get(orderListUrl);
  }
  postMembershipDetails(payload: any) {
    const membershipUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/membership/submit?fields=DEFAULT`);
    return this.http.post(membershipUrl, payload, { responseType: 'text' });
  }
  addToCart(payload: any, seasonValue: string) {
    const addCartUrl = this.occEndPointsService.buildUrl(`/users/current/cart/addToCart?fields=DEFAULT&season=${seasonValue}`);
    return this.http.post(addCartUrl, payload, { responseType: 'text' })
  }
  getReviewPageData(seasonValue: string) {
    const reviewUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/order-review?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(reviewUrl)
  }
  deleteCartData(seasonValue: string) {
    const deleteUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/deleteCart?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(deleteUrl, { responseType: 'text' })
  }
  placeOrderAPI(poNumber: string, seasonValue: string) {
    const placeOrderUrl = this.occEndPointsService.buildUrl(`/users/current/checkout/confirmOrder?PORefNumber=${poNumber}&fields=DEFAULT&&season=${seasonValue}`);
    return this.http.get(placeOrderUrl, { responseType: 'text' });
  }
  getPORefNumber() {
    const poRefNumberUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/generatePO?fields=DEFAULT`);
    return this.http.get(poRefNumberUrl, { responseType: 'text' })
  }
  getIframeUrl() {
    const iframeUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/terms-conditions-sales-2024-2025?fields=DEFAULT`);
    return this.http.get(iframeUrl, { responseType: 'text' })
  }
  checkOrderExist(seasonValue: string) {
    const checkOrderUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/checkCartExistforSeason?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(checkOrderUrl, { responseType: 'text' });
  }
  getBulkOrderSeasonDocument(seasonValue: string) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/download-order-excel?fields=DEFAULT&season=${seasonValue}`);
    return this.http.get(bulkOrderUrl, { observe: 'response', responseType: 'arraybuffer' })
  }
  uploadFile(payload: any) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/bulkOrderUpload?fields=DEFAULT`)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'multipart/form-data');
    return this.http.post(bulkOrderUrl, payload, { headers, reportProgress: true, observe: "events", responseType: 'text' });
    // return this.http.post(bulkOrderUrl, payload);
  }
  submitFile(payload: any) {
    let bulkOrderUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/bulkordersubmit`)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'multipart/form-data');
    return this.http.post(bulkOrderUrl, payload, { headers, responseType: 'text' });
  }
  getAfluriaPrescribingInfoPdf() {
    let afluriaUrl = this.occEndPointsService.buildUrl(`/SeqirusPDF/AfluriaPrescribingInfoPDF?fields=DEFAULT`);
    return this.http.get(afluriaUrl, {responseType: 'text'});
  }
  preBookRollOver() {
    let rolloverUrl = this.occEndPointsService.buildUrl(`/users/current/Start-order/preBookRollOver?fields=DEFAULT`);
    return this.http.get(rolloverUrl, {responseType: 'text'});
  }
}
