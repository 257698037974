/* eslint-disable @typescript-eslint/no-explicit-any */
import { GigyaResponse } from './gigya-response';

export interface GetAccountInfoGigyaResponse extends GigyaResponse {
  data: GetAccountInfoGigyaAccount;
}

export interface GetAccountInfoGigyaAccount {
  UID: string;
  UIDSignature: string;
  signatureTimestamp: string;
  isVerified: boolean;
  isRegistered: boolean;
  isActive: boolean;
  lastLogin: number;
  lastUpdated: number;
  registered: number;
  socialProviders: string;
  loginIDs: GetAccountInfoGigyaLoginIDs;
  data: GetAccountInfoGigyaData;
  profile: GetAccountInfoGigyaProfile;
  subscriptions?: GetAccountGigyaSubscriptions;
}

export interface GetAccountInfoGigyaData {
  subscriptionSource: string;
  subscribe: boolean;
  terms: boolean;
  modificationSource: string;
  acquisitionSource: string;
  ecommerce: { profile: { title?: string; titleCode: string } };
}

export interface GetAccountInfoGigyaLoginIDs {
  emails: string[];
  unverifiedEmails: any[];
}

export interface GetAccountInfoGigyaProfile {
  address?: string;
  age?: number;
  birthDay?: number;
  birthMonth?: number;
  birthYear?: number;
  city?: string;
  countr?: string;
  email: string;
  firstName?: string;
  gender?: 'm' | 'f' | 'u';
  languages?: string;
  lastName?: string;
  locale?: string;
  phones?: [{ type?: string; number: string }];
  zip?: string;
}

export interface UpdateInfoGigyaSubscriptions {
  [k: string]: GetAccountGigyaSubscription;
}
export interface GetAccountGigyaSubscriptions {
  [prop: string]: GetAccountGigyaSubscription;
}

export interface GetAccountGigyaSubscription {
  email: {
    isSubscribed: boolean;
    lastUpdatedSubscriptionState?: string;
    tags?: any;
    doubleOptIn?: any;
  };
}
