import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-help-widget',
  templateUrl: './help-widget.component.html',
  styleUrl: './help-widget.component.scss'
})
export class HelpWidgetComponent  {
  constructor(public contentService: ContentService,protected config: OccConfig) {}
  helpNode: Observable<any> = this.contentService.getBannerNode();
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
}
