import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentComponent } from './payment/payment.component';
import { CmsConfig, ConfigModule } from '@spartacus/core';



@NgModule({
  declarations: [
    PaymentComponent
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        // SeqirusPaymentSectionComponent : {
        //   component: PaymentComponent
        // }
     
     
      }
    } as CmsConfig)
  ]
})
export class PaymentStatusModule { }
