<div class="product-3col container" *ngIf=" slideNode | async as item">
    <div class="product-3col__body">
         <input type="hidden" id="webEnabled" value="true">
        <input type="hidden" id="logincheck" value="">
        <div class="product-3col__item" *ngFor="let item2 of mergedObject1; let i= index">
            <img alt="Fluad Quadrivalent logo" [src]="getImage(item2.media)" id="prodFluad_img">
            <h3>{{item2.headline}}</h3>
            <p [innerHTML]="item2.content" class="sup_bold"></p>
            <a href="{{item2.urlLink}}" class="no-underline">{{item2.h2content}}</a>
        </div>
    </div>
    <div class="product-3col__footer">
        <p class="disclaimer--paragraph">{{item.bottomContent}}</p>
    </div>
</div>