import { NgModule } from '@angular/core';
import { ConfigModule, provideConfig } from '@spartacus/core';
import { LayoutConfig, defaultLayoutConfig } from '@spartacus/storefront';



@NgModule({
  declarations: [],
  imports: [
  ],

  providers:[
    provideConfig(
      <LayoutConfig>{
        ...defaultLayoutConfig,
        layoutSlots: {
       
          header:{
              lg:{
              slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot'],
                 },
				      sm:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 },
              xs:{
                  slots:['TopHeaderSlot','TopHeaderNavSlot','SiteLogo','BottomHeaderSlot','SideContent'],
                 }
          },
          LandingPage2Template:{
            slots:['AlertMessageSlot','homepageBanner','planningSection','solutionsSection','strengthenSection','featuredResourcesSection','BroughtybSection'],
          },
          AccountPageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent', 'MyOrdersSection-MyOrdersPage','LeftContent']
            },
            sm:{
              slots :['BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','MyOrdersSection-MyOrdersPage','LeftContent'],
            }
          },
          InvoicePageTemplate:{
            lg:{
              slots: ['SideContent','BodyContent','LeftContent']
            },
            sm:{
              slots :['BodyContent','LeftContent'],
            },
            xs:{
              slots :[ 'BodyContent','LeftContent'],
            }
          },
          PaymentStatusPageTemplate:{
            lg:{
            slots:['MiddleContent']
          },
          sm:{
            slots :['MiddleContent'],
          },
          xs:{
            slots :['MiddleContent'],
          }
          },
        LoginPageTemplate:{
          lg:{
            slots:['BodyContent']
          }
        },
        GigyaRegistrationPageTemplate:{
          // lg:{
            slots:['LeftContentSlot','BodyContent'],
          // }
        },
        GigyaProfileVerificationPageTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaChangePasswordSuccessTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaForgotPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        GigyaResetPasswordTemplate:{
          slots:['LeftContentSlot','BodyContent']
        },
        ProfileActivationPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot1','BodyContentSlot2','BodyContentSlot3',]
        },
        JoinAccountTemplate:{
          slots:['BodyContent']
        },
        VerificationLinkExpiredPageTemaplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        VerificationEmailSentPageTemplate:{
          slots:['LeftContentSlot','BodyContentSlot1']
        },
        RegistrationPageTemplate:{
          slots:['registerBodyContentSlot','RegisterThankyouContentSlot']
        },
        paymentInformationPageTemplate:{
           
          slots: ['SideContent','BodyContent','LeftContent']
        },
        CartPageTemplate: {
          lg:{
            slots: ['SideContent','CenterLeftContentSlot']
          },
          sm:{
            slots :['CenterLeftContentSlot'],
          },
          xs:{
            slots :['CenterLeftContentSlot'],
          }
        },
        footer:{
            lg:{
            slots:['Footer'],
            }, 
        }
         
      },
      }
    )
  ]
})
export class LayoutModule { }
