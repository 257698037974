import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent,CmsLinkComponent } from '@spartacus/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrl: './nav-header.component.scss'
})
export class NavHeaderComponent {

  constructor(public component2: CmsComponentData<CmsBannerComponent>,private headerService : ContentService) {}
  
  navheader: Observable<any> = this.component2.data$; 
  public logo1: Observable<any> = this.headerService.getimage(); 


  public logo2: Observable<any> = this.headerService.getimage1(); 

  // public nav: Observable<any> = this.headerService.getNavigation(); 

}
