<div class="container"*ngIf="object1 | async as item" >
    <div id="influenza-seasons-cards">
        <div class="row-flex m1-mobile">
            <div id="influnenza-complications-header" class="col-lg-12 col-md-12 col-sm-12 col-sm-12 text-center">
                <h2>
                        <span>{{item.headLine}}</span>
                        <div class="header-line"></div>
                    </h2>
                </div>
            <div class="col-lg-5 col-md-3 col-sm-12 col-xs-12">
                <div id="season-card" class="two-row--card season-card-left season-left-width pt-6">
                    <div class="two-row--card-content">
                        <div class="two-row--card-top season-left-width">
                            <p class="line-header">
                                <p class="card-callout">                    
                 <div class="seasons-two-row--card-bottom">
                            <!-- <p class="card-content centered-text">of <strong>adults 65+</strong> live with <strong class="text--red-100">multiple chronic health conditions</strong>, many of which are associated with increased risk of influenza-related complications.<sup>*3,4</sup></p> -->
                        <p class="card-content centered-text"[innerHTML]="item.content">
                        </p>
                       </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7 col-md-8 col-sm-12 col-xs-12">
                <div id="season-card-right" class="two-row--card bg--white">
                    <div class="two-row--card-content">
                        <div class="two-row--card-top">  
                            <p class="text--grey-110 card-header" [innerHtml]="item.text1content"></p>
                         </div>                       
                        <div class="two-row--card-bottom display--flex season-card-right-desktop">
                            <div class="mini-card bg--grey-0">
                                <div class="mini-card-1-container">
                            <p class="ash1" [innerHtml]="item.text2content"></p>
                            </div>
                            </div>
                            <div class="mini-card bg--grey-0">
                                <div class="mini-card-2-container">
                                    <!-- <p>A first or subsequent <br> <span>stroke</span>: </p><p class="callout">up to</p><p class="lg-text"><span>2-3 times</span></p><p>more likely<sup>5</sup></p></div> -->
                           <p class="ash1"[innerHTML]="item.text3content"></p>
                                </div>
                                </div>
                            <div class="mini-card bg--grey-0 text-left-align">
                                <div class="mini-card-3-container">
                                    <p class="one-block-text" [innerHtml]="item.text4content"></p>
                                        <!-- Developing <span>pneumonia and bronchitis</span>, the most common complications of influenza<sup>5,6</sup></p> -->
                                    </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 influenza-season-footnotes">
                <p class="ash"[innerHTML]="item.bottomContent">
                    </p>
                </div>
        </div>
    </div>
</div>