import { Component } from '@angular/core';

@Component({
  selector: 'app-financial-banner',
  templateUrl: './financial-banner.component.html',
  styleUrl: './financial-banner.component.scss'
})
export class FinancialBannerComponent {

}
