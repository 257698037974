import { ChangeDetectorRef, Component } from '@angular/core';
import { BaseSiteService, CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-banner-section-dashboard',
  templateUrl: './banner-section-dashboard.component.html',
  styleUrl: './banner-section-dashboard.component.scss'
})
export class BannerSectionDashboardComponent {
  currentSite: string | undefined;
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService,private baseSiteService:BaseSiteService) {
  }
  bannerNode: Observable<any> = this.component.data$;
  invoiceNode: Observable<any> = this.userInfoService.getInvoiceData();
  orderData:Observable<any> = this.userInfoService.getOrdersDashboard();
  userData:any;

  ngOnInit(){
    this.userInfoService.getUserData().subscribe(res=>{
      this.userData=res;
    });
    this.baseSiteService.getActive().subscribe(res => {
      this.currentSite = res;
    });
  }
}
