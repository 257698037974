import {
  GetAccountInfoGigyaData,
  GetAccountInfoGigyaProfile,
} from './get-account-gigya-response';
import { GigyaResponse } from './gigya-response';

export interface GigyaLoginResponse extends GigyaResponse {
  data: {
    UID: string;
    data: GetAccountInfoGigyaData;
    profile: GetAccountInfoGigyaProfile;
  };
}
