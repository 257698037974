import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hero-overview',
  templateUrl: './hero-overview.component.html',
  styleUrl: './hero-overview.component.scss'
})
export class HeroOverviewComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,protected config: OccConfig,){}
  bannerNode: Observable<any> = this.component.data$;
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
