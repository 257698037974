import { ChangeDetectorRef, Component ,OnInit} from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-all-invoices-banner-section',
  templateUrl: './all-invoices-banner-section.component.html',
  styleUrl: './all-invoices-banner-section.component.scss'
})
export class AllInvoicesBannerSectionComponent {
  totalCount: any;
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService,private cd:ChangeDetectorRef) {
  }
  bannerNode: Observable<any> = this.component.data$;
  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
  seasons$ = this.userInfoService.seasonValue$;
  invoiceCountValue$ = this.userInfoService.invoiceCount$
  data:any;
  ngOnInit():void{
    this.seasonData.subscribe((res:any)=>{
      this.userInfoService.sendDropdownValue(res.inSeason)
    })
    
  }
  selectedSeason(e :any){    
    this.userInfoService.sendDropdownValue((e.target as HTMLInputElement).value)
    console.log('event', (e.target as HTMLInputElement).value)
    this.cd.detectChanges()
  }
  getCount(){
    this.invoiceCountValue$.subscribe(res=>{
      this.totalCount = res
    })
    return this.totalCount == 1? 0: this.totalCount;
  }
}
