import { Component } from '@angular/core';
import { CmsBannerComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-left-banner',
  templateUrl: './left-banner.component.html',
  styleUrl: './left-banner.component.scss'
})
export class LeftBannerComponent {
  constructor(public bannerComponent: CmsComponentData<CmsBannerComponent>,protected config: OccConfig,) {

  }
  leftBannerNode: Observable<any> = this.bannerComponent.data$; 
  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.desktop.url;
  }
}
