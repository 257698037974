<section class="col-xs-12" id="opeffuiency-third">
    <div *ngIf=" thirdheadline | async  as item" class="col-xs-12 global_headerwithdash" id="home_dashleftpadding">
        <div class="global_dashheader">{{item.headline}}</div>
        <div class="global_line"></div>
    </div>
</section>
    <div class="container vaccine-portfolio-row">
            <div *ngFor="let item2 of mergedObject1; let i= index"
                class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 center-content">
                <div class="logo--card center-xs">
                    <div class="logo--card-container">
                        <div class="logo--card__logo-container">
                            <img class="hero--circlechart-chart" alt="image" [src]="getImage(item2.picture)">
                        </div>
                        <a href="{{item2.link}}" class="no-underline text-dark-gray cta f-sb">
                            Explore<img alt="Next screen"  src="/assets/images/arrow-right.svg">
                        </a>
                    </div>
                </div>
            </div>
        <div *ngIf=" thirdheadline | async  as item" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                <p class="disclaimer--paragraph">{{item.bottomContent}}</p>
           </div>  
    </div>

