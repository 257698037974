import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cartbanner',
  templateUrl: './cartbanner.component.html',
  styleUrl: './cartbanner.component.scss'
})
export class CartbannerComponent {
  constructor(public PageParagraphComponent : CmsComponentData<any>){}
  
  startcart: Observable<any> = this.PageParagraphComponent.data$;
}
