<section class="col-xs-12 global_createaccsection" id="home_flu360overview" *ngIf="leftBannerNode | async  as item" >
    <div  *ngIf="(item.uid === 'strengthen-banner-Component') || (item.uid === 'broughtyb-banner-Component')">
    <div class="simple-banner banner__component--responsive">
<img class="js-responsive-image" alt="Healthcare professional talking to older adult patient" title="Healthcare professional talking to older adult patient" style="" [src]="getImage(item.media)">
</div><div class="col-xs-12 home_topfirst">
        <div class="offset-lg-6 col-lg-6">
            <div [ngClass]="item.uid === 'strengthen-banner-Component' ? 'header_section1' : 'header_section2'" class="col-xs-12 global_createaccheader">{{item.headline}}</div>
            <div class="col-xs-12 global_createaccsubheader">{{item.h2content}}</div>
            <div class="col-xs-12 global_greyredbtncontainer">
                <div class="global_redbtn" [innerHTML]="item.paragraphcontent"></div>
                <div class="global_greybtn" [innerHTML]="item.content"></div>
            </div>
        </div>
    </div>
</div>
</section>
