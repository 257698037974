import { Component } from '@angular/core';
import { UserInfoService } from './spartacus/services/user-info.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'flu360_csf';
  userData: any;
  constructor(private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.userInfoService.fetchUserData();  
      //console.log('on it')
  }
//   ngAfterViewInit(){
    
//         //document.getElementById('iframeHolder')?.style.display = none
//         // setTimeout(() => {
//         //   console.log(document.getElementById('iframePopup'));
//         //   if(document.getElementById('iframePopup') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
//         //     (document.getElementById('iframePopup') as HTMLElement).style.visibility = 'hidden'
//         //     }
//         // }, 5000);
//         setTimeout(() => {
//           console.log(document.getElementById('iframeHolder'));
//           if(document.getElementById('iframeHolder') as HTMLElement  &&  localStorage.getItem('isCancelled') === 'true'){
//             (document.getElementById('iframeHolder') as HTMLElement).style.visibility = 'hidden'
//             }
//         }, 5000);
//     console.log('ngafterview')
//   }
//   ngOnChanges(){
//     console.log('on changes')
//   }
// ngDoCheck(){
  
//   console.log('do check')
// }
// ngAfterContentInit(){
//   console.log('after content')
// }
// ngAfterContentChecked(){
//   console.log('content checked')
// }

// ngAfterViewChecked(){
//   console.log('view checked')
// }
// ngOnDestroy(){
//   console.log('on destroy')
// }



}

