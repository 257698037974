import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contact-us-topbanner',
  templateUrl: './contact-us-topbanner.component.html',
  styleUrl: './contact-us-topbanner.component.scss'
})
export class ContactUsTopbannerComponent {

  constructor(public component: CmsComponentData<any>){
    
  }
  topContent: Observable<any> = this.component.data$;
  
  ngOnInit(){
    
  }

}
