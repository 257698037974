
    <div class="response-container" *ngIf="thankYouData | async as item">
        <div class="modal-header">
            <h4 class="modal-title myprofile_modal_header" id="myModalLabel">Thank You!</h4>
            <button (click)="close()" type="button" class="myprofile_modalclose" data-dismiss="modal"
                aria-hidden="true">×</button>

        </div>
        <div class="modal-body" [ngClass]="dataComingFromOrg?'borders':''">

        <ng-container *ngIf="data.type=='notsure'">
            <p class="myprofile_modalbody_txt" >
                        {{item.content}}
                    </p>
                    <p class="myprofile_modalbody_txt">
                            {{item.paragraphcontent}}
                    </p>
        </ng-container>    
           

            <p class="myprofile_modalbody_txt" *ngIf="data.type=='yes'">
                {{item.text2}}
            </p>

            <ng-container *ngIf="data.type=='no'">
                <p class="myprofile_modalbody_txt">
                    {{item.content}}
                </p>
                <p class="myprofile_modalbody_txt">
                    {{item.paragraphcontent}}
                </p>
            </ng-container>

            <p class="myprofile_modalbody_txt" *ngIf="data.type=='account-thank-you'">
                {{item.title}}
            </p>
            <p class="myprofile_modalbody_txt" *ngIf="data.type=='request-thank-you'">
                {{item.title}}
            </p>
        </div>
        <div class="modal-footer">
            <button (click)="close()" type="button" class="btn btn-default myprofile_modalclosebtn"
                data-dismiss="modal">Close</button>

        </div>
    </div>

