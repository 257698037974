<div ngIf="!isThankYouPage() | async">
<div *ngIf="response == 'component-regBusinessDetailsPage'">
    <main role="main" class="registration col-xs-12 no-padding">
        <script> var shippingData=[];</script>

        
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 registration_leftside no-padding-right">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 registration_nagivation no-padding">
                <div class="registration_nagivationPart" id="registration_nagivationPart1">
                        <div class="registration_nagivationNo">
                            <div class="registration_nagivationtick" style="display:block;"><img src="\_ui\responsive\theme-lambda\images\greentick.png" alt=""></div>
                        </div>
                       
                        <div class="registration_nagivationName">User Profile</div>
                    </div>

                    <div class="registration_nagivationPart" id="registration_nagivationPart2">
                        <div class="registration_nagivationNo">
                            <div class="registration_nagivationNumeric">2</div>
                            <div class="registration_nagivationtick"><img src="\_ui\responsive\theme-lambda\images\greentick.png" alt=""></div>
                        </div>
                        <div class="registration_nagivationName active">Business Details</div>
                    </div>
                 <div class="registration_nagivationPart" id="registration_nagivationPart3">
                        <div class="registration_nagivationNo">
                            <div class="registration_nagivationNumeric">3</div>
                            <div class="registration_nagivationtick"><img src="\_ui\responsive\theme-lambda\images\greentick.png" alt=""></div>
                        </div>
                        <div class="registration_nagivationName inactive">Contacts &amp; Addresses<ul>
                               
                                <li>Billing Information</li>
                                <li>Paying Information</li>
                                <li>Shipping Location(s)</li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="registration_nagivationPart" id="registration_nagivationPart4">
                        <div class="registration_nagivationNo">
                            <div class="registration_nagivationNumeric">4</div>
                            <div class="registration_nagivationtick"><img src="\_ui\responsive\theme-lambda\images\greentick.png" alt=""></div>
                        </div>
                        <div class="registration_nagivationName inactive">Review &amp; Confirm</div>
                    </div>
                 </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12 registration_rightside no-padding-left">
                <div class=" registration_help">
                Having trouble? &nbsp;<a href="/help-and-faq" class="showPopupclick"><span>We Can Help</span><span class="global_blackarrow"></span></a>
                </div>
                <section class="registration_business registrationFlow" id="registration_section1" style="display: block;">
                    <form id="businessForm" modelattribute="customerRegisterForm" action="/register/saveRegData" method="post" novalidate="novalidate">
                      
                        <input type="hidden" id="loginemail" name="hiddenEmail" value="pridalal@mailinator.com">
                      <input type="hidden" id="fname" name="firstName" value="Prithwijit">
                      <input type="hidden" id="lname" name="lastName" value="Dalal">
                       <input type="hidden" id="position" name="position" value="Office Manager/Administrator">
                        <input type="hidden" id="phone" name="phoneNumber" value="07003247460">
                         <input type="hidden" id="ext" name="phoneExt" value="">
                       <h1 class="registration_label padding-B10 new_header_register">Business Details</h1>
                        <input type="hidden" id="shippingJson" value="[]">
                        <input type="hidden" id="pageIndex" value="business">
                        <div class="registration_text1 padding-B10">Tell us about your business.</div>
                        <div class="registration_text2">All fields marked with an asterisk (*) are required.</div>
                        <div class="registration_existing padding-B20"><a href="/register/join-account" target="_self" class="showPopupclick">Join an existing business profile instead <div class="global_blackarrow"></div></a></div>
                        <div class="padding-R15">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                <label class="registration_inputLabel" for="business-orgname">Company Name*</label>
                                <input class="registration_input form-control" id="business-orgname" name="orgName" autocomplete="no" value="">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                <label class="registration_inputLabel" for="business-dunnumber">Dun &amp; Bradstreet (DUNS) Number</label>
                                <input class="registration_input form-control" id="business-dunnumber" name="orgAddressForm.duns" autocomplete="off" value="">
                            </div>
                            <div class="clearboth"></div>
                        </div>
                            <div class="padding-R15"> <!-- Membership for registration changes -->                     
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">   
                                    <label class="registration_inputLabel" for="business-membership">Membership</label>
                                    <input class="registration_input form-control" id="business-membership" name="orgAddressForm.membership" autocomplete="off" value="">
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">   
                                    <label class="registration_inputLabel" for="business-gln">GS1 Global Location Number (GLN)</label>
                                    <input class="registration_input form-control" id="business-gln" name="orgAddressForm.globalLocationNumber" autocomplete="off" value="">
                                </div>
                            </div> <!-- Membership for registration changes --> 
                            <div class="clearboth"></div>
                        
                        <div class="registration_text1  margin-B10">Your membership refers to the organization with which you have a signed contract.</div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding margin-B20 registration_address form-group">                        
                            <label class="registration_inputLabel " for="business-lookup">Address Lookup</label>
                            <input class="registration_input form-control" id="business-lookup" name="business-lookup" cc_applied="true" placeholder="Start with post/zip code or street" autocomplete="new-crafty-global-search">
                            <span class="glyphicon glyphicon-search form-control-feedback"></span>
                        </div>
                        <div class="padding-R15">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                <label class="registration_inputLabel " for="business-address1">Address Line 1*</label>
                                <input class="registration_input form-control" id="business-address1" name="orgAddressForm.Line1" autocomplete="off" value="">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding-right margin-B20 registration_parent form-group">                        
                                <label class="registration_inputLabel " for="business-address2">Address Line 2</label>
                                 <input class="registration_input form-control" id="business-address2" name="orgAddressForm.line2" autocomplete="off" value="">
                            </div>
                            <div class="clearboth"></div>
                        </div>
                        <div class="padding-R15">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 no-padding margin-B20 registration_parent form-group">                        
                                <label class="registration_inputLabel " for="business-city">City*</label>
                                <input class="registration_input form-control" id="business-city" name="orgAddressForm.city" value="">
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-6 col-xs-6 no-padding-right margin-B20 registration_mob form-group">                        
                                <label class="registration_inputLabel " for="business-state">State*</label>
                                 <select class="registration_state form-control" id="business-state" name="orgAddressForm.billingState">
                                    <option value="" selected="selected"></option>
                                    <option value="AL">AL</option>
									<option value="AK">AK</option>
									<option value="AR">AR</option>
									<option value="AZ">AZ</option>
									<option value="CA">CA</option>
									<option value="CO">CO</option>
									<option value="CT">CT</option>
									<option value="DE">DE</option>
									<option value="DC">DC</option>
									<option value="FL">FL</option>
									<option value="GA">GA</option>
									<option value="GU">GU</option>
									<option value="HI">HI</option>
									<option value="IA">IA</option>
									<option value="ID">ID</option>
									<option value="IL">IL</option>
									<option value="IN">IN</option>
									<option value="KS">KS</option>
									<option value="KY">KY</option>
									<option value="LA">LA</option>
									<option value="ME">ME</option>
									<option value="MD">MD</option>
									<option value="MA">MA</option>
									<option value="MI">MI</option>
									<option value="MN">MN</option>
									<option value="MS">MS</option>
									<option value="MO">MO</option>
									<option value="MT">MT</option>
									<option value="NC">NC</option>
									<option value="ND">ND</option>
									<option value="NE">NE</option>
									<option value="NV">NV</option>
									<option value="NH">NH</option>
									<option value="NJ">NJ</option>
									<option value="NM">NM</option>
									<option value="NY">NY</option>
									<option value="OH">OH</option>
									<option value="OK">OK</option>
									<option value="OR">OR</option>
									<option value="PA">PA</option>
									<option value="PR">PR</option>
									<option value="RI">RI</option>
									<option value="SC">SC</option>
									<option value="SD">SD</option>
									<option value="TN">TN</option>
									<option value="TX">TX</option>
									<option value="UT">UT</option>
									<option value="VA">VA</option>
									<option value="VI">VI</option>
									<option value="VT">VT</option>
									<option value="WA">WA</option>
									<option value="WI">WI</option>
									<option value="WV">WV</option>
									<option value="WY">WY</option>
                                </select>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-6 col-xs-6 no-padding-right margin-B20 form-group">                        
                                <label class="registration_inputLabel" for="business-zipcode">ZIP Code*</label>
                               <input class="registration_input form-control" id="business-zipcode" name="orgAddressForm.postalCode" autocomplete="off" value="">                                                       
                            </div>
                            <div class="clearboth"></div>
                        </div>
                        <button type="button" class="registration_save active" id="registration_save">Save and Continue</button>
                    </form>
                </section>
                	
            </div>
             
       
        
        </main>
  </div>

</div>
