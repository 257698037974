import { NgModule } from '@angular/core';
import { asmTranslationChunksConfig, asmTranslations } from "@spartacus/asm/assets";
import { AsmRootModule, ASM_FEATURE, AsmConfig } from "@spartacus/asm/root";
import { CmsConfig, ConfigModule, I18nConfig, provideConfig } from "@spartacus/core";
import { translationOverwrites } from '../../../translations/en/custom_asm';

@NgModule({
  declarations: [],
  imports: [
    AsmRootModule
  ],
  providers: [provideConfig(<CmsConfig>{
    featureModules: {
      [ASM_FEATURE]: {
        module: () =>
          import('@spartacus/asm').then((m) => m.AsmModule),
      },
    }
  }),
  provideConfig(<AsmConfig>{
    asm : {
      userIdHttpHeader: {
        enable: true,
      },
    },
  }),
  ]
})
export class AsmFeatureModule { }
