import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService, SemanticPathService } from "@spartacus/core";
import { Observable, map, of, switchMap, tap } from "rxjs";
import { UserInfoService } from "../spartacus/services/user-info.service";


@Injectable({
  providedIn: 'root'
})
export class SeqirusNotAuthGuard {
  constructor(private authService: AuthService, private router: Router,
    private userInfoService: UserInfoService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | any {
    return this.authService.isUserLoggedIn().pipe(
      map(isLoggedIn => {
        if (isLoggedIn) {
        //  return this.userInfoService.getUserData().subscribe(res => {
        //   console.log(res)
        //     if (res) {
        //       if (res.webEnabled) {
        //         console.log('/my-account');
        //         this.router.navigate(['/my-account']);
        //       }
        //       else{
        //         console.log('/');
        //         this.router.navigate(['/']);
        //       }
        //     }
        //     // console.log('false')
        //     // return true;
        //   })
          this.router.navigate(['/my-account']);
          return false;
        }
        // console.log('true')
        return true;

      })
    );
  }
}

