import { Component } from '@angular/core';
import { AuthService, CmsBannerComponent, CmsNavigationComponent, CmsService, OccConfig } from '@spartacus/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, map } from 'rxjs';
import { ContentService } from '../../spartacus/services/content.service';
import { CmsParagraphComponent } from '@spartacus/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  footernode$: Observable<NavigationNode> = this.service.getNavigationNode(
    this.componentData.data$
  );

  styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
    map((d) => d?.styleClass)
  );
  
  termsnConditionNodes: any=[];
  
  termsofUseLink: any;
  tandCSale23_24Link: any;
  tandCSale23_24_apr_Link: any;
  tandCSale24_25Link: any;
  tandCSale24_25_apr_Link: any;

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,protected paraService:ContentService,private authService:AuthService,
    protected config: OccConfig
  ) {}

  footerPara: Observable<CmsParagraphComponent> = this.paraService.getNavigation("SeqirusFooterTextLoggedOut");
  visitsite: Observable<CmsParagraphComponent> = this.paraService.getNavigation("corporatesitelinkcomponent");
  logoData : Observable<any>= this.paraService.getNavigation("SeqirusLogoFooterLink");

  isUserLoggedIn :Observable<boolean> = this.authService.isUserLoggedIn();
 
  
  termsofUseNode: Observable<any>= this.paraService.getNavigation("TermsOfUseLink");
  tandCSale23_24Node: Observable<any>= this.paraService.getNavigation("TandCofsaleLink2023-2024");
  tandCSale23_24_apr_Node: Observable<any>= this.paraService.getNavigation("TandCofsaleLink2023-2024-april");
  tandCSale24_25Node: Observable<any>= this.paraService.getNavigation("TandCofsaleLink2024-2025");
  tandCSale24_25_apr_Node: Observable<any>= this.paraService.getNavigation("TandCofsaleLink2024-2025-april");
  
  
  ngOnInit(){

    this.footernode$.subscribe((res:any)=>{
      if(res){
        
        if(res.children[2].title=='Website Terms of Use'){
          let a=res.children[2];
          
          a.children[2].url = this.termsofUseLink;
          a.children[3].url = this.tandCSale23_24Link;
          a.children[4].url = this.tandCSale23_24_apr_Link;
          a.children[5].url = this.tandCSale24_25Link;
          a.children[6].url = this.tandCSale24_25_apr_Link;

          this.termsnConditionNodes =res.children[2].children;
          
        }
        
        
      }
    })
  
    this.tandCSale23_24Node.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.tandCSale23_24Link = res;
    });
    this.tandCSale23_24_apr_Node.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.tandCSale23_24_apr_Link = res;
    });
    this.tandCSale24_25Node.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.tandCSale24_25Link = res;
    });
    this.tandCSale24_25_apr_Node.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.tandCSale24_25_apr_Link = res;
    });
    this.termsofUseNode.pipe(map(val=> val.media?.url)).subscribe(res => {
      this.termsofUseLink = res;
    });
    
  }

  getImage(image: any): string {
    return this.config.backend?.occ?.baseUrl + image.url;
  }
  


}
