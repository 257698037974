import { ChangeDetectorRef, Component } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInfoService } from '../../spartacus/services/user-info.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss'
})
export class OrderSummaryComponent {
  constructor(public component: CmsComponentData<CmsBannerComponent>,
    public userInfoService:UserInfoService) {
  }
  bannerNode: Observable<any> = this.component.data$;
  //orderData:Observable<any> = this.userInfoService.getOrdersDashboard();
  seasonData :Observable<any> =  this.userInfoService.getseasonDropdown();
}
