import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideOutlet, OutletPosition } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule, provideConfig } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomStartOrderComponent } from './custom-start-order.component';
import { CmsCommonModule } from '../cms-common/cms-common.module';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CustomStartOrderComponent
  ],
  imports: [
    MatProgressBarModule,
    CommonModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    CmsCommonModule,
    MatTableModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        startorderFlexComponent: {
          component: CustomStartOrderComponent,
        },
      }
    } as CmsConfig)
  ]
})
export class CustomStartOrderModule { }
